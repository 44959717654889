.popover-advanced-search {
	max-width: 618px;
	top: 2px !important;
	box-shadow: 0 3px 10px rgba(71, 80, 89, 0.15);
	border: 1px solid #e1e6eb;
	border-radius: 4px;

	.popover-content {
		position: relative;

		padding: 20px 20px 16px;
		font-size: 13px;
		line-height: 20px;
	}

	.search-sample {
		pre {
			display: inline-block;
			vertical-align: top;
			min-width: 24px;
			margin-right: 10px;
			padding: 2px 9px;
			background: #f2f5f7;
			border: 1px solid #e1e6eb;
			border-radius: 4px;
			font-weight: $font-weight-bold;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			font-size: 12px;
			line-height: 20px;
			color: #879099;
			text-align: center;
		}
		span {
			display: inline-block;
			vertical-align: top;
			padding: 3px 0;
		}
	}

	.info-circle {
		font-size: 15px;
		border-radius: 100%;
		border: 2px solid #ccc;
		width: 22px;
		height: 22px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.popover-arrow {
		right: 10px !important;
		left: auto !important;
	}
}
