#reserve-confirm-modal {
  .conflict {
    line-height: 40px;

    .lead {
      margin: 0;
    }

    .item-image {
      width: 40px;
      height: 40px;
      border: 1px solid #eee;
      border-radius: 5px;
    }

    .more-image {
      width: 40px;
      height: 40px;
      border-radius: 5px;
    }
  }
}