#templatePreviewModal {
	.modal-body {
		padding: 0;
	}

	#list-wrapper {
		overflow: scroll;
		padding: 0;
		height: 500px;
		max-height: 500px;
	}
	#preview-wrapper {
		background-color: #f1f3f4;
		position: relative;
		padding: 0;

		.inner {
			overflow: scroll;
			height: 500px;
			max-height: 500px;
		}

		.loading,
		.invalid {
			height: 100%;
			width: 100%;
			top: 0;
			position: absolute;
			z-index: 5555555;
			background-color: #f1f3f4;
			display: flex;
			align-items: center;
			justify-content: center;

			.empty {
				margin: auto;

				p:not(.lead) {
					margin: 0 0 20px;
				}
			}
		}
	}

	.results-list {
		margin: 0;

		a {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	#sign-btn {
		background-color: $brand-primary;
		color: #fff;
		border-radius: 100%;
		width: 50px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 18px;
		box-shadow: 0 2px 32px rgba(0, 0, 0, 0.16);
		position: absolute;
		bottom: 15px;
		left: 50%;
		margin-left: -25px;
		text-decoration: none;
		outline: 0px none;

		&.hidden {
			display: none;
		}

		.fa-times {
			display: none;
		}

		&.open {
			.fa-times {
				display: block;
			}
			.fa-pencil {
				display: none;
			}
		}

		&:hover,
		&:active {
			text-decoration: none;
			box-shadow: 0 2px 32px rgba(0, 0, 0, 0.32);
		}
	}

	#pages {
		font-family: Arial;
		table {
			border-collapse: collapse;
		}

		overflow: hidden;
		position: relative;

		.scale {
			.page {
				background: white;
				box-shadow: 0 0.5mm 2mm rgba(0, 0, 0, 0.3);
				margin: 5mm;
				overflow: hidden;

				&.a4 {
					width: 210mm;
					height: 296mm;
					padding: 10mm;

					&.landscape {
						width: 296mm;
						height: 210mm;
					}
				}

				&.letter {
					width: 216mm;
					height: 279mm;
					padding: 20mm;

					&.landscape {
						width: 279mm;
						height: 216mm;
					}
				}

				&.tabloid {
					width: 279mm;
					height: 432mm;
					padding: 20mm;

					&.landscape {
						width: 432mm;
						height: 279mm;
					}
				}

				&.label4x6 {
					width: 101.6mm;
					height: 152.4mm;
					padding: 2mm;
					margin: 5mm auto;

					&.landscape {
						width: 152.4mm;
						height: 101.6mm;
					}
				}
			}
		}
	}

	.split-table {
		margin: 10px;
	}
}

.signature-popover {
	max-width: 450px;
	width: 450px;
	box-shadow: 0 2px 32px rgba(0, 0, 0, 0.16);
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;

	.popover-content {
		padding: 0;
		position: relative;
	}

	canvas {
		width: 100%;
		height: 250px;
	}

	.signature-placeholder {
		position: absolute;
		width: 90%;
		border-top: 1px solid #bbb;
		left: 25px;
		bottom: 35px;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;

		span {
			font-size: 20px;
			color: #bbb;
			font-weight: 100;
		}
	}

	.clear-action {
		color: #bbb;
		position: absolute;
		top: 15px;
		right: 25px;
		font-size: 20px;
		display: none;
	}
}
