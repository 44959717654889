.label-btn {
	padding: 5px;

	span {
		width: 21px;
		height: 21px;
		display: block;
	}
}

.label-dropdown {
	padding: 15px 10px;
	width: 200px;

	a {
		float: left;
		margin-left: 5px;

		span {
			width: 30px;
			height: 30px;
			display: inline-block;
		}

		&.active {
			span {
				position: relative;

				&:after {
					content: '\f00c';
					font-family: FontAwesome;
					color: #fff;

					position: absolute;
					top: 5px;
					left: 8px;
				}
			}
		}
	}
}

.label-picker {
	.dropdown-item {
		padding: 5px 10px;

		&.active {
			background-color: #fff;
			color: #333;

			&:after {
				content: '\f00c';
				font-family: FontAwesome;
				float: right;
			}
		}
	}
}

.label-tag {
	display: inline-block;
	width: 15px;
	height: 15px;
	border-radius: 3px;
	vertical-align: text-bottom;
}

.label-none {
	color: #373a3c !important;
	background-color: transparent !important;
	border: 1px solid #ccc !important;
	background-image: linear-gradient(45deg, #ccc 0%, #ccc 50%, #ccc 50%, white 50%, white 100%);
}

.label-checkbox {
	padding-left: 0px;
	cursor: pointer;

	label {
		display: inline-block;
		position: relative;
		width: 100%;
		cursor: pointer;

		&:before {
			content: '';
			display: inline-block;
			position: absolute;
			width: 15px;
			height: 15px;
			left: 0;
			top: 4px;
			margin-left: -19px;
			border: 1px solid #cccccc;
			border-radius: 3px;
			background-color: #fff;
			-webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
			-o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
			transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
		}

		&:after {
			display: inline-block;
			position: absolute;
			width: 14px;
			height: 14px;
			left: 0;
			top: 4px;
			margin-left: -19px;
			padding-left: 3px;
			padding-top: 1px;
			font-size: 9px;
			color: #555555;
		}
	}

	input[type='checkbox'] {
		opacity: 0;

		&:focus + label:before {
			outline: none;
		}
		&:checked + label:after {
			font-family: 'FontAwesome';
			content: '\f00c';
		}
		&:disabled + label {
			opacity: 0.65;
		}
		&:disabled + label:before {
			background-color: #eeeeee;
			cursor: not-allowed;
		}
	}

	@each $color, $value in $labelColors {
		&.#{$color} {
			input[type='checkbox'] {
				&:checked + label:after {
					color: #fff;
				}
			}

			label {
				&:before {
					background-color: $value;
					border-color: darken($value, 10%);
				}
			}
		}
	}
}

.event-list {
	.list-group-item {
		&:before {
			content: ' ';
			background-color: #fff;
			top: 0;
			left: 0;
			position: absolute;
			height: 100%;
			width: 5px;
		}

		@each $color, $value in $labelColors {
			&.#{$color} {
				&:before {
					background-color: $value !important;
				}
			}
		}
	}
}

.table-checkouts,
.table-reservations {
	tbody {
		tr {
			@each $color, $value in $labelColors {
				&.#{$color} {
					td:first-child {
						min-height: 63px; //IE bugfix

						@include media-breakpoint-down(md) {
							padding-right: 25px;
						}

						&:before {
							background-color: $value !important;
						}
					}
				}
			}

			td:first-child {
				&:before {
					content: ' ';
					background-color: #fff;
					top: 0;
					left: 0;
					position: absolute;
					height: 100%;
					width: 5px;
					min-height: 63px; //IE bugfix
				}
			}
		}
	}
}
