#archiveLocationModal {
	.select-picker {
		max-width: 300px;
		margin-bottom: 15px;
		margin-top: -5px;

		.selection-text {
			width: calc(100% - 15px);
		}
	}
}
