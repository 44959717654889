#comments {
	.empty {
		margin-top: 80px;
	}

	.comment {
		margin-bottom: 15px;

		.avatar {
			width: 32px;
			height: 32px;
			margin-right: 4px;
		}

		.by {
			font-weight: $font-weight-bold;
			margin-bottom: 5px;
		}

		.card {
			padding: 15px;
			margin-bottom: 0px;
		}

		form {
			margin-bottom: 5px;

			textarea {
				&.collapsed {
					height: 35px;
				}
			}
		}

		.close {
			float: none;
		}
	}

	@include media-breakpoint-down(sm) {
		.empty {
			margin-top: 0;
		}
	}
}
