@mixin kit-badge(
	$size: 40px,
	$badgeColor: rgba(33, 33, 33, 0.7),
	$iconTop: 5px,
	$iconLeft: 5px,
	$iconColor: #fff,
	$iconFontSize: 12px
) {
	cursor: pointer;

	&:before {
		content: '';
		width: 0;
		height: 0;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 7;
		border-style: solid;
		border-width: $size/2;
		border-color: $badgeColor transparent transparent $badgeColor;
		border-radius: 5px 0 0 0;
	}
	&:after {
		content: '\f1b3';
		font-family: 'FontAwesome';
		position: absolute;
		top: $iconTop;
		left: $iconLeft;
		color: $iconColor;
		z-index: 67;
		font-size: $iconFontSize;
	}
}
