#items-list {
	// For the categorized view
	.item-image,
	.more-image {
		width: 36px;
		height: 36px;
	}

	.no-items {
		width: 36px;
		height: 36px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #fff;
		border-radius: 5px;
		border: 1px solid #e5e5e5;
		text-align: center;
		font-size: 10px;
		color: $text-secondary;
		line-height: 10px;
	}

	.select-picker {
		min-width: initial;

		.dropdown-menu {
			width: 250px;
		}
	}

	.scheduler {
		.durandal-wrapper {
			overflow-y: visible;
		}
	}
}

.group-filter {
	.select-picker .btn {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.btn-group.border-right-0 .btn {
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
	}
}

#items-new,
#kits-new,
#contacts-new {
	// Make sure that the location and category dropdown go over the custom field pickers on item create page
	.input-group .form-control {
		z-index: 0;

		&:focus {
			z-index: 1;
		}
	}

	a[data-toggle='collapse'] {
		margin-bottom: 15px;
		display: block;
		font-size: 16px;

		&:active,
		&:visited,
		&:hover,
		&:focus {
			text-decoration: none;
		}

		&:before {
			font-family: FontAwesome;
			content: '\f107';
			padding-right: 5px;
		}
		&:after {
			content: 'Show less item info';
		}

		&.collapsed {
			margin-bottom: 0;

			&:before {
				content: '\f105';
			}
			&:after {
				content: 'Add more item info';
			}
		}
	}

	select.form-control:not([size]):not([multiple]) {
		height: 33px;

		&.empty {
			padding: 0.5rem 0.75rem;
		}
	}

	.duration-helpers {
		a {
			text-decoration: none !important;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.card {
		hr.full {
			margin-left: -15px;
			margin-right: -15px;
		}
	}

	.bootstrap-select {
		button.btn-lg {
			padding-left: 10.5px;
		}

		.bs-searchbox + .dropdown-menu.inner {
			padding-bottom: 0px;
		}
	}
}

#item,
#kit {
	height: 100%;

	.gmap {
		height: calc(100% - 85px);

		.map {
			height: 100%;
			min-height: 295px;
			width: 100%;
		}

		.empty {
			height: 100%;
			min-height: 295px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			background-color: #fff;
		}
	}

	.empty {
		height: 100%;
	}

	.map-card {
		height: calc(100% - 34px);
		margin-bottom: 0;

		.card-header {
			font-size: 17px;
		}
	}

	.status-card {
		.fa {
			font-size: 36px;
			line-height: 50px;
			width: 80px;
		}
		.lead {
			margin-bottom: 0;
		}
	}

	@include media-breakpoint-down(sm) {
		.upgrade-page {
			height: 500px;
		}
	}
}

#kit {
	.gmap {
		height: 100%;
	}

	.map-card {
		height: 100%;
		overflow: hidden;
	}
}

#item_map {
	height: 100%;

	.row {
		height: 100%;
	}

	.col-md-8 {
		height: 100%;

		padding: 0;
	}
}

@keyframes pulsate {
	0% {
		transform: scale(0.1);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: scale(1.2);
		opacity: 0;
	}
}
