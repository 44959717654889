.twitter-typeahead {
	width: 100%;
}

.typeahead,
.tt-query,
.tt-hint {
}
.tt-query {
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
}
.tt-hint {
	color: $text-secondary;
}
.tt-dropdown-menu {
	background-color: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 8px 8px 8px 8px;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	margin-top: 12px;
	padding: 8px 0;
	width: 422px;
}
.tt-suggestion {
	font-size: $font-size-root;
	line-height: 24px;
	padding: 3px 20px;

	small {
		font-size: 60%;
	}
}
.tt-suggestion.tt-cursor {
	background-color: #0097cf;
	color: #ffffff;
}
.tt-suggestion p {
	margin: 0;
}

.tt-menu {
	width: 100%;
	position: relative;
	margin: 12px 0;
	padding: 8px 0;
	background-color: #fff;
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, 0.2);
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	-webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.tt-dataset-item-brand-catalog,
.tt-dataset-item-model-catalog {
	.tt-suggestion {
		font-size: 14px;
		line-height: 16px;
	}
}
