.fields-table {
	tr {
		background-color: #fff;

		.drag-handle {
			color: $text-secondary;
			cursor: move;
		}

		.cell-handle {
			width: 20px;
		}
		.cell-name {
			width: 250px;
		}

		.cell-value-description {
			min-width: 200px;
			max-width: 200px;
			white-space: normal;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
}

.fields-wrapper.fields-view {
	background-color: white;
	border-radius: 8px;
	border: 1px solid $card-border-color;

	.fields-table-header {
		margin: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	h6.heading {
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 0;
		text-transform: none;
		color: $gray-900;
		line-height: 24px;
	}

	label {
		margin-bottom: 0;
	}

	.card {
		border: none;
	}
}

.fields-wrapper {
	.more-action {
		text-transform: initial;

		input[type='checkbox'] {
			margin-right: 5px;
		}
	}

	.empty {
		padding-top: 75px;
	}

	.field-actions {
		border-top: 1px solid $card-border-color;
		padding: 16px 8px 0px 8px;
		text-align: center;

		.or {
			display: inline-block;
			padding: 0 10px;
		}
	}

	.fields {
		.invalid-field {
			color: red;
			float: right;
			padding-right: 5px;
		}

		.has-error {
			input {
				border-color: #a94442;
				color: #a94442;
			}
			.help-block {
				color: #a94442;
			}
		}

		.editing {
			background-color: #fdfde7;

			.form-inline .form-group {
				width: 100%;
				margin-bottom: 0px;

				.form-control {
					width: 100%;
				}
			}

			.media-body-truncate {
				position: relative;
			}

			//Styling bugfixes
			.cell-value {
				padding: 4px 0;

				.input-group {
					margin-top: -2px;
				}

				.form-group {
					margin-bottom: 0;
					padding: 0 15px 0 0;
				}
			}

			.clear-field {
				display: none;
			}
		}

		.cell-name {
			width: 200px;
		}

		.cell-value {
			.editable {
				display: block;
				color: $text-dark;
				cursor: pointer;

				&.editable-empty {
					color: $text-secondary;
					font-style: italic;
				}

				&:hover {
					text-decoration: none;

					&:before {
						content: ' \f040';
						font-family: FontAwesome;
						color: $text-secondary;
						float: right;
						margin-right: 2px;
					}
				}
			}

			.editable-input {
				width: calc(100% - 86px);
			}

			.editable-buttons,
			.editable-input {
				display: inline-block;
				vertical-align: top;

				.editable-submit,
				.editable-cancel {
					padding: 4px 7px;

					i {
						box-sizing: border-box;
						position: relative;
						display: block;
						width: 22px;
						height: 22px;
						border: 2px solid transparent;
					}
				}

				button {
					margin-left: 5px;
				}
			}

			.editable-submit {
				i {
					border-radius: 100px;

					&:after {
						content: '';
						display: block;
						box-sizing: border-box;
						position: absolute;
						left: 3px;
						top: -1px;
						width: 6px;
						height: 10px;
						border-width: 0 2px 2px 0;
						border-style: solid;
						transform-origin: bottom left;
						transform: rotate(45deg);
					}
				}
			}

			.editable-cancel {
				i {
					border-radius: 40px;

					&:after,
					&:before {
						content: '';
						display: block;
						box-sizing: border-box;
						position: absolute;
						width: 16px;
						height: 2px;
						background: currentColor;
						transform: rotate(45deg);
						border-radius: 5px;
						top: 8px;
						left: 1px;
					}

					&:after {
						transform: rotate(-45deg);
					}
				}
			}

			.clear-field {
				visibility: hidden;
				color: $text-secondary;
			}

			&:hover {
				.clear-field {
					visibility: visible;
				}
			}
		}

		.media-body {
			overflow: visible;
		}
	}
}
