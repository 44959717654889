.messg {
	position: fixed;
	z-index: 9999;
	left: 10px;
	right: 10px;
	border: 0 none;
	font-size: 14px;
	line-height: 1.4286;
	text-align: left;
	cursor: pointer;

	@include border-radius(2px);
	//.box-shadow(inset 0 -1px rgba(0,0,0,0.25));
}

@media (min-width: 768px) {
	.messg {
		left: 23%;
		right: 23%;
	}
}

@media (min-width: 992px) {
	.messg {
		left: 28%;
		right: 28%;
	}
}

@media (min-width: 1200px) {
	.messg {
		left: 33%;
		right: 33%;
	}
}

.messg--default {
	background-color: $gray-light;
}

.messg--success {
	background-color: $state-success-bg;
	color: $state-success-text;
}

.messg--info {
	background-color: $state-info-bg;
	color: $state-info-text;
}

.messg--warning {
	background-color: $state-warning-bg;
	color: $state-warning-text;
}

.messg--error {
	background-color: $state-danger-bg;
	color: $state-danger-text;
}

.messg__text {
	padding: 8px 14px;
}

.messg__buttons {
	float: right;
	padding: 8px;
}

.messg,
.messg__button {
	//color: #f5f5f5;
	font-family: 'Lucida Sans Unicode', 'Lucida Grande', sans-serif;
}

.messg__button {
	display: inline-block;
	border: 0 none;
	margin: 0 0 0 5px;
	padding: 0 6px;
	background: none;
	cursor: pointer;
	@include border-radius(1px);
}

.messg--default .messg__button {
	background-color: darken($gray-light, 10%);
}

.messg--default .messg__button:hover {
	background-color: darken($gray-light, 20%);
}

.messg--success .messg__button {
	background-color: lighten($state-success-text, 30%);
	color: $state-success-bg;
}

.messg--success .messg__button:hover {
	background-color: $state-success-text;
}

.messg--info .messg__button {
	background-color: lighten($state-info-text, 30%);
	color: $state-info-bg;
}

.messg--info .messg__button:hover {
	background-color: $state-info-text;
}

.messg--warning .messg__button {
	background-color: lighten($state-warning-text, 30%);
	color: $state-warning-bg;
}

.messg--warning .messg__button:hover {
	background-color: $state-warning-text;
}

.messg--error .messg__button {
	background-color: lighten($state-danger-text, 30%);
	color: $state-danger-bg;
}

.messg--error .messg__button:hover {
	background-color: $state-danger-text;
}
