.chart-wrapper {
	position: relative;
	min-height: 200px;

	.chart-info {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 50%;
		left: 0;
		margin-top: -30px;
		line-height: 1;
		text-align: center;

		.count {
			font-size: 40px;
			margin-bottom: 5px;
		}
		.desc {
			color: $text-secondary;
		}
	}

	canvas {
		max-width: 200px;
		margin: auto;
		position: relative;
	}

	&.chart-wrapper-lg {
		canvas {
			max-width: initial;
		}
	}
}

.chart-legend {
	.legend-item {
		color: $text-dark;
		margin-bottom: 5px;
		display: block;
		text-wrap: wrap;
		word-break: break-all;

		&.disabled {
			pointer-events: none;
		}

		&:hover,
		&:active,
		&:focus {
			text-decoration: none;
		}

		.circle {
			width: 15px;
			height: 15px;
			display: inline-block;
			border-radius: 100%;
			margin-right: 5px;
		}
	}

	&.empty {
		flex: 1;
		max-width: 250px;
		padding: 0;

		.legend-item {
			.circle {
				height: 15px;
				width: 15px;
				background-color: #d8d8d8;
			}

			.circle + span {
				display: inline-block;
				min-width: 50px;
				max-width: 150px;
				width: calc(100% - 24px);
				height: 14px;
				background-color: #d8d8d8;
			}
		}
	}
}

#dashboard-page {
	.card-block {
		padding: 30px;
	}

	.maintenance-card,
	.customer-card {
		.aligner {
			height: 260px;
			flex-direction: column;
		}
	}

	.card {
		margin-bottom: 0;

		.loading,
		.no-results {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 260px;
			flex-direction: column;
			color: $text-muted;

			i {
				font-size: 45px;
				margin-bottom: 10px;
				color: $text-muted;
			}
		}

		&.first {
			order: -1;
		}
	}

	.events-card {
		overflow: hidden;

		.event-list {
			height: 260px;
			overflow-y: auto;

			h4 {
				padding-left: 15px;
				padding-right: 10px;
			}
		}

		.items-list {
			.list-group-item {
				img {
					width: 48px;
					height: 48px;
				}

				&:before {
					display: none;
				}
			}
		}
	}

	.item-image,
	.more-image {
		height: 50px !important;
		width: 50px !important;
		margin-top: 5px;
	}
}

.stat-link {
	display: block;

	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
	}

	.count {
		color: $text-dark;
		font-size: 40px;
		line-height: 45px;

		&.attention-required {
			color: $brand-danger;
		}

		&.attention-warning {
			color: $brand-warning;
		}

		&.attention-success {
			color: $brand-success;
		}

		&.attention-done {
			color: gray;
		}
	}

	.desc {
		color: $text-secondary;
	}
}
