@keyframes kaboom {
	0% {
		transform: scale(1.2);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes greenGray {
	0% {
		color: green;
	}
	100% {
		color: gray;
	}
}

@keyframes new-item-animation {
	from {
		opacity: 0;
		-webkit-transform: scale(0);
		-ms-transform: scale(0);
		-o-transform: scale(0);
		transform: scale(0);
	}

	to {
		opacity: 1;
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		-o-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes fadeInFadeOut {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

#spotcheckModal {
	.modal-body {
		height: 400px;
	}

	.tabs {
		display: flex;

		.nav-item {
			flex: 1;
		}

		.nav-link {
			display: block;
			text-align: center;
		}
	}

	.form-search {
		position: relative;

		&:before {
			content: '\f02a';
			font-family: FontAwesome;
			position: absolute;
			top: 5px;
			left: 12px;
			font-size: 20px;
			color: $text-secondary;
		}

		input {
			padding-left: 40px;
		}

		.scanMessage {
			position: absolute;
			right: 15px;
			top: 9px;
			opacity: 1;
		}
	}

	.results-list {
		max-height: 310px;
		overflow-y: auto;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		&.disabled {
			opacity: 0.5;
			pointer-events: none;
		}

		&.no-results {
			background-color: rgba(255, 255, 255, 0.9);
			position: absolute;
			top: 60px;
			left: 15px;
			right: 15px;
			width: calc(100% - 30px);
			height: auto;
		}

		a {
			&.highlight {
				order: -1;

				opacity: 0;
				-webkit-animation: new-item-animation 0.3s linear forwards;
				-o-animation: new-item-animation 0.3s linear forwards;
				animation: new-item-animation 0.3s linear forwards;
			}
		}

		&.checked,
		&.unexpected {
			margin-top: 0;
			max-height: 370px;

			.media-right {
				padding-right: 10px;

				.btn {
					margin-top: 8px;
				}
			}

			a {
				&:hover {
					background-color: transparent;
					cursor: default;
				}
			}
		}
	}

	.results-loading {
		height: 100%;
	}

	.results-empty {
		height: calc(100% - 50px);
		flex-direction: column;

		&.checked,
		&.unexpected {
			height: 100%;
		}

		h1 {
			font-size: 20px;
			font-weight: normal;
			color: $text-secondary;
		}

		.success {
			animation: kaboom 550ms ease-in-out, greenGray 1.5s ease-in-out;
		}
	}
}

.multi-progress {
	height: 12px;
	border-radius: 50px;
	display: flex;
	overflow: hidden;
	//margin:7px 25px 20px 25px;
	line-height: 0;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
	justify-content: flex-end;

	&.xs {
		height: 8px;
	}

	.found-progress {
		background-color: $brand-success;
	}
	.missing-progress {
		background-color: #eee;
	}
	.unexpected-progress {
		background-color: $brand-danger;
	}
}

.user-image,
.user-more-image {
	border: 1px solid #ccc;
	border-radius: 50%;
	width: 32px;
	height: 32px;
}

.legend-unexpected,
.legend-issues {
	width: 14px;
	height: 14px;
	display: inline-block;
	border-radius: 5px;
	vertical-align: middle;
	margin-right: 2px;

	background-color: $brand-danger;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.legend-scanned,
.legend-checked {
	width: 14px;
	height: 14px;
	display: inline-block;
	background-color: $brand-success;
	border-radius: 5px;
	vertical-align: middle;
	margin-right: 2px;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.legend-unscanned {
	width: 14px;
	height: 14px;
	display: inline-block;
	background-color: #eee;
	border-radius: 5px;
	vertical-align: middle;
	margin-right: 2px;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

/**
 * Circle loader with checkmark completed state
 * https://codepen.io/scottloway/pen/zqoLyQ
 */
// Define vars we'll be using
$loader-size: 40px;
$check-height: $loader-size/2;
$check-width: $check-height/3;
$check-left: ($loader-size/6 + $loader-size/12);
$check-thickness: 3px;
$check-color: $brand-success;

.circle-loader {
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-left-color: $check-color;
	animation: loader-spin 1.2s infinite linear;
	position: relative;
	display: inline-block;
	vertical-align: top;
	border-radius: 50%;
	width: $loader-size;
	height: $loader-size;

	&.disabled {
		border-color: rgba(0, 0, 0, 0.2);

		&.load-complete {
			border-color: orange;
		}
	}

	&.load-complete {
		.checkmark {
			display: block;
		}
	}
}

.load-complete {
	-webkit-animation: none;
	animation: none;
	border-color: $check-color;
	transition: border 500ms ease-out;
}

.checkmark {
	display: none;

	&.check {
		&.draw:after {
			animation-duration: 800ms;
			animation-timing-function: ease;
			animation-name: checkmark;
			transform: scaleX(-1) rotate(135deg);
		}

		&:after {
			opacity: 1;
			height: $check-height;
			width: $check-width;
			transform-origin: left top;
			border-right: $check-thickness solid $check-color;
			border-top: $check-thickness solid $check-color;
			content: '';
			left: $check-left;
			top: $check-height + 2px;
			position: absolute;
		}
	}

	&.danger {
		&:after,
		&:before {
			content: '';
			display: block;
			box-sizing: border-box;
			position: absolute;
			border-radius: 3px;
			width: 3px;
			background: orange;
			left: 18px;
		}

		&:after {
			top: 6px;
			height: 20px;
		}

		&:before {
			height: 3px;
			bottom: 8px;
		}
	}
}

@keyframes loader-spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes checkmark {
	0% {
		height: 0;
		width: 0;
		opacity: 1;
	}
	20% {
		height: 0;
		width: $check-width;
		opacity: 1;
	}
	40% {
		height: $check-height;
		width: $check-width;
		opacity: 1;
	}
	100% {
		height: $check-height;
		width: $check-width;
		opacity: 1;
	}
}

#newSpotcheckModal {
	.spotcheck-kinds {
		.card {
			height: 120px;
			cursor: pointer;

			.media-left {
				vertical-align: middle;
				height: 90px;

				.fa {
					line-height: 65px;
					color: #333;
					font-size: 30px;
					width: 50px;
					text-align: center;
				}
			}

			&:hover {
				border-color: $brand-primary;
			}

			&.active {
				.fa {
					color: $brand-primary;
				}
				border-color: $brand-primary;
				box-shadow: 0 0px 5px $brand-primary;
			}
		}
	}

	.results-list {
		height: 375px;
		overflow-y: auto;

		a > * {
			pointer-events: none;
		}

		.disabled {
			opacity: 0.5;
		}
		.own {
			border-bottom: 2px solid #ccc;
		}

		document-values + .csv {
			margin-left: 5px;
		}

		input:not(:checked) + .el-checkbox-style {
			&:after {
				display: none;
			}
		}

		.transaction {
			padding-left: 15px;

			&:before {
				content: ' ';
				background-color: #fff;
				top: 0;
				left: 0;
				position: absolute;
				height: 100%;
				width: 5px;
			}

			@each $color, $value in $labelColors {
				&.#{$color} {
					&:before {
						background-color: $value !important;
					}
				}
			}
		}
	}

	.results-loading,
	.results-empty {
		height: 395px;
	}

	.results-empty {
		color: $text-secondary;

		.fa {
			font-size: 80px;
			margin-bottom: 10px;
			color: $text-secondary;
		}
		.lead {
			font-size: 1.25rem;
			font-weight: $font-weight-light;
		}
	}
}

#spotcheckDetail {
	.participants {
		display: flex;
		margin-top: 15px;

		.participant {
			margin-right: 15px;
			width: 75px;
			text-align: center;

			img {
				border-radius: 50%;
				width: 48px;
				height: 48px;
				box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
			}
		}
	}

	.nav-pills {
		padding: 0 0 10px;
		background-color: transparent;

		.nav-link {
			border-bottom: 0px;
			padding: 5px 15px;

			&.active {
				border-bottom: 0px none;
				background-color: #fff;
				color: $gray-900;
			}
		}
	}

	.equipment-row {
		.media-right {
			vertical-align: middle;
		}

		.entry-status {
			white-space: nowrap;
			display: inline-block;
			border: 1px solid $brand-danger;
			padding: 5px 10px;
			color: $brand-danger;
			border-radius: 5px;
			text-align: center;
		}
	}

	.doc-placeholder {
		.img {
			width: 48px;
			height: 48px;
			background-color: $timeline-placeholder-bg-color;
		}

		.title {
			width: 70%;
			height: 20px;
			background-color: $timeline-placeholder-bg-color;
			margin-bottom: 5px;
		}

		.info {
			span {
				display: inline-block;
				height: 15px;
				width: 50px;
				background-color: $timeline-placeholder-bg-color;
			}
		}
	}

	.spotcheck-doc-info {
		a:hover,
		a:visited,
		a:active {
			color: #333;
		}
	}

	.aligner {
		min-height: 400px;

		.lead {
			margin-top: 15px;
			font-size: 18px;
			color: #777;
		}
	}
}

.spotcheck-progress {
	path {
		stroke-width: 20px;
	}
}

.transaction-card-info,
.transaction-popover-info {
	//padding-left: 30px;

	&:before {
		content: ' ';
		background-color: #fff;
		top: 0;
		left: 0;
		position: absolute;
		height: 100%;
		width: 5px;
	}

	@each $color, $value in $labelColors {
		&.#{$color} {
			&:before {
				background-color: $value !important;
			}
		}
	}
}
.transaction-popover-info {
	width: 250px;
}
