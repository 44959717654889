.banner {
	border-radius: 4px;
	padding: 8px;
	text-align: center;
}

.banner-error {
	background-color: $red-100;
	color: $red-500;
}
