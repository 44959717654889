.location-name-cell {
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.openinghours-badge {
	background-color: $indigo-200;
	color: $indigo-700;
	border-radius: 9999px;
	padding: 0.25rem 0.5rem;
	display: inline-block;
	font-size: 80%;
	width: min-content;
}
