.flex {
	display: flex;
}

.flex-col {
	flex-direction: column;
}

.flex-row {
	flex-direction: row;
}

.items-center {
	align-items: center;
}

.justify-center {
	justify-content: center;
}

.justify-between {
	justify-content: space-between;
}

.flex-wrap {
	flex-wrap: wrap;
}

.shrink-0 {
	flex-shrink: 0;
}

$breakpoints: sm;

@each $breakpoint in $breakpoints {
	@include media-breakpoint-up($breakpoint) {
		.#{$breakpoint}\:flex-row {
			flex-direction: row;
		}
	}
}
