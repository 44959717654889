#calendarFilterModal {
	.col-md-4 {
		label {
			font-weight: $font-weight-bold;
		}
	}

	.select-list {
		border: 1px solid rgba(0, 0, 0, 0.15);
		width: 100%;
		height: 150px;
		border-radius: 4px;
		overflow-y: auto;
		padding: 10px 0;

		.dropdown-item {
			label {
				width: 100%;
				cursor: pointer;
			}

			&.disabled {
				label {
					cursor: not-allowed;
				}
			}
		}
	}

	.select-list-label {
		line-height: 25px;

		.selection-actions {
			float: right;
			font-size: 80%;
		}
	}

	.select-picker {
		.dropdown-menu {
			box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
		}

		.selection-text {
			width: calc(100% - 15px);
		}
	}
}
