#equipment-view {
	.actions {
		margin-bottom: 5px;

		.search {
			display: inline-block;
			width: 200px;
			vertical-align: middle;
			position: relative;

			input {
				padding-left: 25px;
			}

			&:after {
				content: '\f002';
				font-family: FontAwesome;
				position: absolute;
				top: 7px;
				left: 7px;
				color: #aaa;
			}
		}

		h6 {
			line-height: 33px;
			margin-bottom: 0;
			display: inline-block;
		}
		small {
			display: inline-block;
			text-transform: uppercase;
		}
	}

	.items-filter {
		display: inline-block;

		button {
			text-transform: uppercase;
			color: #818a91;
			border-color: #818a91;
			font-size: 10px;
			border-radius: 3px;
		}
	}

	.add-equipment {
		padding: 15px;
		border-bottom: 1px solid #f5f5f5;
		font-size: 18px;
		text-align: center;
	}

	.alert {
		border-radius: 0;
	}

	.equipment-empty {
		padding: 110px 50px;
		text-align: center;

		i {
			margin-bottom: 10px;
		}
	}

	.empty {
		margin-top: 80px;
	}

	.equipment-list {
		margin: 0;

		// Dropdown cut-off issue
		// https://github.com/twbs/bootstrap/issues/6381
		.media-body {
			overflow: visible;
		}

		li:last-child {
			.equipment-row {
				border-bottom-right-radius: $border-radius;
				border-bottom-left-radius: $border-radius;
			}
		}

		.equipment-row {
			border-bottom: 1px solid #f5f5f5;

			.flag-status {
				font-size: 12px;
				margin-left: 10px;
			}

			.equipment-level {
				padding-top: 10px;
				padding-left: 10px;
				padding-right: 0px;
			}

			.equipment-content {
				padding: 10px;

				.equipment-img {
					width: 80px;
					height: 80px;
					background-color: #fff;
				}

				.equipment-title {
					margin-top: 5px;
					font-size: 20px;
					line-height: 28px;
					display: block;
					color: $text-dark;

					&:hover,
					&:active,
					&:focus {
						text-decoration: none;
					}
				}

				.csv {
					font-size: 11px;
				}

				.kit-images,
				.info {
					.item-image,
					.more-image {
						width: 48px;
						height: 48px;
					}

					&:hover,
					&:active,
					&:focus {
						text-decoration: none;
					}
				}

				.info {
					color: $text-secondary;
				}
			}

			.equipment-title {
				.tag-success {
					font-size: 10px;
					line-height: 12px;
					vertical-align: text-bottom;
					border: 1px solid #5cb85c;
					background: none;
					color: #5cb85c;
					padding: 4px 8px;
				}
			}

			.equipment-actions {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				height: 80px;

				.checkbox {
					margin: auto;

					input {
						margin-left: 0;
					}
				}

				&.required {
					.checkbox {
						position: relative;

						&:after {
							content: ' ';
							width: 28px;
							height: 28px;
							background-color: #f2dede;
							position: absolute;
							top: -3px;
							left: -8px;
							border-radius: 5px;
							border: 1px solid #a94442;
							z-index: 1;
						}

						input {
							position: relative;
							z-index: 50;
						}
					}
				}
			}

			&.adding,
			&.removing {
				opacity: 0.5;
			}

			&.conflict {
				background-color: $state-warning-bg;
				color: $state-warning-text !important;
				border-bottom: 1px solid lighten($state-warning-text, 40%);

				.equipment-title {
					color: $state-warning-text !important;
				}

				.btn-outline-conflict {
					font-size: 75%;
				}
			}

			&.checkedidn {
				position: relative;

				&:after {
					content: ' ';
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: rgba(255, 255, 255, 0.5);
				}

				.equipment-actions {
					position: absolute;
					font-size: 18px;
					top: 35px;
					right: 15px;
					z-index: 55;
				}
			}
		}

		.equipment-detail-list {
			background-color: #fafafa;

			.equipment-row {
				.equipment-level {
					background-color: darken(#f5f5f5, 15%);
				}

				&.conflict {
					.equipment-level {
						background-color: lighten($state-warning-text, 25%);
					}
				}
			}
		}
	}
}
