.heatmap {
	page-break-after: always;

	table {
		border: 0px none;
	}

	.days {
		width: 5vw;

		border: 0px none;
	}

	.cell {
		padding: 0;
		border: 1px solid white;

		&:after {
			content: ' ';
			width: 30px;
			height: 30px;
			display: block;
		}
	}

	.legend {
		td {
			border: 0px none;
		}

		table {
			width: 100%;

			td {
				padding: 0 5px;
				width: 20%;
			}
		}
	}

	.hours {
		td {
			padding: 0;
			text-align: center;
		}
	}

	.ampm {
		td {
			padding: 5px 0;
			border: 0px none;
		}

		.hr {
			width: 99%;
			font-size: $font-size-base;
			text-align: center;
			border-bottom: 1px solid #eee;
			line-height: 0.1em;
			margin: 10px 0 20px;
			font-weight: normal;

			span {
				background: #fff;
				padding: 0 10px;
			}
		}
	}
}
