@import 'pages/items';
@import 'pages/kits';
@import 'pages/contacts';
@import 'pages/getting-started';
@import 'pages/cart';
@import 'pages/fields';
@import 'pages/search';
@import 'pages/transaction';
@import 'pages/equipment';
@import 'pages/comments';
@import 'pages/attachments';
@import 'pages/timeline';
@import 'pages/tags';
@import 'pages/import';
@import 'pages/codes';
@import 'pages/calendar';
@import 'pages/cards';
@import 'pages/map';
@import 'pages/account';
@import 'pages/profile';
@import 'pages/dashboard';
@import 'pages/event-list';
@import 'pages/list';
@import 'pages/invite-users';
@import 'pages/settings';
@import 'pages/sync-users';
@import 'pages/reports';
@import 'pages/permissions';
@import 'pages/depreciation';
@import 'pages/labels';
@import 'pages/upgrade';
@import 'pages/picker';
@import 'pages/heatmap';
@import 'pages/emoji-picker';
@import 'pages/integrations';
@import 'pages/status-messages';
@import 'pages/styled-checkbox';
@import 'pages/timeline-filter';
@import 'pages/activity-feed';
@import 'pages/locations';
@import 'pages/subscription';

.app-container {
	height: 100%;
	min-width: 100vw;
	flex: 1;
	position: relative;
	display: grid;
	grid-template-areas:
		'header'
		'main'
		'footer';
	grid-template-rows: min-content 1fr min-content;
	grid-template-columns: 1fr;

	@include media-breakpoint-up(sm) {
		overflow-x: auto;
	}

	@include media-breakpoint-up(md) {
		min-width: auto;
		width: 100%;
	}

	.app-container-header {
		grid-area: header;
	}

	.app-container-main {
		position: relative;
		grid-area: main;
	}

	.app-container-footer {
		grid-area: footer;
	}
}

.pre-header {
	background-color: $preheader-bg-color;
	padding: $preheader-padding;
	border-top: 1px solid transparent;
	border-bottom: 1px solid $preheader-border-color;
	line-height: 35px;

	//sticky pre-header
	position: sticky;
	top: 0px;
	z-index: 111;

	.breadcrumb {
		padding-right: 10px;

		.breadcrumb-item {
			display: inline;
			float: none;
			a {
				font-weight: 600;
			}
		}
	}

	&.archived,
	&.blocked {
		@include archived(#e5e5e5);
	}

	&[style*='display: none'] + .content {
		margin-top: 0;
		height: calc(100% + 45px);
	}

	@include media-breakpoint-down(md) {
	}

	@include media-breakpoint-down(sm) {
		width: 100% !important;
		position: static;
		top: 0;

		& + .content,
		& + .header {
			margin-top: 0;
		}
	}
}

.header {
	background-color: $header-bg-color;
	padding: $header-padding;
	border-bottom: 1px solid $header-border-color;

	h1 {
		font-size: $header-title-size;
		font-weight: normal;
	}
	h4 {
		font-size: $header-subtitle-size;
		color: lighten($text-dark, 40%);
		font-weight: normal;
	}

	h3 {
		font-size: 24px;
		padding: 5px 10px 5px 0;
		margin-bottom: 5px;
		white-space: normal;
	}

	h3,
	h4 {
		@include editable;
	}

	.breadcrumb {
		margin-bottom: 10px;
		font-size: 16px;

		&.editable {
			cursor: pointer;

			//show as inline block
			//https://css-tricks.com/injecting-line-break/
			display: table !important;
			&.editable-open {
				display: none !important;
			}

			&:hover {
				li:last-child {
					&:after {
						content: '\f040';
						font-family: FontAwesome;
						color: #aaa;
						line-height: normal;
					}
				}
			}
		}
	}

	.editable-inline {
		margin-bottom: 5px;
		display: block;

		.form-group {
			width: 100%;
		}

		input {
			padding: 5px 10px;
			font-size: 24px;
			border: 0 none;
			box-shadow: none;
			background-color: #fdfde7;
			border-radius: 5px;
			height: auto;
			line-height: 1.1;
			width: 100%;
		}

		.has-error {
			input {
				background-color: $state-danger-bg;
				color: $state-danger-text;
			}

			.help-block {
				color: $state-danger-text;
			}
		}
	}

	.extra-info {
		margin-top: 5px;
		padding-left: 5px;

		&:hover,
		&:focus {
			text-decoration: none;
		}
	}

	// Don't cut off dropdowns in header
	.media-body {
		overflow: visible;
	}
}

.footer-buttons {
	margin-top: 1rem;
}

.paging {
	margin-left: 15px;
	button {
		outline: 0px none !important;

		&:active,
		&:focus {
			outline: 0px none !important;
		}
	}
}

.tabs {
	//border-bottom: 1px solid $tabs-border-color;
	padding: $tabs-padding;
	background-color: $tabs-bg-color;

	.nav-link {
		color: $tabs-color;
		padding: 5px 15px;

		&.active {
			border-bottom: 2px solid $tabs-active-border-color;
		}
	}
}

.tabs-minimal {
	padding: $tabs-padding-minimal;
}

.content {
	padding: $content-padding;
	position: relative;

	// CHECK IF THIS DOESN'T BREAK ANYTHING
	//overflow-x: hidden;

	&.empty-content,
	&.blank-content {
		height: 100%; //calc(100% - 95px);
	}

	h6 {
		font-size: 14px;
		text-transform: uppercase;
		font-weight: normal;
		color: $text-heading;
		margin-bottom: 10px;
	}

	.actions {
		@include clearfix;
		margin-bottom: $content-padding;

		.search {
			width: 370px;

			@include media-breakpoint-down(xs) {
				margin-bottom: 15px;
				width: 100%;
			}
		}
	}

	.advanced-filters {
		margin-top: -10px;
		margin-bottom: 15px;

		& > .btn-group {
			margin-top: 5px;

			&:not(:last-child) {
				margin-right: 4px;
			}
		}

		.select-picker {
			min-width: initial;

			.dropdown-menu {
				padding: 8px;
				min-width: 250px;
			}
		}

		.bootstrap-select > .dropdown-toggle {
			padding-right: 14px;
		}
		.bootstrap-select.btn-group .dropdown-toggle .filter-option {
			width: auto;
		}
	}

	.advanced-filters:not([style*='display: none']) + .empty {
		height: calc(100% - 75px);
	}
	.advanced-filters[style*='display: none'] + .empty {
		height: calc(100% - 45px);
	}

	.item-image {
		@include border-radius(5px);
		border: 1px solid #e5e5e5;
	}
	.more-image {
		@include border-radius(5px);
	}
}

.empty,
.blank {
	text-align: center;
	color: $text-secondary;

	i {
		font-size: 150px;
		color: $gray-200;
	}

	p {
		margin: 20px 0 10px;
	}

	h1 {
		color: $text-dark;
		font-size: 24px;
		margin-top: 20px;
		margin-bottom: 5px;
	}
	h1 + .lead {
		max-width: 70%;
		margin: 0 auto 20px;

		@include media-breakpoint-down(md) {
			max-width: 100%;
		}
	}

	.or {
		display: inline-block;
		margin: 0 10px;
	}

	.label-tag {
		vertical-align: middle;
	}

	&.empty-sm {
		i {
			font-size: 50px;
		}

		p {
			margin: 0px 0 5px;
		}
	}

	.empty-filters {
		small {
			display: block;
			margin-bottom: 5px;
			font-size: 16px;
			font-weight: $font-weight-light;
		}
	}

	.filter + .filter {
		margin-right: 5px;
	}

	.filter {
		display: inline-block;
		vertical-align: middle;
		min-width: 24px;
		padding: 4px 8px;
		background: #fff;
		border: 1px solid #e1e6eb;
		border-radius: 4px;
		font-weight: $font-weight-light;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: $body-color;
		text-align: center;
		text-decoration: none;
		margin-bottom: 5px;

		.fa {
			opacity: 0.4;
			font-size: 15px;
		}

		&:not(.filter-btn):after {
			opacity: 0.4;
			margin-left: 5px;
			content: '\f057';
			font-family: FontAwesome;
		}

		&.disabled {
			cursor: default;
			&:after {
				content: '';
			}
		}

		&:hover {
			&:after {
				opacity: 1;
			}
		}
	}
}

.faded {
	opacity: 0.5;
}

.text-center {
	text-align: center;
}
.text-right {
	text-align: right;
}
.text-left {
	text-align: left;
}

.vmiddle {
	vertical-align: middle !important;
}

.cover-image {
	position: relative;
	display: block;
	color: $text-secondary;
	cursor: default;

	img,
	.actions {
		transition: 0.3s all;
	}

	.actions {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 100;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.4);
		border-radius: 5px;

		li {
			margin: 2px;
			border: 1px solid #ccc;
			width: 40px;
			height: 40px;
			border-radius: 5px;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			color: #fff;

			&:hover {
				background-color: #fff;
				border-color: #fff;
				color: #333;
			}
		}
	}

	&:hover {
		color: $text-secondary;
		img {
			-webkit-filter: blur(2px);
			filter: blur(2px);
		}

		.actions {
			opacity: 1;
		}
	}

	&.disabled {
		pointer-events: none;
	}

	img {
		width: 128px;
		height: 128px;
	}
}

.disable-page {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.5);
	top: 0;
	left: 0;
	z-index: 100;
}

.in-kit {
	@include kit-badge();
}

.csv {
	display: inline-block;
	font-size: 80%;
	margin: 0;
	padding: 0;

	li {
		display: inline-block;
		margin-left: 5px;

		a {
			color: #818a91;

			&:visited,
			&:active,
			&:hover {
				color: #818a91;
			}
		}

		&:first-child {
			margin-left: 0;
		}

		&:after {
			content: ' ';
		}
	}
}

.fade-highlight {
	animation: target-fade 3s 1;
}
.fade-block {
	position: relative;
	display: inline-block;

	.fade-overlay {
		position: absolute;
		width: 100%;
		height: 100%;
	}
}

.help-link {
	color: lighten($text-dark, 20%);

	&:hover,
	&:focus,
	&:active {
		color: $text-dark;
		text-decoration: none;
	}

	&.help-link-o {
		&:after {
			content: '\f29c';
		}
	}

	&:after {
		content: '\f059';
		font-family: FontAwesome;
	}
}

.info-link {
	display: inline-block;
	margin: 0 5px;
}

@-webkit-keyframes target-fade {
	0% {
		background-color: rgba(255, 255, 0, 0.3);
	}
	100% {
		background-color: rgba(255, 255, 0, 0);
	}
}
@-moz-keyframes target-fade {
	0% {
		background-color: rgba(255, 255, 0, 0.3);
	}
	100% {
		background-color: rgba(255, 255, 0, 0);
	}
}

.required {
	color: $brand-danger;
}
