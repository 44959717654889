#item_depreciation {
	.card-group {
		.depreciation-value {
			font-size: 22px;
		}

		.editable-card {
			&.editing {
				background-color: #fdfde7;

				.form-inline .form-group {
					width: 100%;
					margin-bottom: 0px;

					.form-control {
						width: 100%;
					}
				}
			}

			&:not(.editing):hover {
				cursor: pointer;

				&:before {
					content: ' \f040';
					font-family: FontAwesome;
					color: $text-secondary;
					float: right;
					margin-top: 15px;
					margin-right: 15px;
					line-height: normal;
				}
			}
		}
	}

	.empty-chart {
		.empty-message {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			overflow: hidden;
			@include border-radius($card-border-radius);
		}
	}
}
