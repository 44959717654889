#imageModal {
	.modal-header {
		padding-bottom: 0px;

		.tabs {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.modal-body {
		height: 342px;

		.tab-content {
			height: 100%;

			.durandal-wrapper {
				height: 100%;
			}

			.card-upload {
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0;
				text-align: center;
				color: $text-secondary;

				a {
					color: $text-secondary;
				}

				h4 {
					margin-bottom: 5px;
				}
				button {
					margin-top: 5px;
				}
			}

			.placeholder {
				height: 300px;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
			}

			.thumbs {
				a {
					display: flex;
					align-items: center;
					justify-content: center;

					border: 1px solid #ccc;
					height: 132px;
					margin-bottom: 5px;
					border-radius: 4px;
					overflow: hidden;

					img {
						max-width: 100%;
						max-height: 100%;
					}

					&.active {
						border-color: $brand-primary;
					}

					&.error {
						border-color: $red-500;
					}

					@include media-breakpoint-down(xs) {
						height: 177px;
					}
				}
			}
		}
	}
}
