.navigation-sidebar + .app-container {
	#nuvo-app-root-element {
		div:has(#nuvo-footer-root-items, #nuvo-footer-root-contacts),
		.match-form-footer {
			&.left-0.fixed {
				left: 312px;
			}
		}
	}
}
.navigation-sidebar.collapsed + .app-container {
	#nuvo-app-root-element {
		div:has(#nuvo-footer-root-items, #nuvo-footer-root-contacts),
		.match-form-footer {
			&.left-0.fixed {
				left: 70px;

				@media (max-width: 768px) {
					left: 0px;
				}
			}
		}
	}
}
