.modal-dialog {
	.results-list {
		margin-top: 15px;

		& > a,
		label {
			display: block;
			color: $text-dark;
			padding: 10px 5px;
			border-bottom: 1px solid #dcdcdc;
			position: relative;
			margin: 0;

			&:last-child {
				border-bottom: 0px none;
			}

			&.selected,
			&:hover {
				background-color: #f5f5f5;
			}

			&.selected,
			&:hover,
			&:focus,
			&:active {
				text-decoration: none;
			}

			h4 {
				font-size: 18px;
			}

			img {
				width: 48px;
				height: 48px;
				border-radius: 3px;
			}

			.hint {
				position: absolute;
				right: 15px;
				top: 10px;
				font-size: 90%;
			}
		}
	}

	.results-empty,
	.results-loading {
		height: 285px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;

		&.results-empty {
			i {
				font-size: 50px;
			}
			p {
				margin: 5px 0 15px;
			}
		}
	}
}
