.select > select {
	-webkit-appearance: none;
	appearance: none;
	width: 100%;
	outline: none;

	&:not([disabled]):hover {
		background-color: $gray-50;
	}
}

select,
.select::after {
	grid-area: select;
}

.select {
	display: grid;
	grid-template-areas: 'select';
	align-items: center;
	min-width: 60px;

	&::after {
		content: '';
		width: 16px;
		height: 16px;
		margin-right: 10px;
		background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 9L12 16L5 9' stroke='%231F2C41' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
		background-size: contain;
		justify-self: end;
		pointer-events: none;
	}
}
