.popover-calendar-info {
	min-width: 350px;
	max-width: 350px;

	.fromto {
		margin-right: 15px;
	}

	.duration {
		margin-left: 15px;
	}

	.header {
		border-bottom: 1px solid #ddd;
		margin: -10px -15px 10px;
		padding: 10px 15px;

		.kind {
			text-transform: uppercase;
		}
	}

	.title {
		font-size: 15px;
		font-weight: 500;
	}

	.images {
		margin-top: 10px;
	}

	.fields {
		margin-top: 10px;
	}

	.repeating {
		margin-top: 10px;
	}

	.item-image,
	.more-image {
		width: 32px;
		height: 32px;
		border: 1px solid #ccc;
		border-radius: 3px;
	}
}
