.new-progressbar {
	$height: 5px;

	position: relative;
	width: 100%;
	height: $height;
	border-radius: $height;
	overflow: hidden;

	&-empty {
		background-color: $gray-100;
	}

	&-overshoot {
		background-color: $red-900;
	}

	&-fill-indigo,
	&-fill-red {
		position: absolute;
		left: 0;
		top: 0;
		height: $height;
		border-radius: 0;
	}

	&-fill-indigo {
		background-color: $indigo-500;
	}

	&-fill-red {
		background-color: $red-500;
	}
}
