.popover-kit-info {
	.result-title {
		font-weight: $font-weight-bold;
		line-height: 18px;
		font-size: 14px;

		small {
			color: $text-secondary;
			font-weight: normal;
		}
	}
	.text-muted {
		font-size: 90%;
	}
	.label-incomplete {
		display: inline;
	}

	img {
		margin-right: 0px;
		border: 1px solid #eee;
		width: 48px;
		height: 48px;
		border-radius: 5px;
	}
	.icon {
		margin-right: 0px;
		padding-top: 4px;
		width: 48px;
		height: 48px;
		font-size: 16px;
		text-align: center;
		border: 1px solid #eee;
	}
}
