.input-group .input-group-addon {
	background-color: transparent;
}

.form-group:not(.has-danger) {
	input.form-control:hover,
	.input-group:hover {
		background-color: $gray-50 !important;
		border-color: $gray-400 !important;
	}
}

.input-group-btn.dropdown {
	display: flex;
	width: min-content;
	margin-right: 8px;
}

.search.input-group {
	display: flex;
	margin-right: 4px;
}

.disconnected-search {
	position: relative;
	display: flex;
	width: 100%;

	& > input.form-control {
		padding-left: 30px;
	}

	&::after {
		content: '\f002';
		font-family: FontAwesome;
		position: absolute;
		top: 8px;
		left: 8px;
		color: $gray-400;
		z-index: 3;
	}
}

.input-group-btn:not(:last-child) > .dropdown-toggle {
	border-bottom-right-radius: $btn-border-radius;
	border-top-right-radius: $btn-border-radius;
}

.input-group .form-control:not(:first-child) {
	border-bottom-left-radius: $border-radius;
	border-top-left-radius: $border-radius;
}

.input-group input:last-child {
	border-left: 1px solid $input-border-color;

	&:focus {
		border-left-color: $input-border-focus;
	}
}

.dropdown-item,
.select-picker .dropdown-menu .results .dropdown-item,
.select-picker .list .results .dropdown-item,
.popover-custom-filter .popover-content .filterList .results .dropdown-item {
	padding: $dropdown-item-padding-y $dropdown-item-padding-x;
	line-height: normal;
}

.dropdown-menu li.disabled {
	display: none;
}

.form-group {
	& > label {
		color: $gray-700;
		font-size: 14px;
		font-weight: 500;
		line-height: 16px;
	}
}

.fields {
	.invalid-field {
		color: $red-500;
	}

	.has-error {
		input {
			border-color: $red-500;
			color: $red-500;
		}
		.help-block {
			color: $red-500;
		}
	}
}

.dropdown-toggle.bs-placeholder {
	color: $text-secondary !important;
}

.settings-card {
	@include media-breakpoint-up(md) {
		float: right;
		width: 66.66%;
	}
}

.form-control {
	&:focus {
		box-shadow: 0 0 0 4px $indigo-100;
		outline-offset: 2px;
		outline: 2px solid transparent;
	}
}

.has-danger {
	.form-control-label {
		color: $gray-600;
	}

	.form-control {
		color: $gray-700;
	}

	.form-control {
		border-color: $red-300;
		
		&:focus {
			outline-color: $red-100;
		}
	}

	.form-control::placeholder {
		color: $gray-400;
	}

	.form-control-feedback {
		color: $red-700;
		font-size: 12px;
	}
}
