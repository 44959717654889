#column-modal {
	max-width: 800px;

	.col-md-8 {
		padding-right: 15px;
		margin-right: 10px;
		width: calc(66.6666666667% - 20px);
		border-right: 1px solid #eee;

		@include media-breakpoint-down(sm) {
			width: 100%;
			border: 0px none;
		}
	}

	#available-columns {
		height: 250px;
		overflow: auto;
		margin-top: 10px;

		.form-check {
			padding: 5px 5px 10px 5px;
			border-bottom: 1px solid #eee;
		}
		.empty {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		h4 {
			margin-top: 10px;
			margin-bottom: 10px;
			display: block;
			font-size: 0.875rem;
			color: #818a91;
			white-space: nowrap;
		}
	}

	#selected-columns {
		height: 250px;
		overflow: auto;
		margin-top: 10px;

		li {
			padding: 5px 10px;
			margin-right: 15px;
			border: 1px solid lighten($brand-primary, 25%);
			background-color: lighten($brand-primary, 35%);
			margin-bottom: 10px;
			border-radius: 5px;
			position: relative;

			.drag-handle {
				margin-right: 5px;
				color: #aaa;
				cursor: move;
			}

			.remove {
				position: absolute;
				right: 0px;
				top: 0;
				font-size: 10px;
				color: $brand-primary;
				height: 100%;
				width: 20px;
				padding: 7px 0;
				text-align: center;
			}
		}
	}

	.modal-body {
		h4 {
			text-transform: uppercase;
			font-size: 14px;
		}
	}
}
