@import 'mixins/kit';

@mixin dotted {
	&.dotted {
		border-style: dotted;
		border-width: 2px;
	}
}

@mixin dashed {
	&.dashed {
		border-style: dashed;
		border-width: 2px;
		border-color: $gray-300;
	}
}

//https://jacobrask.github.io/styledocco/styledocco/examples/bootstrap/docs/mixins.html
@mixin striped($color, $angle: -45deg, $opacity: 0.15) {
	background-color: $color;
	background-image: -webkit-gradient(
		linear,
		0 100%,
		100% 0,
		color-stop(0.25, rgba(255, 255, 255, $opacity)),
		color-stop(0.25, transparent),
		color-stop(0.5, transparent),
		color-stop(0.5, rgba(255, 255, 255, $opacity)),
		color-stop(0.75, rgba(255, 255, 255, $opacity)),
		color-stop(0.75, transparent),
		to(transparent)
	);
	background-image: -webkit-linear-gradient(
		$angle,
		rgba(255, 255, 255, $opacity) 25%,
		transparent 25%,
		transparent 50%,
		rgba(255, 255, 255, $opacity) 50%,
		rgba(255, 255, 255, $opacity) 75%,
		transparent 75%,
		transparent
	);
	background-image: -moz-linear-gradient(
		$angle,
		rgba(255, 255, 255, $opacity) 25%,
		transparent 25%,
		transparent 50%,
		rgba(255, 255, 255, $opacity) 50%,
		rgba(255, 255, 255, $opacity) 75%,
		transparent 75%,
		transparent
	);
	background-image: -ms-linear-gradient(
		$angle,
		rgba(255, 255, 255, $opacity) 25%,
		transparent 25%,
		transparent 50%,
		rgba(255, 255, 255, $opacity) 50%,
		rgba(255, 255, 255, $opacity) 75%,
		transparent 75%,
		transparent
	);
	background-image: -o-linear-gradient(
		$angle,
		rgba(255, 255, 255, $opacity) 25%,
		transparent 25%,
		transparent 50%,
		rgba(255, 255, 255, $opacity) 50%,
		rgba(255, 255, 255, $opacity) 75%,
		transparent 75%,
		transparent
	);
	background-image: linear-gradient(
		$angle,
		rgba(255, 255, 255, $opacity) 25%,
		transparent 25%,
		transparent 50%,
		rgba(255, 255, 255, $opacity) 50%,
		rgba(255, 255, 255, $opacity) 75%,
		transparent 75%,
		transparent
	);
}

@mixin archived($stripeColor: rgba(0, 0, 0, 0.05), $bgColor: #fff) {
	background-color: #fff;
	background: linear-gradient(
		to bottom right,
		$stripeColor 25%,
		$bgColor 25%,
		$bgColor 50%,
		$stripeColor 50%,
		$stripeColor 75%,
		$bgColor 75%,
		$bgColor
	);
	background-size: 14px 14px;
	color: #777;
}

// https://tailwindcss-forms.vercel.app/
// https://gist.github.com/certainlyakey/e9c0d8f5c87ff47e3d5b
@function encodeColor($string) {
	@if type-of($string)=='color' {
		$hex: str-slice(ie-hex-str($string), 4);
		$string: unquote('#{$hex}');
	}

	$string: '%23' + $string;
	@return $string;
}

//http://projects.lukehaas.me/css-loaders/
@mixin loader($loaderName, $color, $loaderSize: 24px) {
	.#{$loaderName} {
		width: $loaderSize;
		height: $loaderSize;
		background-size: $loaderSize;
		background-repeat: no-repeat;
		background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 11.4477 20.4477 11 21 11C21.5523 11 22 11.4477 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C12.5523 2 13 2.44772 13 3C13 3.55228 12.5523 4 12 4Z' fill='#{encodeColor($color)}' /%3E%3C/svg%3E");
		animation: spin 1s linear infinite;	
	}

	@keyframes spin {
		to {
			transform: rotate(360deg);
		}
	}
}

@mixin progress($progressName, $bgColor, $progressSize: 60px, $progressInnersize: 8px) {
	.#{$progressName} {
		margin: auto;
		font-size: 10px;
		position: relative;
		text-indent: -9999em;
		border-top: $progressInnersize solid darken($bgColor, 10%);
		border-right: $progressInnersize solid darken($bgColor, 10%);
		border-bottom: $progressInnersize solid darken($bgColor, 10%);
		border-left: $progressInnersize solid darken($bgColor, 35%);
		-webkit-transform: translateZ(0);
		-ms-transform: translateZ(0);
		transform: translateZ(0);

		@for $i from 0 to 101 {
			&[data-value='#{$i}'] {
				-webkit-transform: rotate($i * 1deg);
				transform: rotate($i * 1deg);
			}
		}
	}
	.#{$progressName},
	.#{$progressName}:after {
		border-radius: 50%;
		width: $progressSize;
		height: $progressSize;
	}
}

@mixin editable {
	&.editable:not(.editable-disabled) {
		cursor: pointer;

		//show as inline block
		//https://css-tricks.com/injecting-line-break/
		display: table !important;
		&.editable-open {
			display: none !important;
		}

		&:hover {
			&:after {
				margin-left: 5px;
				content: '\f040';
				font-family: FontAwesome;
				color: #aaa;
				line-height: normal;
			}
		}
	}
}

@mixin tag-normal {
	.tag {
		display: inline-block;
		padding: 5px 4px;
		font-size: 75%;
		font-weight: $font-weight-bold;
		line-height: 1;
		color: #fff;
		text-align: center;
		white-space: nowrap;
		vertical-align: baseline;
		border-radius: 0.25rem;
		position: static;
		height: auto;
	}
}

@mixin tag-small($top: 3px) {
	.tag {
		position: absolute;
		left: 37px;
		top: $top;
		font-size: 9px;
		line-height: 14px;
		padding: 0 4px;
		border-radius: 9px;
	}
}

// generic transform
@mixin transform($transforms) {
	-moz-transform: $transforms;
	-o-transform: $transforms;
	-ms-transform: $transforms;
	-webkit-transform: $transforms;
	transform: $transforms;
}
// rotate
@mixin rotate($deg) {
	@include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
	@include transform(scale($scale));
}
// translate
@mixin translate($x, $y) {
	@include transform(translate($x, $y));
}
// skew
@mixin skew($x, $y) {
	@include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin($origin) {
	moz-transform-origin: $origin;
	-o-transform-origin: $origin;
	-ms-transform-origin: $origin;
	-webkit-transform-origin: $origin;
	transform-origin: $origin;
}

@function makelongshadow($color) {
	$val: 0px 0px $color;
	@for $i from 1 through 200 {
		$val: #{$val}, #{$i}px #{$i}px #{$color};
	}
	@return $val;
}
@mixin longshadow($color) {
	text-shadow: makelongshadow($color);
}
