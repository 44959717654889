.table-list {
	.item-image,
	.more-image {
		width: 36px;
		height: 36px;
	}
}

.kit-card {
	min-height: 180px;

	.card-block {
		padding: 10px;
		height: 84px;

		img {
			width: 64px;
		}

		item-images {
			width: max-content;
			display: block;

			img {
				border: 1px solid #ccc;
				border-radius: 4px;
				width: 48px;
			}
		}
	}
}

#kits-new {
	#equipment-view {
		margin-bottom: 0;
	}

	a[data-toggle='collapse'] {
		&:after {
			content: 'Show less kit info';
		}

		&.collapsed {
			&:after {
				content: 'Add more kit info';
			}
		}
	}
}

#kit_map {
	height: calc(100% - 35px);

	.gmap {
		overflow: hidden;
	}

	.row {
		height: 100%;
	}

	.col-md-8 {
		height: 100%;
		padding: 0;
	}

	.col-md-4 {
		height: 100%;
		padding: 0;

		.address-list {
			min-height: 360px;
			overflow-y: auto;
			margin: 0;
			overflow-x: hidden;
			margin-right: 7px;
			border-left: 1px solid $gray-200;

			.address {
				padding: 10px;
				border-top: 1px solid $gray-200;

				a {
					color: #333;

					&:hover {
						text-decoration: none;
					}
				}
				img {
					width: 40px;
					height: 40px;
				}
			}

			h4 {
				display: block;
				font-size: 0.875rem;
				color: $gray-700;
				white-space: nowrap;
				padding: 10px;
				background-color: $gray-50;
				margin: 0;
			}
		}
	}
}
