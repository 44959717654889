#activityFeed {
	.feed-wrapper {
		width: 400px;
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		background-color: #fff;
		z-index: 9999;
	}
}
