#report-page {
	height: 100%;

	.desc + .count {
		margin-top: 15px;
	}

	.total-row {
		/*td{
			border-top: 1px solid #555;
			border-bottom: 1px solid #555;
		}*/
		font-weight: $font-weight-bold;
	}

	.list-group-item {
		border-top: 0px none;
		border-left: 0px none;
		border-right: 0px none;
		border-bottom: 0px none;

		&:not(:last-child) {
			border-bottom: 2px solid #ddd;
		}
	}

	.report-options {
		margin-bottom: 15px;
		padding: 15px;
		background-color: rgba(238, 238, 238, 1);
	}

	#reportFilters {
		margin-top: 10px;

		display: inline-block;

		.filter {
			display: inline-block;
			background-color: #fff;
			border: 1px solid #ccc;
			padding: 4px 5px;
			vertical-align: middle;
			border-radius: 2px;

			.kind {
				margin-right: 3px;
			}

			&:hover {
				cursor: pointer;
				&:after {
					opacity: 1;
				}
			}

			&:after {
				opacity: 0.3;
				content: '\f057';
				font-family: FontAwesome;
				margin-left: 5px;
			}
		}
	}

	#selectedGroups {
		display: inline-block;

		span {
			display: inline-block;
			background-color: $report-selected-group-color;
			color: #fff;
			margin-right: 5px;
			padding: 4px 5px;
			vertical-align: middle;
			border-radius: 5px;

			&:hover {
				cursor: pointer;
				&:after {
					opacity: 1;
				}
			}

			&:after {
				opacity: 0.8;
				content: '\f057';
				font-family: FontAwesome;
				margin-left: 5px;
			}
		}
	}

	//group by
	.level-0 {
		line-height: 40px;
		border-top: 2px solid #ddd;

		font-size: 16px;
	}
	.level-1 {
		background-color: #fafafa;
	}

	.report {
		background-color: #fff;
		padding: 60px 40px;
		box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
		min-height: 100%;
		margin-bottom: 15px;

		.loading-overlay {
			position: absolute;
			inset: 0;
			z-index: 1;

			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: $border-radius;

			background-color: rgba(255, 255, 255, 0.9);

			svg {
				animation: spin 1.25s linear infinite;
			}
		}

		.report-head {
			margin-bottom: 30px;
			position: relative;

			h1 {
				font-weight: 400;

				.parent {
					color: $brand-primary;
				}
			}

			h3 {
				font-size: 18px;
				color: $text-secondary;
				font-weight: normal;
				margin-bottom: 0;
			}
		}

		.report-charts {
			margin-bottom: 20px;
		}

		.no-results {
			padding: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.chart-wrapper {
			min-height: 150px;
		}

		.tag-pill {
			display: inline-block;
			text-align: center;
			min-width: 60px;
			color: #fff;
			font-size: 13px;
			font-weight: normal;
			padding: 2px 5px;
			line-height: 17px;
		}
	}

	&.loading {
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(255, 255, 255, 0.7);
		}

		.loader {
			display: block;
		}
	}

	.loader {
		display: none;
		position: fixed;
		top: 50%;
		left: 50%;
		background-color: #fff;
		padding: 25px;
		width: 250px;
		box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
		z-index: 50;

		.progress {
			margin: 0;
		}

		.progress-bar {
			height: 10px;
			border-radius: 5px;
			margin: 0;
			display: -ms-flexbox;
			display: flex;
			-ms-flex-direction: column;
			flex-direction: column;
			-ms-flex-pack: center;
			justify-content: center;
			color: #fff;
			text-align: center;
			white-space: nowrap;
			background-color: #007bff;
			transition: width 0.6s ease;
		}

		.progress-bar-striped {
			background-image: linear-gradient(
				45deg,
				rgba(255, 255, 255, 0.15) 25%,
				transparent 25%,
				transparent 50%,
				rgba(255, 255, 255, 0.15) 50%,
				rgba(255, 255, 255, 0.15) 75%,
				transparent 75%,
				transparent
			);
			background-size: 1rem 1rem;
		}

		.progress-bar-animated {
			-webkit-animation: progress-bar-stripes 1s linear infinite;
			animation: progress-bar-stripes 1s linear infinite;
		}
	}

	.select-picker {
		min-width: auto;

		.dropdown-menu {
			min-width: 250px;
		}
	}
}

.usage-table {
	.header {
		border-bottom: 2px solid #000;

		td {
			border: 0px none;
			font-size: 18px;
		}
	}

	.subheader {
		border-bottom: 2px solid #ccc;

		td {
			font-size: 16px;
			border-top: 0px none;
		}
	}

	.footer {
		th {
			border-top: 2px solid #000;
		}
	}

	.spacer {
		border: 0px none;
	}
	.spacer-row {
		border: 0px none;
		height: 5px;
		padding: 0;
	}

	tr:not(.header) {
		td:first-child,
		th:first-child {
			padding-left: 35px;
			position: relative;

			&:after {
				content: ' ';
				position: absolute;
				bottom: -4px;
				left: 0;
				background-color: #fff;
				width: 20px;
				height: 5px;
			}
		}

		&.thead {
			td {
				border: 0px none;
				padding: 5px 0.75rem 0px 0.75rem;
				text-transform: uppercase;

				&:first-child {
					padding-left: 35px;
				}
			}
		}

		&.evt_0 {
			td {
				border-top: 0px none;
			}
		}
	}
}

.report-skeleton {
	.report-head {
		h1 {
			width: 450px;
			background-color: $report-skeleton-color;
			height: 50px;
		}
		h3 {
			width: 100px;
			background-color: $report-skeleton-color;
			height: 20px;
		}
	}

	.report-charts {
		.card-header {
			span {
				width: 100px;
				height: 16px;
				background-color: $report-skeleton-color;
				display: inline-block;
			}
		}

		.piechart {
			.chart-wrapper {
				span {
					display: inline-block;
					width: 200px;
					height: 200px;
					border-radius: 50%;
					background-color: $report-skeleton-color;
				}
			}
		}

		.list {
			.col-xs-5,
			.col-xs-7 {
				span {
					width: 100px;
					height: 15px;
					background-color: $report-skeleton-color;
					display: inline-block;
				}
			}
		}
	}

	.table {
		th,
		tr {
			span {
				width: 100px;
				height: 15px;
				background-color: $report-skeleton-color;
				display: inline-block;
			}
		}
	}

	.animate {
		animation: shimmer 2s infinite linear;
		background: linear-gradient(
			to right,
			$report-skeleton-color 4%,
			$report-skeleton-highlight-color 25%,
			$report-skeleton-color 36%
		);
		background-size: 1000px 100%;
	}

	@keyframes shimmer {
		0% {
			background-position: -1000px 0;
		}
		100% {
			background-position: 1000px 0;
		}
	}
}

.report-grouping {
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	#selectedGroups {
		margin-left: 8px;
	}
}
