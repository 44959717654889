.card .table th {
	border-top: none;
}

.table thead td {
	border-top: none;
}

.table thead th {
	color: $gray-500;
	font-weight: 600;
	line-height: 18px;
}

.table {
	thead th {
		border-bottom: $table-border-width solid $table-border-color;
	}

	tbody + tbody {
		border-top: $table-border-width solid $table-border-color;
	}
}

.table-responsive {
	overflow-y: hidden;

	&.show-overflow {
		overflow: visible;
	}
}

.row-meta {
	display: inline-grid;
	grid-template-columns: min-content minmax(0, 1fr);
	grid-template-rows: 1fr;
	width: 370px;
}

tr:hover .row-meta {
	width: 280px;
}

.row-fields {
	width: 100%;

	& > ul {
		width: 100%;

		& > li {
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 100%;
			line-height: 12px;
		}
	}
}
