#equipmentListModal {
	height: 100%;
	transition: none;

	.modal-content {
		display: flex;
		overflow: hidden;
	}

	@include media-breakpoint-down(sm) {
		.preview {
			display: none;
		}
	}

	.sidebar {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 400px;
		background-color: #fafafa;
		overflow-y: auto;

		@include media-breakpoint-down(sm) {
			width: 100%;
		}

		.lbl {
			text-transform: uppercase;
			font-size: 90%;
			font-weight: $font-weight-bold;
		}

		#eel-accordion {
			background-color: #fff;
			box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
			border-top: 1px solid rgba(0, 0, 0, 0.1);

			button {
				background: none;
				border: 0px none;
				display: block;
				border-radius: 0;
				outline: 0px none;
				padding: 15px;
				width: 100%;
				font-weight: $font-weight-normal;
				text-align: left;
				border-bottom: 1px solid rgba(0, 0, 0, 0.1);

				.fa {
					width: 30px;
					opacity: 0.7;
				}

				&[aria-expanded='true'] {
					&:after {
						content: '\f106';
						font-family: FontAwesome;
						top: 15px;
						right: 15px;
						position: absolute;
						opacity: 0.4;
					}
				}

				&:after {
					content: '\f107';
					font-family: FontAwesome;
					top: 15px;
					right: 15px;
					position: absolute;
					opacity: 0.4;
				}
			}
		}

		header {
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;
			min-height: 57px;
			width: 100%;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			background: white;
			-webkit-box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
			box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
			z-index: 1;
			-webkit-box-flex: 0;
			-webkit-flex: none;
			-ms-flex: none;
			flex: none;

			h2 {
				font-size: 15px;
				margin-bottom: 0px;
				font-weight: $font-weight-bold;
				padding: 0 15px;

				.fa {
					width: 25px;
				}
			}
		}

		.panel {
			display: none;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-webkit-flex-direction: column;
			-ms-flex-direction: column;
			flex-direction: column;
			position: absolute;
			z-index: 2;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: #f9fafb;
			-webkit-transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
			transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
			-webkit-transform: translateX(100%);
			transform: translateX(100%);

			&.is-active {
				-webkit-transform: translateX(0);
				transform: translateX(0);
			}
			&.is-visible {
				display: flex;
			}

			.panel-content {
				overflow-y: auto;
				margin-bottom: 64px;
			}
		}

		footer {
			padding: 15px;
			border-top: 1px solid #ccc;
			text-align: right;
			background-color: #fff;
			position: absolute;
			bottom: 0;
			z-index: 555;
			width: 100%;
		}
	}

	.preview {
		position: absolute;
		left: 400px;
		top: 0;
		bottom: 0;
		width: calc(100% - 400px);
		height: 100%;

		.preview-wrapper {
			background-color: #f4f6f8;
			padding: 10px 10px 0 10px;
			display: flex;
			width: 100%;
			height: 100%;

			iframe {
				width: 100%;
				height: 100%;
				border: 0px none;
				box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 0 12px rgba(0, 0, 0, 0.1);
				pointer-events: none;
			}
		}
	}

	.sp-replacer,
	.sp-replacer:hover,
	.sp-replacer.sp-active {
		border: none;
		padding: 0;
		background: none;
		flex: none;
		flex-shrink: 0;
	}
	.sp-dd {
		display: none;
	}

	.sp-preview {
		border-radius: 3px;
		border: none;
		margin: 0;
		height: 19px;
		width: 38px;
	}

	.sp-preview-inner {
		border-radius: inherit;
		box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
	}

	.sp-replacer + label {
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		padding-left: 0.8rem;
		margin-bottom: 0;
		min-height: 1.9rem;
	}

	.color-picker {
		position: relative;
		box-sizing: border-box;
		margin: 0;
		display: flex;
		flex-direction: column;
		padding: 8px 0;
		max-width: 100%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;

		label {
			font-weight: $font-weight-normal;
			cursor: pointer;
		}
	}

	section {
		background-color: #fff;
		margin-bottom: 15px;
		border-top: 1px solid rgba(0, 0, 0, 0.1);
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);

		padding: 15px;

		&:first-child {
			margin-top: 15px;
		}

		.toggle-switch + .lbl {
			margin-bottom: 0;
		}

		.select-picker {
			border: 1px solid rgba(0, 0, 0, 0.1);
			border-radius: 5px;
			padding: 10px;

			.search {
				padding: 0;

				.wrapper {
					position: relative;
					margin-bottom: 10px;

					.input-icon {
						position: absolute;
						left: 8px;
						top: 9px;
					}

					input {
						padding-left: 30px;
					}
				}
			}

			.results {
				height: 150px;
				overflow-y: auto;

				.dropdown-item {
					padding: 7px 5px;

					label {
						pointer-events: none;
					}
				}
			}
		}
	}
}

.sp-container.sp-container {
	margin-top: 11px;
	border: 1px solid #c0c0c0;
	background: #ffffff;
	-webkit-box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
	box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
	border-radius: 6px;
	overflow: visible;

	&:after {
		position: absolute;
		content: '';
		width: 0;
		height: 0;
		border-top: none;
		border-right: 8px solid transparent;
		border-left: 8px solid transparent;
		top: -6px;
		border-bottom: 7px solid #ffffff;
		left: 10px;
	}
	&:before {
		position: absolute;
		right: 10px;
		content: '';
		width: 0;
		height: 0;

		left: 10px;
		border-top: none;
		border-right: 8px solid transparent;
		border-left: 8px solid transparent;
		top: -7px;
		border-bottom: 7px solid #c2c2c2;
	}

	.sp-hue,
	.sp-color,
	.sp-val {
		border: none;
		border-radius: 3px;
	}

	.sp-slider {
		opacity: 1;
		height: 6px;
		border: 1px solid #c3cfd8;
		width: 23px;
		border-radius: 3px;
		left: -3px;
	}

	.sp-dragger {
		background: none;
		border-width: 2px;
		width: 8px;
		height: 8px;
	}

	.sp-clear {
		top: 166px;
		left: 122px;
		width: 70px;
		height: 35px;
		border-radius: var(--p-border-radius-base, 3px);
		border-color: #c4cdd5;
	}

	.sp-hue {
		width: 19px;
		top: 0;
		height: 100%;
		left: auto;
		right: 0;
		border-radius: 3px;
	}

	.sp-color {
		right: 26px;
	}

	.sp-picker-container {
		width: 192px;
	}

	.sp-input {
		text-transform: uppercase;
		padding-left: 40px;
		padding-top: 4px;
		background-color: white;
		height: 35px;
		border-radius: 3px;
		-webkit-transition-property: background, border, -webkit-box-shadow;
		transition-property: background, border, -webkit-box-shadow;
		transition-property: background, border, box-shadow;
		transition-property: background, border, box-shadow, -webkit-box-shadow;
		-webkit-transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
		transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
		-webkit-transition-duration: 200ms;
		transition-duration: 200ms;
		-webkit-box-shadow: inset 0 1px 0 0 rgba(63, 63, 68, 0.05);
		box-shadow: inset 0 1px 0 0 rgba(63, 63, 68, 0.05);
		border-color: #c4cdd5;
		border-style: solid;
	}

	.sp-input-container {
		border-radius: 3px;

		&:before {
			background: inherit;
			content: '';
			position: absolute;
			margin-top: 7px;
			width: 20px;
			height: 20px;
			left: 20px;
			border-radius: 50%;
			-webkit-box-shadow: inset rgba(0, 0, 0, 0.07) 0 0 0 1px, inset rgba(0, 0, 0, 0.15) 0 1px 3px 0;
			box-shadow: inset rgba(0, 0, 0, 0.07) 0 0 0 1px, inset rgba(0, 0, 0, 0.15) 0 1px 3px 0;
		}
	}
}

#embedModal {
	textarea {
		font-family: Consolas, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono,
			Courier New;
	}
}

/* Basic Accordion Styles */
.accordion {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: #fff;
	z-index: 555;

	/* Radio Inputs */
	input[name='panel'] {
		display: none;
	}

	/* Labels */
	.accordion__label {
		position: relative;
		display: block;
		padding: 1em;
		background: #fff;
		border-top: 1px solid #fff;
		cursor: pointer;
		margin: 0;
		border-bottom: 1px solid rgba(0, 0, 0, 0.05);
		border-top: 1px solid rgba(0, 0, 0, 0.05);
		display: flex;
		align-items: center;
		min-height: 57px;
		font-size: 15px;
		font-weight: $font-weight-bold;
		padding: 0 15px;

		.fa {
			width: 25px;
		}

		.tag {
			margin-left: 5px;
		}

		&:after {
			content: '\f105';
			font-family: FontAwesome;
			top: 15px;
			right: 15px;
			position: absolute;
			opacity: 0.4;
		}

		&:hover {
			color: $brand-primary;
		}
	}

	.accordion__label.active {
		color: $brand-primary;
		box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);

		&:after {
			content: '\f107';
			font-family: FontAwesome;
			top: 15px;
			right: 15px;
			position: absolute;
			opacity: 0.4;
		}
	}

	/* Panel Content */
	.accordion__content {
		overflow-y: auto;
		height: 0px;
		position: relative;
		background: #f5f5f5;
		transition: height 0.4s cubic-bezier(0.865, 0.14, 0.095, 0.87);

		.reset-defaults {
			font-size: 90%;
		}
	}
}

/*
* Size Variations
*/

input[name='panel']:checked + .accordion__content {
	height: calc(100% - 290px);
}

input[name='panel']:checked + .accordion__content + label {
	border-top: 0px none;
	box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
}
