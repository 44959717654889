.integrations-page {
	@include media-breakpoint-up(md) {
		.integration-col {
			max-width: 300px;
		}
	}

	.integrations {
		.integration-col.disabled {
			opacity: 0.5;
			pointer-events: none;

			a {
				visibility: hidden;
			}
		}

		.card {
			.card-block {
				padding: 25px;
			}

			h4 {
				font-size: 16px;
				font-weight: $font-weight-bold;
				margin: 15px 0 5px;
			}

			p {
				min-height: 60px;
			}

			a {
				font-weight: $font-weight-bold;
			}

			.tag-primary {
				position: absolute;
				right: 10px;
				top: 10px;
				padding: 5px;
			}
		}
	}
}

.tag-upgrade {
}

.integration-page {
	.integration-header {
		background-color: $gray-50;
		padding-bottom: 100px;

		a {
			text-transform: uppercase;
		}
	}

	.integration-settings {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: -72px auto auto auto;
		max-width: 800px;

		.empty {
			min-height: 290px;

			p {
				margin-bottom: 10px;
			}
		}

		pre {
			display: block;
			padding: 9.5px;
			margin: 0 0 10px;
			font-size: 13px;
			line-height: 1.42857143;
			color: $text-dark;
			word-break: break-all;
			word-wrap: break-word;
			background-color: #f5f5f5;
			border: 1px solid #ccc;
			border-radius: 4px;
		}

		code {
			padding: 2px 4px;
			font-size: 90%;
			color: #c7254e;
			background-color: #f9f2f4;
			border-radius: 4px;
		}
	}

	.triggers {
		.media {
			border-bottom: 1px solid #ccc;
			padding: 20px 0;

			&:last-child {
				border-bottom: 0px none;
			}
		}
	}
}

.sso-settings {
	.select-picker .selection-text {
		width: calc(100% - 15px);
	}

	.logo-container {
		padding: 20px;
		border: 1px solid $gray-300;
		text-align: center;
		border-radius: 5px;
		background-color: $gray-50;

		& + .btn {
			margin-top: -2px;
			border-top-left-radius: 0px;
			border-top-right-radius: 0px;
		}
	}

	ol {
		list-style: decimal;
		margin-left: 22px;
	}

	.card-upload {
		height: 200px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		flex-direction: column;
	}

	.help-text {
		margin-top: 2px;
		color: $text-secondary;
	}

	.roles-source-list {
		label {
			display: flex;

			input {
				min-width: 16px;
				min-height: 16px;
				margin-top: 3px;
			}

			.label-text {
				margin-left: 10px;
				display: flex;
				flex-direction: column;
			}
		}
	}

	#sso-accordion {
		.accordion-step {
			padding: 30px 20px;
			margin-right: 15px;
			width: 100%;

			border-left: 1px solid #ddd;
			border-right: 1px solid #ddd;

			&:last-child {
				border-bottom: 1px solid #ddd;
			}
		}

		.list-group-item {
			text-decoration: none !important;

			&.disabled {
				pointer-events: none;

				opacity: 0.5;

				h4 {
					span,
					i {
						background-color: $gray-500;
					}
				}
			}

			h4 {
				margin: 0;
				display: flex;
				align-items: center;

				span {
					width: 30px;
					height: 30px;
					color: white;
					border-radius: 50%;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					background-color: $brand-primary;
					margin-right: 15px;
					font-size: 14px;
				}

				i {
					margin-left: 5px;
					padding: 5px;
					font-style: normal;
					font-size: 10px;
					text-transform: uppercase;
				}
			}
		}
	}
}

#equipment-lists {
	h3 {
		font-size: 18px;
		margin: 0;
		margin-bottom: 5px;
	}
	.modified {
		margin-bottom: 10px;
	}
	p {
		margin-bottom: 10px;
	}

	.dropdown-toggle {
		cursor: pointer;
	}

	.filter-stats {
		h6 {
			font-size: 12px;
			margin: 0;
		}
		span {
			font-weight: 500;
			font-size: 18px;
		}
	}

	.list-actions {
		font-size: 13px;

		a:not(:first-child) {
			margin-left: 20px;
		}
	}
}
