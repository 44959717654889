#locationModal {
	.results-list {
		max-height: 325px;
		overflow-y: auto;
		clear: both;

		&.multi {
			max-height: 305px;
			a {
				padding: 5px !important;
			}
		}

		& > a {
			border-bottom: 0px none;
			border-radius: 5px;
			margin-bottom: 5px;

			&:hover {
				background-color: #f5f5f5;
			}

			.media-body {
				h4 {
					margin-bottom: 0;
				}

				vertical-align: middle;
			}

			&.checked {
				background-color: lighten($brand-primary, 50%);
			}
		}

		.btn {
			margin-top: 10px;
		}
	}

	.modal-body {
		min-height: 360px;

		.aligner {
			height: 285px;

			.aligner-item {
				max-width: 85%;
			}
		}
	}
}
