.popover-custom-filter {
	z-index: 2;

	.popover-content {
		padding: 8px;

		.form-group {
			margin-bottom: 8px;
		}

		.filterList {
			@include pickerList();

			.search {
				padding: 0;

				i {
					font-size: 14px;
				}
			}
			.results {
				height: 150px;
				padding: 0;
				font-size: 14px;

				.no-results {
					height: 100%;
				}
			}
		}
	}

	.popover-title {
		display: none;
	}

	.daterangepicker {
		min-width: initial;
	}

	.btn-datepicker {
		.fa-calendar {
			margin-right: 5px;
		}
	}
}

.modal-backdrop + .popover-custom-filter {
	z-index: 5555;
}
