#timeline-filter {
	margin-top: -8px;

	.dropdown-menu {
		padding: 0;
		font-size: 90%;
		width: 410px;

		.filter-header {
			padding: 10px;

			label {
				margin: 0;
			}
		}

		.filters {
			padding: 10px;
		}

		hr {
			margin: 0;
		}

		.filter-footer {
			text-align: right;
			padding: 10px;
		}
	}

	.dropdown-toggle {
		color: $text-secondary;
		text-decoration: none;
		outline: 0px none;
		border-radius: 3px;
		font-size: 90%;

		&:hover {
			color: $text-dark;
			border: 1px solid #ccc;
			background-color: #fff;
		}
	}

	&.open {
		.dropdown-toggle {
			border: 1px solid #ccc;
			background-color: #fff;
		}
	}

	.filters {
		small {
			text-transform: uppercase;
			margin-bottom: 5px;
			display: inline-block;
		}
	}
}
