#importWizard {
	.steps {
		margin-top: 15px;
		margin-bottom: 30px;

		ul {
			margin: 0;
			padding: 0;
			background-color: $gray-50;
			border: 1px solid $gray-300;
			overflow: hidden;
			border-radius: 5px;

			li {
				margin: 0;
				padding: 0;

				.subtitle {
					font-size: 95%;
				}

				&:last-child {
					a {
						&:after,
						&:before {
							border: 0px none;
						}
					}

					&.valid {
						&:after {
							right: 20px;
						}
					}
				}

				&:first-child {
					a {
						padding-left: 20px;
					}
				}

				&.disabled {
					a {
						cursor: default;
					}
				}

				&.disabled,
				&:not(.active) {
					.step-content {
						opacity: 0.4;
					}
				}

				&.active {
					a {
						color: #333;
						background-color: #fff;

						&:after {
							border-left: 30px solid #fff;
						}
					}
				}

				&.valid {
					&:not(.active) {
						&:after {
							opacity: 0.4;
						}
					}

					&:after {
						font-family: FontAwesome;
						content: '\f00c';
						border-radius: 100%;
						background-color: #333;
						width: 20px;
						height: 20px;
						text-align: center;
						position: absolute;
						right: 0px;
						top: 50%;
						margin-top: -10px;
						color: #fff;
					}
				}

				a {
					display: block;
					padding: 15px 20px 15px 50px;
					color: #333;
					text-decoration: none;
					position: relative;

					&:after,
					&:before {
						content: ' ';
						display: block;
						width: 0;
						height: 0;
						border-top: 50px solid transparent; /* Go big on the size, and let overflow hide */
						border-bottom: 50px solid transparent;
						border-left: 30px solid $gray-50;
						position: absolute;
						top: 50%;
						margin-top: -50px;
						left: 100%;
						z-index: 2;
					}

					&:before {
						left: 100.5%;
						border-left: 30px solid $gray-300;
					}
				}
			}
		}
	}

	.step {
		.description {
			margin-bottom: 15px;
		}
	}

	.step1 {
		.close {
			position: absolute;
			top: 10px;
			right: 10px;
		}

		.card-upload {
			min-height: 450px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0;
			text-align: center;
			flex-direction: column;

			hr {
				width: 100px;
				border-top-width: 2px;
				border-color: $gray-300;
			}

			color: $text-secondary;

			a {
				color: $text-secondary;
			}

			.help {
				position: relative;
				font-size: 90%;

				a {
					color: $link-color;
					display: inline-block;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
		.card-progress {
			min-height: 450px;
			display: flex;
			align-items: center;
			justify-content: center;

			.progress {
				margin-bottom: 0;
			}
		}
	}

	.step2 {
		.checkbox {
			margin-top: 0;

			label {
				margin: 0;
			}
		}

		.map-col,
		.label-col {
			width: 20%;
		}
	}

	.step3 {
		.validating {
			min-height: 450px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
		}

		#accordion {
			.card {
				.card-header {
					padding: 0;
					background-color: #f5f5f5;

					a {
						color: #333;
						border-color: #ddd;
						display: block;
						padding: 10px 15px;

						&:hover,
						&:active,
						&:focus {
							text-decoration: none;
						}

						.tag {
							margin-left: 5px;
						}
					}
				}
				.card-block {
					.label-col {
						display: inline-block;
						width: 10px;
						height: 10px;

						border-radius: 2px;

						&.error {
							background-color: #d9534f;
						}
						&.warning {
							background-color: #f0ad4e;
						}
					}
				}
			}
		}
	}

	h4 {
		font-size: 14px;

		&.hr {
			position: relative;
			z-index: 1;
			overflow: hidden;

			&:after {
				margin-left: 5px;
				position: absolute;
				top: 51%;
				overflow: hidden;
				width: 100%;
				height: 1px;
				content: '\a0';
				background-color: rgba(0, 0, 0, 0.1);
			}
		}
	}

	.bootstrap-select {
		.dropdown-menu[role='combobox'] {
			max-height: unset !important;
		}

		.bs-searchbox + .dropdown-menu.inner {
			padding-bottom: 0.5rem;
		}

		& .dropdown-divider {
			margin: 0;
			margin-bottom: 0.5rem;
		}
	}
}
