@import 'plugins/bootstrap';
@import 'plugins/bootstrap-select';
@import 'plugins/font-awesome';
@import 'plugins/fullcalendar';
@import 'plugins/messg';
@import 'plugins/bootstrap-datetimepicker';
@import 'plugins/typeahead';
@import 'plugins/bootstrap-daterangepicker';
@import 'plugins/intl-tel-input';
@import 'plugins/slick';
@import 'plugins/lazyload';

// Limit z-index of intercom so it doesn't restrict users from
// clicking a button on mobile screens
.intercom-lightweight-app,
.intercom-launcher-frame {
	z-index: 500 !important;
}

.intercom-launcher {
	&.offset {
		bottom: 85px !important;

		.intercom-launcher-frame,
		.intercom-launcher-discovery-frame {
			bottom: 85px;
		}
	}
}
