@mixin pickerList {
	.search {
		.wrapper {
			position: relative;

			.selection-actions {
				display: none;
				position: absolute;
				right: 7px;
				top: 5px;

				a {
					font-size: 90%;

					&:first-child {
						margin-right: 2px;
					}
				}
			}

			input {
				padding-left: 25px;
			}

			.input-icon {
				color: $text-secondary;
				content: '\f002';
				font-family: FontAwesome;
				position: absolute;
				top: 11px;
				left: 7px;
				display: block;
				font-size: 15px;
			}
		}
	}

	.results {
		max-height: 175px;
		overflow-y: auto;
		margin: 8px 0;

		.dropdown-item {
			padding: 5px 15px;
			border-radius: 5px;
			cursor: pointer;
			position: relative;
			line-height: 1;

			input {
				vertical-align: baseline;
			}

			label {
				margin-bottom: 0;
				text-overflow: ellipsis;
				max-width: 100%;
				width: 100%;
				overflow: hidden;

				&.level2 {
					width: 90%;
				}
				&.level3 {
					width: 80%;
				}
				&.level4 {
					width: 70%;
				}
				&.level5 {
					width: 60%;
				}
				&.level6 {
					width: 50%;
				}
				&.level7 {
					width: 40%;
				}
				&.level8 {
					width: 30%;
				}
				&.level9 {
					width: 20%;
				}
			}

			img {
				width: 32px;
				height: 32px;
			}

			&.selected:after {
				content: '\f00c';
				font-family: FontAwesome;
				position: absolute;
				right: 15px;
				top: 5px;
			}

			.only {
				display: none;
				float: right;
				margin-left: 10px;
				color: $text-secondary;

				&:hover {
					color: $text-dark;
				}
			}

			&:hover {
				.only {
					display: block;
				}
			}
		}

		.no-results {
			min-height: 100px;
			display: flex;
			align-items: center;
			justify-content: center;

			pointer-events: none;
		}
	}

	.footer {
		border-top: 1px solid #ccc;
		padding: 10px 5px;
	}
}

.select-picker {
	position: relative;

	input[type='checkbox'] {
		min-width: 16px;
		min-height: 16px;
	}

	&.inline-picker {
		min-width: auto;
		display: inline-block;
		line-height: 24px;
		vertical-align: bottom;
		height: 28px;

		button {
			padding: 5px !important;
			margin-bottom: -3px;
			border-radius: 3px;

			&.dropdown-toggle {
				width: auto;
			}

			&.disabled {
				opacity: 1;
				cursor: default;

				&.dropdown-toggle {
					&:after {
						display: none;
					}
				}
			}

			.selection-text {
				width: auto;
			}

			&:hover:not(.disabled),
			&:focus:not(.disabled),
			&[aria-expanded='true'] {
				border: 1px solid #ccc;
			}
		}

		.dropdown-menu {
			min-width: 200px;
		}
	}

	&.has-buttons {
		.dropdown-menu {
			.results {
				height: 150px;

				.no-results {
					height: 100%;
				}
			}

			.footer {
				padding: 5px;
			}
		}
	}

	button {
		&.dropdown-toggle {
			width: 100%;
			z-index: 1;
		}

		.selection-text {
			display: inline-block;
			text-align: left;
		}

		&.btn-link {
			color: #373a3c;
			text-decoration: none;
			outline: 0px none;
			padding-top: 0;
			padding-bottom: 0;

			&:hover,
			&:focus {
				outline: 0px none;
				text-decoration: none;
			}
		}
	}

	.dropdown-menu,
	.list {
		width: 100%;
		padding-bottom: 0;
		overflow: hidden;
		min-width: 250px;

		@include pickerList();
	}
}

.availability-picker {
	.status-l {
		line-height: 12px;
	}

	.dropdown-menu {
		width: 300px;
		padding: 15px;

		.media {
			margin-bottom: 15px;
			line-height: 18px;
			padding-bottom: 10px;
			border-bottom: 1px solid #eee;

			&:last-child {
				margin-bottom: 0;
				border-bottom: 0px none;
				padding-bottom: 0;
			}

			h5 {
				font-size: 14px;
				margin-bottom: 2px;
			}
			.text-muted {
				font-size: 90%;
			}

			.toggle-switch-sm input[type='checkbox']:checked ~ .switch {
				background-color: $brand-success;
			}
		}
	}
}

.new-picker {
	.results {
		label {
			pointer-events: none;
		}
	}
}
