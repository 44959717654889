.status-messages {
	width: 350px;
	max-height: 140px;
	margin: 25px 0;
	clip-path: inset(0 10px -15px 10px);
	transition: opacity 0.25s ease-in-out;

	&.invisible {
		opacity: 0;
	}

	&.slick-dotted {
		margin-bottom: 15px !important;
	}

	@include media-breakpoint-down(sm) {
		width: 100vw;

		&.slick-dotted {
			margin-bottom: 30px !important;
		}
	}

	.slick-list {
		padding: 0;
	}

	.slick-dots {
		bottom: -30px;

		button:before {
			content: ' ';
			height: 5px;
			width: 20px;
			background-color: #333;
			border-radius: 10px;
			opacity: 0.2;
		}

		& > li {
			display: inline-block;
		}
		& > li:only-child {
			display: none;
		}
	}

	a.status-message {
		color: $text-dark;

		&:hover,
		&:focus,
		&:visited {
			text-decoration: none;
		}

		&.no-events {
			&:after {
				display: none;
			}
		}

		&:after {
			font-family: FontAwesome;
			content: '\f105';
			position: absolute;
			right: 10px;
			top: 50%;
			margin-top: -20px;
			font-size: 25px;
			color: #e8e8e8;
		}
	}

	.status-message {
		outline: 0px none;
		background: #fff;
		margin: 5px;
		position: relative;
		border: 1px solid $gray-200;
		width: 300px;
		height: 90px;
		padding: 5px 25px 5px 15px;
		font-size: 15px;
		border-radius: 5px;
		transition: opacity 0.15s linear;
		display: block;

		.title {
			font-weight: $font-weight-bold;
			line-height: 1;
			margin-top: 5px;
		}

		.media {
			display: flex;
			align-items: center;
			height: 100%;

			.media-left {
				.status-badge {
					.text {
						display: none;
					}

					&:before {
						font-size: 40px;
					}
				}

				i {
					margin: 0 5px;

					height: 35px;
					width: 35px;
					font-size: 17px;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #fff;
					border-radius: 50%;
					overflow: hidden;
				}
			}
		}

		.avatar {
			border-radius: 50%;
			width: 25px;
			height: 25px;
			display: inline-block;
			box-shadow: 0px 0px 1px rgba(0, 0, 0, 1);

			&.large {
				width: 40px;
				height: 40px;
			}
		}
	}
}
