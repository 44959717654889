#reserveAgainModal {
	max-width: 650px;

	h3 {
		margin: 10px 0 5px;
		font-size: 20px;
		line-height: 20px;
		display: inline-block;
		color: #555;
		position: relative;

		&:after {
			content: ' ';
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: $brand-danger;
		}
	}

	.fa-building {
		width: 64px;
		height: 48px;
		text-align: center;
	}

	.list-group {
		margin: 0 0 10px;
	}

	@include media-breakpoint-down(sm) {
		.col-sm-3.text-right {
			text-align: left;
		}
	}
}
