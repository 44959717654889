#users_invite {
	height: 100%;

	.lined {
			overflow: auto;
			position: relative;
			height: 200px;
			border: 1px solid #ccc;
			border-radius: 5px;
	
			&.invalid {
				border-color: $brand-danger;
			}
	
			#syntax {
				height: 125px;
				pointer-events: none;
				position: absolute;
				width: 100%;
	
				.syntax-error {
					background: rgba(255, 0, 0, 0.2);
					text-align: right;
					padding-right: 20px;
				}
			}
	
			textarea {
				padding: 0px 10px 0;
				resize: none;
				height: 2000px;
				line-height: 1.5;
				font-size: 14px;
				border: 0px none;
	
				&:focus {
					outline: none;
					border-color: inherit;
				}
	
				&[disabled] {
					background-color: #eee;
				}
	
				&.placeholder {
					color: $text-secondary;
				}
			}
		}

	.roles-list {
		.row {
			border-bottom: 1px solid #f5f5f5;
			padding: 10px 0;

			&:last-child {
				border-bottom: 0px none;
			}
		}
	}
}
