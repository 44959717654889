#deleteRoleModal {
	.select-picker {
		.btn {
			.selection-text {
				width: 95%;
			}
		}
	}

	.has-danger {
		.select-picker {
			.btn {
				color: $brand-danger;
				border-color: $brand-danger;
			}
		}
	}
}
