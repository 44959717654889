#preview-modal {
	.modal-body {
		background-color: #f1f3f4;
		height: 500px;
		max-height: 500px;
		overflow: scroll;

		.flex-container {
			height: 100%;
			display: flex;

			& > div {
				margin: auto;
			}
		}

		#invalidTemplate {
			p:not(.lead) {
				margin: 0 0 20px;
			}
		}
	}
}
