#calendar-page {
	padding: 0;
	height: calc(100% + 36px);
	margin-bottom: -100px;

	.calendar-view {
		float: left;
		width: 100%;
		height: 100%;
		background-color: #fff;

		.calendar-wrapper {
			margin: 0;
			border: 0px none;
			height: 100%;
			border-right: 1px solid #ddd;
			border-radius: 0;
		}

		@include media-breakpoint-down(md) {
			width: 100%;
			float: none;
			height: auto;
		}
	}

	.calendar-list + .calendar-view {
		width: 70%;

		@include media-breakpoint-down(md) {
			width: 100%;
		}
	}

	.calendar-list {
		float: right;
		width: 30%;
		height: 100%;

		@include media-breakpoint-down(md) {
			width: 100%;
			float: none;
			height: auto;
		}

		.loading,
		.no-results {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
		}

		.no-results {
			padding: 50px;
			text-align: center;
		}

		.event-list {
			.list-group-item:first-child {
				border-top: 0px none;
			}

			.event-title {
				cursor: pointer;
				padding-left: 20px;
				position: relative;
				border-bottom: 1px solid #ddd;

				.tag {
					padding: 3px 5px;
					font-size: 10px;
					background-color: $text-secondary;
					color: #fff;
					font-weight: 100;
				}
			}

			.event-title[aria-expanded='false'] {
				&:before {
					content: '\f0da';
					font-family: FontAwesome;
					position: absolute;
					left: 7px;
					top: 6px;
				}
			}

			.event-title[aria-expanded='true'] {
				&:before {
					content: '\f0d7';
					font-family: FontAwesome;
					position: absolute;
					left: 5px;
					top: 6px;
				}
			}
		}
	}
}

.calendar-wrapper {
	.calendar-title {
		font-weight: $font-weight-bold;
		font-size: 20px;
		margin-bottom: 0;
	}

	.calendar-content {
		.calendar {
			margin-top: 15px;
			margin-bottom: 5px;

			.fc-day-grid {
				.fc-content-skeleton {
					max-height: 210px;
					overflow-y: auto;
					overflow-x: hidden;
				}
			}

			.fc-time-grid-event {
				.text-truncate {
					white-space: normal;
				}
			}

			//.fc-sat { background-color:#fafafa;  }
			//.fc-sun { background-color:#fafafa; }
			//

			.badge {
				background-color: rgba(255, 255, 255, 0.5);
				font-size: 80%;
				padding: 0px 5px;
				margin-left: 5px;
				/* margin-top: -5px; */
				/* display: inline-block; */
				position: relative;
				top: -1px;
			}
		}

		.loading {
			position: absolute;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			top: 0;
			left: 0;
			z-index: 5556;

			.loader-sm {
				z-index: 5557;
			}

			&:before {
				content: ' ';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(255, 255, 255, 0.5);
				z-index: 5555;
			}
		}
	}

	.calendar-footer {
		a {
			color: $text-secondary;

			&:hover {
				text-decoration: none;
				color: darken($text-secondary, 10%);
			}
		}
	}

	//Popover wrapper
	.calendar-hidden {
		display: none;
	}

	.daterangepicker {
		min-width: auto;
		width: auto;
	}
}

.popover-calendar-actions {
	min-width: 200px;
	
	&:has(&--disabled) {
		min-width: 300px;
	}

	&--disabled {
		display: flex;
		gap: 1rem;

		> p {
			margin: 0;

			&:first-child {
				font-size: 1.1rem;
			}
		}
	}
}
