#searchPanel {
	background-color: #fff;
	border: 1px solid $gray-300;
	padding: 15px;
	margin-top: 5px;
	position: fixed;
	border-radius: $border-radius;
	width: $navbar-search-width;
	overflow-y: auto;
	max-height: calc(100% - 60px);

	h3 {
		position: relative;
		margin: 5px 0 5px;
		font-size: 14px;
		color: #c3c3c3;
		font-weight: normal;
		box-shadow: 0px none;
		text-shadow: 0px none;

		&:before {
			border-top: 1px solid #e8e8e8;
			content: '';
			margin: 0 auto;
			position: absolute;
			top: 45%;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
		}

		span {
			background: #fff;
			padding-left: 5px;
			padding-right: 15px;
			position: relative;
		}

		a {
			background: #fff;
			position: relative;
			float: right;
			padding: 0 5px;
		}
	}

	.no-results {
	}

	.found-results {
		a {
			display: block;
			overflow: hidden;
			color: #333;
			padding: 10px 25px 10px 5px;
			position: relative;

			&:after {
				font-family: FontAwesome;
				content: '\f105';
				position: absolute;
				right: 10px;
				top: 10px;
				font-size: 16px;
				color: #e8e8e8;
			}

			&:hover {
				text-decoration: none;
				background-color: #f8f8f8;
				border-radius: 5px;
			}

			&.blocked {
				@include archived(#eee);

				&:hover {
					@include archived(#e5e5e5);
				}
			}

			.result-title {
				font-weight: $font-weight-bold;
				line-height: 15px;

				small {
					color: $text-secondary;
					font-weight: normal;
				}
			}
			.text-muted {
				font-size: 90%;
			}
			.label-incomplete {
				display: inline;
			}

			img {
				float: left;
				margin-right: 10px;
				border: 0px none;
				width: 48px;
				height: 48px;
				border-radius: 5px;
			}
			.icon {
				float: left;
				margin-right: 10px;
				padding-top: 4px;
				width: 48px;
				height: 48px;
				font-size: 16px;
				text-align: center;
			}

			.csv {
				display: inline-block;
				font-size: 80%;
				margin: 0;
				padding: 0;

				li {
					display: inline-block;
					margin-left: 5px;
					padding-top: 5px;

					&:after {
						content: ' ';
					}
				}
			}
		}

		&.found-items,
		&.found-kits {
			a {
				img {
					border: 1px solid #eee;
				}
			}
		}
	}
}
