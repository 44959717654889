.card {
	&.contact-card,
	&.item-card,
	&.kit-card,
	&.transaction-card {
		a {
			color: $text-dark;

			&:hover,
			&:focus,
			&:active {
				text-decoration: none;
			}
		}
	}

	&.contact-card {
		a {
			img {
				width: 64px;
				height: 64px;
			}
		}

		&.blocked {
			@include archived(#e5e5e5);
		}
	}

	.empty {
		padding: 20px;

		i {
			font-size: 100px;
		}
		p {
			margin: 0;
			font-size: 15px;
		}
	}

	.table {
    // Target the last table row
    tr:last-of-type {
      &:hover {
        background-color: unset;
        td {
          background-color: $gray-lightest;
        }
      }

      td {
        // The first cell 'hugs' the left table wall
        &:first-of-type {
          border-radius: 0 0 0 $card-table-cell-border-radius;
        }
        // The last cell 'hugs' the right table wall
        &:last-of-type {
          border-radius: 0 0 $card-table-cell-border-radius 0;
        }
      }
    }
  }
}

.card-dropdown {
	position: absolute;
	top: 5px;
	right: 5px;

	i {
		color: $text-secondary;
	}
}