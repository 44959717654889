#kitModal {
	.results-list {
		max-height: 345px;
		overflow-y: auto;
	}
}

#kitConfirmModal {
	.kit-card {
		min-height: auto;
	}
}
