#installMobileApp {
	@include media-breakpoint-up(sm) {
		max-width: 400px;
	}

	.modal-body {
		padding: 30px 40px;

		.lead {
			margin-bottom: 5px;
		}
	}
}
