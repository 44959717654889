//TODO implemented in BS4????
@each $color, $value in $labelColors {
	.text-#{$color} {
		color: $value !important;
	}

	.bg-#{$color} {
		background-color: $value !important;
	}
}

.bg-light {
	background-color: $gray-lightest !important;
}

.aligner {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	flex-direction: column;
}

.aligner-item {
	max-width: 50%;
}

.aligner-item-top {
	align-self: flex-start;
}

.aligner-item-bottom {
	align-self: flex-end;
}

.multiline-text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	line-height: 16px; /* fallback */
	max-height: 32px; /* fallback */
	-webkit-line-clamp: 2; /* number of lines to show */
	-webkit-box-orient: vertical;
	white-space: normal;
}

//https://stackoverflow.com/questions/19757951/div-100-height-scroll
.full-height {
	min-height: 100%;
	height: auto;
}
