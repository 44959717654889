#printLabelModal {
	.modal-body {
		padding: 0;

		#labelsDymo,
		#labelsZebra {
			padding: 15px;
			text-align: center;
			img {
				display: block;
				margin: auto auto 10px;
			}
		}

		#labelsZebra {
			img {
				max-width: 400px;
				max-height: 400px;

				box-shadow: 1px 1px 1px #000;
			}
		}
	}
}
