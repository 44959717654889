#viewCodeModal {
	width: 330px;
	text-align: center;

	img {
		display: block;
		margin: auto;
		width: 300px;
		padding: 10px;

		&.fallback {
			opacity: 0.1;
		}
	}

	.install-app {
		line-height: 30px;

		a {
			color: $text-secondary;
		}
	}

	.modal-header {
		padding: 10px 15px 0;
		border: 0px none;
	}
}

#addCodeModal {
	.no-code {
		line-height: 30px;
	}

	.item-image,
	.more-image {
		border: 1px solid #ccc;
		border-radius: 4px;
		width: 32px;
		height: 32px;
	}

	.contact-image,
	.contact-more-image {
		border: 1px solid #ccc;
		border-radius: 50%;
		width: 32px;
		height: 32px;
	}

	.item-image {
		padding: 5px;
	}

	.validationMessage {
		float: left;
	}
}
