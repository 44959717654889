@import '../../lib/bootstrap-daterangepicker/daterangepicker.scss';

.daterangepicker.single {
	min-width: 371px;
	line-height: 1.5;

	.calendar.single {
		clear: none;
	}

	@media only screen and (max-width: 564px) {
		min-width: auto;
	}
}
