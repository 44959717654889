#warningModal {
	max-width: 900px;

	.downgrade-item {
		display: flex;
		align-items: baseline;
		gap: 5px;

		h4 {
			margin-bottom: 2px;
		}
	}
}
#subscriptionsModal {
	&:not(&.modal-full) {
		max-width: 900px !important;
	}

	.alert {
		p {
			margin: 0;

			&.lead {
				margin-bottom: 5px;
			}
		}
	}

	h6 {
		text-transform: uppercase;
	}

	.last {
		.card {
			margin: 0;
		}
	}

	.slidecontainer {
		width: 100%;

		.rangeslider {
			width: 100%;
			height: 10px;
			background: #fff;
			border: 1px solid $brand-primary;
			position: relative;
			display: block;
			border-radius: 20px;
			top: 5px;

			.rangeslider__fill {
				position: absolute;
				background: $brand-primary;
				display: block;
				border-radius: 10px;
				height: 100%;
			}

			.rangeslider__handle {
				background: $brand-primary;
				color: white;
				cursor: pointer;
				display: inline-block;
				position: absolute;
				top: -10px;
				touch-action: pan-y;
				-ms-touch-action: pan-y;
				border-radius: 22px;
				min-width: 65px;
				line-height: 25px;
				height: 25px;
				text-align: center;
				font-weight: $font-weight-bold;
			}
		}
	}

	.item-slider {
		display: flex;
		padding-top: 10px;

		label {
			font-size: 17px;
			margin-right: 20px;
		}

		.slidecontainer {
			padding-top: 5px;
			max-width: 300px;
		}
	}

	.plans-wrapper {
		display: flex;

		@include media-breakpoint-down(md) {
			flex-direction: column;

			.old {
				margin-right: 0 !important;
			}
		}

		.old {
			margin-right: 15px;
		}

		.new {
			flex-grow: 1;

			@include media-breakpoint-up(md) {
				.collapsed {
					.plan-wrapper {
						margin: 0;

						&:first-child {
							.card {
								border-top-left-radius: 5px;
								border-bottom-left-radius: 5px;
							}
						}

						&:last-child {
							.card {
								border-top-right-radius: 5px;
								border-bottom-right-radius: 5px;
							}
						}

						.card {
							border-radius: 0;
						}

						&:not(:last-child) {
							.card {
								border-right: 0px;
							}
						}
					}
				}
			}
		}
	}

	.plans {
		display: flex;

		.plan-wrapper {
			flex: 1;

			.card {
				cursor: pointer;
			}

			&:not(:first-child) {
				margin-left: 15px;
			}
		}

		@include media-breakpoint-down(md) {
			flex-direction: column;

			.plan-wrapper {
				margin-left: 0px !important;
			}

			.plan-features {
				display: none;
			}
		}
	}

	.period-toggle-switch {
		font-size: 17px;
		float: right;

		@include media-breakpoint-down(md) {
			display: block;
			float: none;
		}

		label {
			color: #d0d0d0;
			cursor: pointer;

			&.active {
				color: #333;
			}
		}

		.save {
			color: $brand-primary;
			font-size: 90%;

			@include media-breakpoint-down(md) {
				margin-right: 0px;
			}
		}
	}

	.card {
		height: 220px;
	}

	.plans {
		.card {
			h3 + p {
				margin-bottom: 50px;
			}
			h3 + button {
				margin-top: 15px;
			}

			&.current {
				position: relative;

				&:before {
					font-size: 10px;
					content: 'current';
					text-transform: uppercase;
					padding: 2px 5px;
					color: $brand-primary;
					border-bottom-left-radius: 5px;
					position: absolute;
					top: 0;
					right: 0;
					background-color: #bde0ff;
				}
			}
		}

		.plan-features {
			height: auto;
			width: 90%;
			margin: 20px auto;

			.tag-sm {
				font-size: 65%;
				vertical-align: text-top;
			}
		}
	}

	.items-conflict.card {
		height: auto;
	}

	.confirm-subscription {
		.card {
			margin: 0;
		}

		.plan-features {
			height: 290px;
			width: 90%;
			margin: auto;

			.disabled {
				display: none;
			}
		}

		.col-md-7 {
			display: flex;
			align-items: center;
			height: 280px;
		}
	}
}

@mixin toggle-period($size: 55px, $extraClass: '') {
	$width: $size;
	$height: $size / 2.5;
	cursor: pointer;

	label {
		cursor: pointer;
	}

	input[type='checkbox'] {
		display: none;

		&:checked ~ .toggle {
			left: ($width - ($height * 0.8 - $height) / 2) - $height - 2;
			transition: 0.5s;
		}

		&:checked ~ .switch {
			transition: 0.5s;
		}
	}
	.switch {
		display: block;
		width: $width;
		height: $height;
		background: #fff;
		border: 1px solid $brand-primary;
		border-radius: $height / 2;
		position: absolute;
		top: 0;
		left: 0;
		transition: 0.5s;
	}

	.toggle {
		height: $height * 0.8;
		width: $height * 0.8;
		border-radius: 50%;
		background: $brand-primary;
		position: absolute;
		top: ($height * 0.8 - $height) / -2;
		left: (($height * 0.8 - $height) / -2)+2;
		transition: 0.5s;
	}

	display: inline-block;
	margin: 0 5px 0 5px;
	width: $width;
	height: $height;
	text-align: center;
	position: relative;
	top: 2px;

	&.disabled {
		pointer-events: none;
		opacity: 0.5;
	}
}

.toggle-period {
	@include toggle-period();
}

.items-conflict.card {
	height: auto;

	.alert-danger {
		margin-bottom: 0;
		border-color: transparent;
		border-radius: 0;
		border-top-color: #ebcccc;
		text-align: left;
		line-height: 1.15;

		.media-left {
			vertical-align: middle;
		}
	}

	.items-diff {
		padding: 20px;
		display: flex;
		justify-content: center;
		align-items: center;

		.fa {
			margin: 0 25px;
			font-size: 35px;
		}

		.stat {
			font-size: 35px;
			line-height: 1;
			display: inline-block;

			&.old {
				.count {
					text-decoration: line-through;
				}
			}

			span:not(.count) {
				text-decoration: normal;
				display: block;
				font-size: 15px;
				color: $text-secondary;
			}
		}
	}
}

.downgrade-list,
.unable-list {
	h4 {
		font-size: 15px;
		margin-bottom: 0px;
	}

	.fa-minus-circle {
		color: $brand-danger;
	}
}

.warning-visual {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 278px;
}

.items-conflict + .downgrade-list {
	max-height: 178px;
}

.downgrade-list {
	max-height: 278px;
	overflow-y: auto;

	p {
		margin: 0;
	}
}

.unable-list {
	margin-bottom: 15px;

	h4 {
		margin-bottom: 5px;
	}
	p {
		margin-bottom: 5px;
	}

	.list-group-item-warning {
		.help-link {
			color: #8a6d3b;
		}
	}
}
