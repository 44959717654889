//
// Override bootstrap variables
$font-size-root: 16px;
$font-size-base: 0.875rem;

$grid-gutter-width: $padding;

$navbar-padding-x: 0;
$navbar-padding-y: 0;

$navbar-light-color: $gray-700;
$navbar-light-active-color: $gray-700;
$navbar-light-hover-color: $gray-700;

$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;
$breadcrumb-bg: transparent;

$tag-padding-y: 5px;
$tag-padding-x: 10px;

$card-spacer-y: $padding;
$card-spacer-x: $padding;
$card-cap-bg: #fff;

$zindex-modal: 555556;
$zindex-modal-bg: 555555;

$input-padding-x-lg: 0.75rem;

//
// Import bootstrap
@import '../../lib/bootstrap/scss/bootstrap.scss';

//
// Override bootstrap
.navbar-brand {
	margin: 0;
}

.breadcrumb {
	margin: 0;
}

//
// Extend bootstrap
.navbar {
	.navbar-right {
		float: right;
	}
	.navbar-left {
		float: left;
	}
}

.card {
	color: $text-dark;

	&:link,
	&:hover,
	&:active,
	&:visited {
		text-decoration: none;
	}

	&:last-child {
		//margin-bottom:0;
	}

	@include dotted;
	@include dashed;

	table {
		margin: 0;
	}
}

.form-group {
	& > label {
		font-weight: $font-weight-bold;
	}
}

@include media-breakpoint-up(md) {
	.pull-right-md {
		float: right;
	}
}

.d-middle {
	display: flex;
	align-items: center;
}

/*
Spinner buttons
<button-extra>
http://jsfiddle.net/AndrewDryga/zcX4h/1/
*/
.spinner {
	display: inline-block;
	opacity: 0;
	width: 0;
	-webkit-transition: opacity 0.25s, width 0.25s;
	-moz-transition: opacity 0.25s, width 0.25s;
	-o-transition: opacity 0.25s, width 0.25s;
	transition: opacity 0.25s, width 0.25s;
}
.ico {
	display: none;
}
.has_icon .ico {
	display: inline-block;
}
.has_spinner.active {
	cursor: progress;
}
.has_spinner.active .ico {
	display: none;
}
.has_spinner.active .spinner {
	opacity: 1;
	width: auto;
	/* This doesn't work, just fix for unknown width elements */
}
.has_spinner.btn-mini.active .spinner,
.has_spinner.btn-mini .ico {
	width: 10px;
}
.has_spinner.btn-small.active .spinner,
.has_spinner.btn-small .ico {
	width: 13px;
}
.has_spinner.btn.active .spinner,
.has_spinner.btn .ico {
	width: 16px;
}
.has_spinner.btn-large.active .spinner,
.has_spinner.btn-large .ico {
	width: 19px;
}
.has_spinner.btn-block.active .spinner,
.has_spinner.btn-block .ico {
	width: 22px;
}

@include media-breakpoint-up(sm) {
	.form-inline {
		.input-group {
			.input-group-addon {
				width: 1px;
			}
		}
	}
}

.has-danger {
	.input-group {
		input:focus + .input-group-addon {
			border-color: $brand-danger;
		}
	}
}

.input-group {
	.input-group-addon {
		background-color: $input-bg;
		padding-left: 3px;
		padding-right: 10px;
		width: 1px;

		// BUGFIX border-collapse:seperate spacing issue
		position: relative;
		left: -2px;
	}

	input:focus + .input-group-addon {
		background-color: $input-bg-focus;
		border-color: $input-border-focus;
		outline: none;
		$shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $input-box-shadow-focus;
		@include box-shadow($shadow);
	}

	input:not(:last-child) {
		border-right: 0px none;
	}

	input:last-child {
		border-left: 0px none;
	}
}

// Text color placholders
.has-danger .form-control {
	color: $brand-danger;
}

.has-danger .form-control::-webkit-input-placeholder {
	color: $brand-danger;
}
.has-danger .form-control:-moz-placeholder {
	color: $brand-danger;
}
.has-danger .form-control::-moz-placeholder {
	color: $brand-danger;
}
.has-danger .form-control:-ms-input-placeholder {
	color: $brand-danger;
}
.has-danger .form-control::-webkit-input-placeholder,
.has-danger .form-control::-webkit-input-placeholder {
	color: $brand-danger;
}
.has-danger .form-control:-moz-placeholder,
.has-danger .form-control:-moz-placeholder {
	color: $brand-danger;
}

/* custom buttons */
.btn-outline-conflict {
	@include button-outline-variant($state-warning-text);
}

.no-events {
	pointer-events: none;
}

.tabs {
	.nav-link {
		.tag {
			vertical-align: top;
			padding-left: 5px;
			padding-right: 5px;
		}
	}
}

.selectpicker {
	position: relative;
	overflow: hidden;

	&.dropdown-toggle:after {
		position: absolute;
		top: 50%;
		right: 12px;
		margin-top: -2px;
		vertical-align: middle;
	}
}

.dropdown-menu:not(.bootstrap-select) {
	.selected {
		position: relative;

		.dropdown-item {
			display: inline-block;
			padding-right: 40px;

			&:after {
				content: '\f00c';
				font-family: FontAwesome;
				position: absolute;
				right: 15px;
				top: 5px;
			}
		}
	}
}

//http://stackoverflow.com/questions/28660494/media-list-and-text-overflow-in-bootstrap-3-3-2
.media-body {
	position: relative;

	.media-body-truncate {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}
}

.comment {
	.media-body {
		padding: 4px;
	}

	.media-left {
		padding: 4px 0;
	}
}

.align-items-center {
	-webkit-box-align: center !important;
	-webkit-align-items: center !important;
	-ms-flex-align: center !important;
	align-items: center !important;
}

.justify-content-center {
	-webkit-box-pack: center !important;
	-webkit-justify-content: center !important;
	-ms-flex-pack: center !important;
	justify-content: center !important;
}

@include media-breakpoint-up(lg) {
	.flex-lg-row {
		display: flex;
	}
}

@include media-breakpoint-up(md) {
	.flex-md-row {
		display: flex;
	}
}

.table {
	tr {
		&.disabled {
			td > * {
				opacity: 0.5;

				&.input-group-btn:hover,
				&.input-group-btn.open {
					opacity: 1;
				}
			}
		}
	}
}

.no-gutters {
	[class*='col-'] {
		padding-left: 0;
		padding-right: 0;
	}
}

.hidden {
	display: none;
}

.hidden-forced {
	display: none !important;
}

.popover,
.tooltip {
	z-index: 555556;
}

select.empty {
	color: $text-secondary;
}

.form-control {
	/* - Internet Explorer 10–11
     - Internet Explorer Mobile 10-11 */
	&:-ms-input-placeholder {
		color: $input-color-placeholder !important;
		font-weight: 400 !important;
	}
}

// Floating labels
:root {
	--input-padding-x: 1.2rem;
	--input-padding-y: 1.2rem;
}

.form-label-group {
	position: relative;
	margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label {
	padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group > label {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	margin-bottom: 0; /* Override default `<label>` margin */
	line-height: 1.5;
	color: #495057;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	transition: all 0.1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
	color: transparent;
}

.form-label-group input:-ms-input-placeholder {
	color: transparent;
}

.form-label-group input::-ms-input-placeholder {
	color: transparent;
}

.form-label-group input::-moz-placeholder {
	color: transparent;
}

.form-label-group input::placeholder {
	color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
	padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
	padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown) ~ label {
	padding-top: calc(var(--input-padding-y) / 3);
	padding-bottom: calc(var(--input-padding-y) / 3);
	font-size: 12px;
	color: #777;
}

.nav-fill {
	display: flex;

	.nav-item {
		-webkit-box-flex: 1;
		-webkit-flex: 1 1 auto;
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
		text-align: center;

		.nav-link {
			display: block;
		}
	}
}

.list-group-flush {
	.list-group-item {
		border-left: 0;
		border-right: 0;

		&:first-child {
			border-top: 0;
		}
		&:last-child {
			border-bottom: 0;
		}
	}
}

.modal-dialog {
	&.loading {
		height: 100%;
		margin: 0;
		width: 100%;
		max-width: none !important;
		pointer-events: none;

		.loader-sm {
			position: absolute;
			top: 40%;
			left: 50%;
		}
	}
}

.modal-full {
	max-width: unset !important;
	margin: 0;
	min-height: 100%;

	.modal-content {
		border: 0px none;
		border-radius: 0;
		min-height: 100%;
	}

	.modal-footer {
		position: sticky;
		bottom: 0;
		background-color: #fff;
	}
}

.relative {
	position: relative;
}

.m-t-0\.5 {
	margin-top: 0.5rem;
}

.fill-space {
	height: 100%;

	& > aside {
		height: 100%;
	}
}
