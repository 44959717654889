.table-list {
	td,
	th {
		position: relative;
		white-space: nowrap;

		input[type='checkbox']:before {
			content: ' ';
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			margin-bottom: 0;

			text-align: center;
			padding-top: 0.65em;

			min-height: 63px; //IE bugfix
		}
	}

	tbody {
		tr {
			cursor: pointer;

			.action {
				display: none;
			}

			&:hover {
				background-color: $table-bg-hover;

				.action {
					display: block;
				}

				.flex-parent {
					.media-body {
						.text-truncate {
							max-width: 280px;
						}
					}
				}

				&.disabled {
					.flex-parent {
						opacity: 1;

						.media-body {
							opacity: 0.5;
						}
					}
				}
			}

			.default {
				border-radius: 10px;
				border: 1px solid $text-muted;
				color: $text-muted;
				padding: 3px 5px;
				font-size: 12px;
				line-height: 12px;
				display: inline-block;
				margin-left: 10px;
			}

			td {
				vertical-align: middle;

				small {
					color: $text-secondary;
				}

				a {
					color: $text-dark;
					&:hover,
					&:active,
					&:focus {
						text-decoration: none;
					}
				}
			}
		}
	}
}

//https://css-tricks.com/flexbox-truncated-text/
/* Text is directly within flex child,
   so doing the wrapping here */
.flex-parent {
	display: flex;
	align-items: center;
	max-width: 450px;
	min-width: 450px;
	min-height: 38px;
	padding-right: 20px;

	&.flex-parent-sm {
		min-width: 250px;
		width: 250px;
	}

	&.flex-parent-md {
		min-width: 350px;
		width: 350px;
	}

	.media-body {
		overflow: hidden;

		.text-truncate {
			max-width: 370px;
		}
	}

	.text-truncated {
		flex: 1;
		min-width: 0; /* or some value */
		overflow: hidden;
	}
}
