#itemSelectionModal {
	.in-kit {
		position: relative;

		@include kit-badge(30px, #333, 3px, 4px, #fff, 8px);
	}

	.results-list {
		max-height: 300px;
		overflow-y: auto;

		label {
			cursor: pointer;
		}
	}
}
