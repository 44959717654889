#flagModal {
	.dropdown-toggle {
		.selection-text {
			display: block;
		}

		&:after {
			position: absolute;
			right: 15px;
			top: 15px;
		}
	}

	.select-picker {
		.selection-text {
			color: $text-secondary;
		}

		.has-selection {
			.selection-text {
				color: $text-dark;
			}
		}
	}

	.comment-box {
		box-shadow: none;
		border: 1px solid $gray-200;
		margin: 0px;

		.comment-action {
			color: $gray-700;
		}
	}

	.stacked {
		position: relative;
		margin-right: 10px;

		.card {
			z-index: 2;
		}

		.next,
		.prev {
			position: absolute;
			right: 0;
			top: 50%;
			z-index: 5;
		}

		.prev {
			left: 0;
		}

		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			border: 1px solid #ccc;
			will-change: transform;
			border-radius: 2px;
			background-color: #fff;
			transform-origin: right center;
		}

		&:before {
			transform: translate(5px, 0) scale(0.95);
			z-index: 1;
		}

		&:after {
			transform: translate(10px, 0) scale(0.9);
			z-index: 0;
		}
	}

	.validationElement {
		color: $brand-danger;
	}

	.has-error {
		.select-picker {
			.btn-secondary {
				background-color: $state-danger-bg;
				border-color: $state-danger-text;

				.selection-text {
					color: $brand-danger;
				}
			}
		}

		.comment-box {
			border-color: $state-danger-text;

			textarea {
				background-color: $state-danger-bg !important;
				color: $state-danger-text;
			}
		}

		.form-control::-webkit-input-placeholder {
			color: $brand-danger;
		}

		.flag-attachments {
			border-color: $state-danger-text !important;

			.add-file {
				background-color: $state-danger-bg !important;
				color: $state-danger-text;
			}
		}
	}

	.flag-attachments {
		min-height: 84px;
		background-color: #fafafa;
		border: 1px solid #ccc;
		border-radius: 5px;
		position: relative;
		padding: 10px 10px 0 10px;

		&.none {
			border: 2px dotted $gray-300;
			background-color: #fff;
			padding: 10px;
		}

		.add-file {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			color: $gray-700;

			&:hover,
			&:visited,
			&:focus {
				background-color: $gray-50;
				text-decoration: none;
			}
		}
	}

	.card {
		margin-bottom: 0;
	}

	.flag-attachment {
		display: inline-block;
		position: relative;
		margin-right: 5px;
		margin-bottom: 10px;

		.progress {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.5);
			transition: width 0.2s ease-in-out;
			border-radius: 5px;
			z-index: 4;
		}

		img {
			border-radius: 5px;
			border: 1px solid #ccc;
			width: 64px;
			height: 64px;
			object-fit: contain;
			background: white;
		}

		a {
			display: none;
			position: absolute;
			top: -8px;
			right: -8px;
			width: 22px;
			height: 22px;
			background-color: #555;
			color: white;
			border-radius: 50%;
			border: 2px solid white;
			text-align: center;
			font-size: 20px;
			line-height: 15px;
			z-index: 55;
			text-decoration: none;

			&:hover {
				background-color: #000;
				text-decoration: none;
			}
		}

		&:hover {
			cursor: pointer;

			a {
				display: block;
			}
		}
	}
}
