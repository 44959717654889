.timeline-header {
	h6 {
		display: inline-block;
	}
	.loader-xs {
		margin: 0;
		margin-left: 5px;
		display: inline-block;
		vertical-align: text-bottom;
	}
}

.timeline {
	position: relative;
	padding: 30px 0;
	height: 100%;

	&:before {
		/* this is the vertical line */
		content: '';
		position: absolute;
		top: 0;
		left: 60px;
		height: 100%;
		width: 2px;
		background: linear-gradient(to bottom, $gray-100 0%, $gray-300 20%, $gray-300 80%, $gray-100 100%);
	}

	.timeline-date {
		width: 120px;
		text-align: center;

		span {
			display: inline-block;
			margin: auto;
			background-color: $bg-color;
			color: $text-secondary;
			padding: 5px 10px;
			border-radius: 5px;
			z-index: 10;
			position: relative;
			border: 2px $gray-300 solid;
			border-radius: 20px;
			white-space: nowrap;
		}
	}

	.timeline-time {
		font-size: 80%;
		color: $text-secondary;
		position: absolute;
		top: 4px;
		left: 0;

		div {
			position: absolute;
			right: -50px;
		}
	}

	.timeline-block {
		position: relative;
		margin: 2em 0;

		&:first-child {
			margin-top: 0;
		}

		.timeline-icon {
			position: absolute;
			top: 8px;
			left: 56px;
			width: 10px;
			height: 10px;
			background-color: $bg-color;
			text-align: center;
			vertical-align: middle;
			color: $text-secondary;
			border: 2px solid $gray-300;
			border-radius: 100%;

			i {
				display: none;
				font-size: 20px;
				padding-top: 4px;
			}
		}

		.timeline-user {
			position: absolute;
			left: 75px;
			top: 0;

			.avatar {
				border-radius: 100%;
				width: 32px;
				height: 32px;
			}
		}

		.timeline-content {
			margin-left: 115px;
			color: $text-secondary;

			.gutruncate-more-link {
				display: block;
			}

			a:not(.gutruncate-more-link):not(.open-email):not(.wdt-emoji-tab):not(.wdt-emoji),
			b {
				color: $gray-700;
			}

			address {
				span {
					display: block;
				}
				margin: 0;
			}

			.img-badge {
				position: relative;
				border: 1px solid $gray-200;
				display: block;
				border-radius: 2px;
				span {
					position: absolute;
					bottom: 0;
					left: 0;
					background-color: $gray-100;
					color: $gray-700;
					padding: 1px 2px;
					border-radius: 0px 2px 0px 2px;
					font-size: 80%;
				}
			}

			img:not(.item-image) {
				display: block;
				border-radius: 5px;
				border: 1px solid #ddd;
				width: 48px;
				height: 48px;
				margin-top: 5px;

				&.pdf {
					width: 50%;
					height: auto;
				}
				&.qrcode {
					height: 64px;
					width: 64px;
					background-color: #fff;
				}
				&.barcode {
					height: 64px;
					padding: 5px 5px 5px 5px;
					width: auto;
					background-color: #fff;
				}
			}

			.item-image {
				border: 0px none;
				width: 48px;
				height: 48px;
			}

			.items-info {
				font-size: 80%;
				color: $text-secondary;
			}

			.table {
				margin-top: 5px;
				margin-bottom: 0;
				background-color: #fff;
				color: $text-dark;

				td {
					padding: 10px 15px;
				}
			}

			.comment-card {
				margin-top: 5px;
				margin-bottom: 0;

				.card-block {
					padding: 10px;
					overflow: hidden;
				}
			}

			.field-group {
				margin-top: 5px;

				.list-group-item {
					padding: 10px;
					color: $text-dark;

					p:last-child {
						margin: 0;
					}
				}
			}

			.mail-subject {
				font-weight: $font-weight-bold;
				line-height: 19px;
				margin-bottom: 3px;
			}
			.mail-body {
				max-height: 50px;
				-webkit-line-clamp: 2;
				color: $text-secondary;
				margin-bottom: 3px;
			}

			.flag-attachment {
				cursor: pointer;
			}

			.spotcheck {
				.title {
					margin-top: 5px;
					text-align: center;

					.success .fa {
						color: $brand-success;
					}
					.warning .fa {
						color: $brand-danger;
					}
				}

				.legend-item {
					line-height: 16px;
					margin: 0 0 10px;

					&:last-child {
						margin: 0;
					}

					.text-muted {
						font-size: 90%;
					}

					.legend-color {
						width: 12px;
						height: 12px;
						display: inline-block;
						border-radius: 3px;
						margin-left: 2px;
						margin-top: 2px;

						&.success {
							background-color: $brand-success;
						}

						&.warning {
							background-color: $brand-danger;
						}

						&.gray {
							@include archived(#e5e5e5);
							background-size: 8px 8px;
							border: 1px solid #eee;
						}
					}

					.item-images {
						float: right;

						img {
							width: 28px;
							height: 28px;
							margin-right: 5px;
							display: inline-block;

							&:last-child {
								margin-right: 0;
							}

							border: 1px solid #ccc;
							border-radius: 4px;
						}
					}
				}
			}
		}
	}

	&.timeline-placeholder {
		&:before {
			background: linear-gradient(
				to bottom,
				$bg-color 0%,
				$timeline-placeholder-bg-color 20%,
				$timeline-placeholder-bg-color 80%,
				$bg-color 100%
			);
		}

		.timeline-date {
			span {
				display: block;
				width: 70px;
				height: 20px;
				background-color: $gray-200;
				color: $text-secondary;
				border: none;
			}
		}

		.timeline-time {
			span {
				display: inline-block;
				width: 50px;
				height: 16px;
				background-color: $timeline-placeholder-bg-color;
			}
		}

		.timeline-block {
			.timeline-icon {
				border-color: $timeline-placeholder-bg-color;
			}

			.timeline-user {
				span {
					display: inline-block;
					border-radius: 100%;
					width: 32px;
					height: 32px;
					background-color: $timeline-placeholder-bg-color;
				}
			}

			.comment-box {
				display: block;
				width: 100%;
				height: 101px;
				background-color: $timeline-placeholder-bg-color;
				margin-top: 5px;
				box-shadow: none;
			}

			.timeline-content {
				span {
					display: block;
					width: 100%;
					height: 18px;
					background-color: $timeline-placeholder-bg-color;
					margin-top: 5px;
					color: $text-secondary;
				}
			}
		}
	}
}

.comment-box {
	background-color: #fff;
	border-radius: $border-radius;
	border: 1px solid $gray-200;
	margin: 4px 4px 12px 0;
	transition: box-shadow 85ms ease-in;
	position: relative;

	&.busy {
		background-color: #fafafa;
		pointer-events: none;

		textarea {
			background: #fafafa !important;
		}

		.comment-box-options {
			opacity: 0.5;
		}
	}

	textarea {
		background: #fff !important;
		height: 101px;
		margin: 0;
		min-height: 101px;
		padding-bottom: 0;
		width: 100%;
		resize: none;
		border: 0;
		border-bottom: 30px solid transparent;

		&:focus {
			border-color: transparent !important;
		}
	}

	.comment-box-options {
		position: absolute;
		bottom: 6px;
		right: 6px;

		.comment-action {
			border-radius: 3px;
			float: left;
			padding: 5px;
			width: 30px;
			text-align: center;

			&:hover {
				background-color: rgba(9, 45, 66, 0.13);
			}

			input {
				cursor: pointer;
				opacity: 0;
				position: absolute;
				top: 0;
				left: 0;
				width: 30px;
				height: 100%;
				font-size: 100px;
			}
		}

		// Fixes emoji not immediatelly visible
		// on load
		&.open {
			.dropdown-menu {
				top: 30px;
				opacity: 1;
				right: 0;
				left: auto;
			}
		}

		.dropdown-menu {
			padding: 0;
			margin-right: -7px;
			margin-top: 9px;
			display: block;
			opacity: 0;
			top: -10000px;
			left: -10000px;
		}
	}
}
.gu-truncate {
	display: inline-block;
}
