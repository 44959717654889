@import '../lib/jquery-fullcalendar/fullcalendar.print.css';

@media print {
	.calendar {
		margin: 5px;
	}
	.pre-header {
		display: none;
	}
	.tabs {
		display: none;
	}
	.intercom-launcher {
		display: none;
	}
	#sideNav,
	#topNav {
		display: none;
	}
	.content {
		.actions {
			display: none;
		}
	}
	#appContent {
		margin: 0;
		overflow: hidden;
		height: fit-content;
	}

	h1 {
		font-size: 20px;
	}
	html,
	body {
		background-color: #fff;
		overflow: auto !important;
		height: auto;
	}

	#report-page {
		margin-top: 0;

		.actions {
			display: none;
		}

		h1 {
			font-size: 30px;
			margin-top: 0;
		}
		h3 {
			font-size: 20px;
			margin-bottom: 15px;
		}

		tfoot {
			th {
				text-align: right;
				font-weight: normal;

				a {
					font-size: 90%;
					color: #555;
				}
			}
		}

		#reportFilters {
			.print-filters {
				display: block !important;
			}

			.filter {
				&:after {
					display: none;
				}
			}
		}

		.report {
			background-color: transparent;
			box-shadow: none;
			padding: 0;

			.date-range {
				border: 0px none;
				padding: 0;
				color: #999;
				font-size: 17px;
				line-height: 45px;

				&.dropdown-toggle:after {
					display: none;
				}
			}

			.report-filter {
				display: none;
			}

			.chart-wrapper {
				canvas {
					width: 100% !important;
					height: 100% !important;
				}
			}
		}
	}

	.heatmap {
		.cell {
			width: 4%;

			&:after {
				display: none;
			}
		}
	}

	/* Print styling */

	[class*='col-sm-'] {
		float: left;
	}

	[class*='col-xs-'] {
		float: left;
	}

	.col-sm-12,
	.col-xs-12 {
		width: 100% !important;
	}

	.col-sm-11,
	.col-xs-11 {
		width: 91.66666667% !important;
	}

	.col-sm-10,
	.col-xs-10 {
		width: 83.33333333% !important;
	}

	.col-sm-9,
	.col-xs-9 {
		width: 75% !important;
	}

	.col-sm-8,
	.col-xs-8 {
		width: 66.66666667% !important;
	}

	.col-sm-7,
	.col-xs-7 {
		width: 58.33333333% !important;
	}

	.col-sm-6,
	.col-xs-6 {
		width: 50% !important;
	}

	.col-sm-5,
	.col-xs-5 {
		width: 41.66666667% !important;
	}

	.col-sm-4,
	.col-xs-4 {
		width: 33.33333333% !important;
	}

	.col-sm-3,
	.col-xs-3 {
		width: 25% !important;
	}

	.col-sm-2,
	.col-xs-2 {
		width: 16.66666667% !important;
	}

	.col-sm-1,
	.col-xs-1 {
		width: 8.33333333% !important;
	}

	.col-sm-1,
	.col-sm-2,
	.col-sm-3,
	.col-sm-4,
	.col-sm-5,
	.col-sm-6,
	.col-sm-7,
	.col-sm-8,
	.col-sm-9,
	.col-sm-10,
	.col-sm-11,
	.col-sm-12,
	.col-xs-1,
	.col-xs-2,
	.col-xs-3,
	.col-xs-4,
	.col-xs-5,
	.col-xs-6,
	.col-xs-7,
	.col-xs-8,
	.col-xs-9,
	.col-xs-10,
	.col-xs-11,
	.col-xs-12 {
		float: left !important;
	}

	body {
		margin: 0;
		padding: 0 !important;
		min-width: 768px;
	}

	.container {
		width: auto;
		min-width: 750px;
	}
}
