.codes-wrapper {
	background-color: white;
	border-radius: 8px;
	border: 1px solid $card-border-color;

	h6.heading {
		font-size: 16px;
		font-weight: 600;
		margin: 16px;
		text-transform: none;
		color: $gray-900;
		line-height: 24px;
	}
}

.codes-list {
	border: none;
	border-top: 1px solid $card-border-color;
	border-radius: 0;

	.empty {
		border-bottom: 1px solid $card-border-color;
	}

	.card {
		margin: 0;
		border: 0px none;
		height: 75px;
		display: flex;
		align-items: center;

		&.add-code-card {
			height: auto;
			justify-content: center;

			.card-block {
				padding: 16px 8px 0 8px;
			}
		}

		&:not(:first-child) {
			border-radius: 0px;
		}

		&:not(:last-child) {
			border-radius: 0px;
			border-bottom: 1px solid $card-border-color;
		}

		&.qrcode-card {
			img {
				width: 64px;
				padding: 0 5px;

				&.fallback {
					opacity: 0.1;
				}
			}
		}
		&.barcode-card {
			img {
				width: 64px;
				height: 64px;
				padding: 5px;

				&.fallback {
					opacity: 0.1;
				}
			}
		}

		.close {
			font-size: 16px;
			padding: 10px;
			position: absolute;
			top: 17px;
			right: 0;
		}
	}
}
