#fieldModal {
	svg {
		fill: white;
		stroke: currentColor;

		rect:not(.nc) {
			stroke: currentColor;
			fill: #ffffff;
			stroke-width: 3;
		}

		rect.nc,
		polygon {
			fill: currentColor;
		}

		text {
			font-family: $font-family-monospace;
			font-weight: normal;
			fill: currentColor;
			font-size: 27px;
		}
	}

	.field-preview {
		display: flex;
		width: 100px;
		height: 100px;
		background-color: $gray-50;
		padding: 10px;
		color: #c7c7c7;
		border-radius: 5px;
		box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.05);
	}

	.field-types {
		&.disabled {
			.card:not(.active) {
				opacity: 0.5;
				pointer-events: none;
			}
		}

		.card {
			cursor: pointer;

			&:hover {
				border-color: $brand-primary;
			}
		}

		.active {
			svg {
				rect {
					stroke: $brand-primary;
				}
			}

			.field-preview {
				color: $brand-primary;
			}

			border-color: $brand-primary;
			box-shadow: 0 0px 5px $brand-primary;
		}
	}

	fieldset {
		border: 1px solid rgba(0, 0, 0, 0.15);
		border-radius: 5px;
		padding: 15px;
		margin-bottom: 14px;

		legend {
			width: auto;
			padding: 0 5px;
			font-size: 14px;
			font-weight: $font-weight-bold;
			margin-bottom: 0;
		}

		.form-group:last-child {
			margin-bottom: 0;
		}

		label {
			font-weight: normal;
			padding: 0;
		}
		p {
			margin-bottom: 0;
		}

		.media-left {
			padding-right: 20px;

			.toggle-switch {
				margin: 0 0 7px;
			}
		}

		.form-group {
			&.disabled {
				pointer-events: none;
				opacity: 0.5;
			}
		}

		.row:last-child {
			margin-bottom: 0px;
		}
	}
}
