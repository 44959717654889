#equipmentModal {
	max-width: 650px;
	height: 100%;

	aside {
		height: 100%;
	}

	.in-kit {
		position: relative;

		@include kit-badge(30px, #333, 3px, 4px, #fff, 8px);
	}

	.results-breadcrumb + .results-list {
		max-height: 355px;
	}

	.form-search + .results-list {
		max-height: 390px;
	}

	.results-list {
		max-height: 345px;
		overflow-y: auto;
		clear: both;

		& > a {
			border-bottom: 0px none;
			border-radius: 5px;
			margin-bottom: 5px;

			&:hover {
				background-color: #f5f5f5;
			}

			.media-body {
				h4 {
					margin-bottom: 0;
				}

				vertical-align: middle;
			}

			&.checked {
				background-color: lighten($brand-primary, 50%);
			}
		}

		.btn {
			margin-top: 10px;
		}
	}

	.lead {
		margin-bottom: 0;
	}

	.modal-title {
		small {
			font-size: 13px;
		}
	}

	#selection {
		cursor: pointer;

		.item-image {
			width: 34px;
			border: 1px solid #ccc;
			border-radius: 3px;
		}
		.more-image {
			width: 34px;
		}
	}

	.tabs {
		padding-left: 0;
		padding-right: 0;
	}

	.form-search {
		display: flex;
		gap: 10px;
		margin-bottom: 5px;

		form {
			position: relative;
			width: 100%;

			input {
				padding-right: 55px;
			}

			.search-actions {
				position: absolute;
				right: 10px;
				top: 0;
				height: 100%;
				display: flex;
				align-items: center;

				.clear-search {
					color: #ccc;
					font-size: 30px;
					line-height: 18px;
					font-weight: $font-weight-light;
					margin-right: 5px;
					text-decoration: none;
				}

				.search-help {
					color: #ccc;
					font-size: 15px;
					border-radius: 100%;
					border: 2px solid #ccc;
					width: 22px;
					height: 22px;
					display: flex;
					align-items: center;
					justify-content: center;
					text-decoration: none !important;

					&:hover {
						color: #aaa;
						border-color: #aaa;
					}

					&:before {
						content: '?';
					}
				}
			}
		}

		.btn {
			outline: none;
		}
	}

	.modal-body {
		min-height: 480px;

		.aligner {
			height: 395px;

			.aligner-item {
				max-width: 85%;
			}
		}

		.results-loading {
			height: 395px;
		}

		.form-search + .results-empty {
			height: 395px;
		}

		.results-breadcrumb {
			small {
				margin-right: 3px;
			}
		}

		.results-empty {
			height: 450px;
			flex-direction: column;

			p {
				margin-bottom: 25px;

				&.lead {
					max-width: 400px;
				}
			}

			svg {
				width: 100px;
				color: $text-secondary;
				display: block;
				margin: 0px auto 15px;
			}

			i {
				font-size: 80px;
				margin-bottom: 10px;
				color: $text-secondary;
			}
		}

		.advanced {
			.select-picker {
				min-width: initial;

				.dropdown-menu {
					min-width: 200px;
				}
			}

			& > .btn-group {
				margin-top: 5px;
			}
		}
	}

	.breadcrumb {
		text-transform: uppercase;
		margin-top: 15px;

		.breadcrumb-item {
			a {
				color: $text-secondary;
				font-weight: 600;
			}

			span {
				display: none;
				color: $body-color;
			}

			&:last-child {
				span {
					display: inline-block;
				}
				a {
					display: none;
				}
			}
			&:only-child {
				span {
					color: $text-secondary;
				}
			}

			position: relative;
			padding-right: 20px;
		}

		.breadcrumb-item + .breadcrumb-item::before {
			border-style: solid;
			border-width: 0.05em 0.05em 0 0;
			content: '';
			display: inline-block;
			height: 8px;
			left: -16px;
			position: absolute;
			top: 7px;
			transform: rotate(45deg);
			vertical-align: top;
			width: 8px;
			padding: 0;
		}
	}
}

//https://www.cssscript.com/demo/csscss3-custom-checkboxes-radios-switches-el-checkbox/
//https://github.com/EvanLiu2968/el-checkbox/blob/master/src/checkbox.less
.el-checkbox {
	position: relative;
	font-size: 150%;
	display: block;
	cursor: pointer;
	background-color: transparent !important;

	input[type='checkbox'] {
		display: none;
	}

	input[type='checkbox'] + .el-checkbox-style {
		position: relative;
		display: inline-block;
		width: 1.4em;
		height: 1.4em;
		vertical-align: middle;
		cursor: pointer;

		/*&:hover{
				&:before{
					border: 1px solid #f5f5f5;
  					animation-name: none;
				}
			}*/

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			display: inline-block;
			width: 1.4em;
			height: 1.4em;
			content: '';
			border: 1px solid #dcdcdc;
			border-radius: 50%;
		}

		&:after {
			position: absolute;
			content: '+';
			font-size: 25px;
			line-height: 26px;
			text-align: center;
			width: 30px;
			display: block;
			color: #aaa;
			font-weight: 100;
		}
	}

	input[type='checkbox']:checked + .el-checkbox-style {
		&:before {
			border: $brand-primary;
			background: $brand-primary;
		}

		&:after {
			content: ' ';
			font-size: 100%;
			top: 0.3em;
			left: 0.55em;
			box-sizing: border-box;
			width: 0.3em;
			height: 0.65em;
			transform: rotate(45deg);
			border-right: 2px solid #fff;
			border-bottom: 2px solid #fff;
		}
	}
}

.el-radio {
	position: relative;
	font-size: 150%;
	display: block;
	cursor: pointer;
	background-color: transparent !important;

	input[type='radio'] {
		display: none;
	}

	input[type='radio'] + .el-radio-style {
		position: relative;
		display: inline-block;
		width: 1.4em;
		height: 1.4em;
		vertical-align: middle;
		cursor: pointer;

		/*&:hover{
				&:before{
					border: 1px solid #f5f5f5;
  					animation-name: none;
				}
			}*/

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			display: inline-block;
			width: 1.4em;
			height: 1.4em;
			content: '';
			border: 1px solid $input-border-color;
			border-radius: 50%;
		}
	}

	input[type='radio']:checked + .el-radio-style {
		&:before {
			border-color: $brand-primary;
		}

		&:after {
			background: $brand-primary;
			content: ' ';
			position: absolute;
			top: 0.2em;
			left: 0.2em;
			box-sizing: border-box;
			width: 1em;
			height: 1em;
			border-radius: 50%;
		}
	}
}

//https://codepen.io/IamManchanda/pen/JNZodd
.drilldown-list {
	margin: 5px 0 0;
	list-style-type: none;
	padding: 0;
	position: relative;
	overflow: hidden;
	font-size: 17px;
	min-height: 360px;

	.listitem {
		display: block;

		a {
			display: block;
			position: relative;
			padding: 5px 15px 5px 0;
			color: $body-color;

			&:hover {
				text-decoration: none;
			}

			&:after {
				border-style: solid;
				border-width: 0.05em 0.05em 0 0;
				content: '';
				display: inline-block;
				height: 0.7em;
				right: 15px;
				position: absolute;
				top: 10px;
				transform: rotate(45deg);
				vertical-align: top;
				width: 0.7em;
				color: $text-secondary;
			}
		}

		.is-active.drilldown-sublist {
			height: 100%;
			z-index: 1;
			display: block;
			transform: translateX(-100%);
			visibility: visible;
		}
	}

	.drilldown-sublist {
		margin: 0;
		padding: 0;
		list-style-type: none;
		visibility: hidden;

		position: absolute;
		top: 0;
		left: 100%;
		z-index: -1;
		width: 100%;
		background: #fff;
		/*-webkit-transition: -webkit-transform .15s linear;
	    transition: -webkit-transform .15s linear;
	    transition: transform .15s linear;
	    transition: transform .15s linear,-webkit-transform .15s linear;*/
	}
}

//https://codepen.io/kylelavery88/pen/QjZNVP
@mixin toggle-switch($size: 45px, $extraClass: '') {
	$width: $size;
	$height: $size / 2.5;

	input[type='checkbox'] {
		display: none;

		&:checked ~ .toggle {
			background: #fff;
			left: ($width - ($height * 1.4 - $height) / 2) - $height;
			transition: 0.5s;
		}

		&:checked ~ .switch {
			background: $switch-active-color;
			transition: 0.5s;
		}
	}
	.switch {
		display: block;
		width: $width;
		height: $height;
		background: $switch-color;
		border-radius: $height / 2;
		position: absolute;
		top: 0;
		left: 0;
		transition: 0.5s;
	}

	.toggle {
		height: $height * 1.4;
		width: $height * 1.4;
		border-radius: 50%;
		background: white;
		position: absolute;
		top: ($height * 1.4 - $height) / -2;
		left: ($height * 1.4 - $height) / -2;
		box-shadow: 0 $width / 50 $width / 25 rgba(black, 0.4);
		transition: 0.5s;
	}

	display: inline-block;
	margin: 0 5px 0 5px;
	width: $width;
	height: $height;
	text-align: center;
	position: relative;
	top: 2px;

	&.disabled {
		pointer-events: none;
		opacity: 0.5;
	}
}
.toggle-switch {
	@include toggle-switch();
}
.toggle-switch-sm {
	@include toggle-switch(35px, '-sm');
}
