.fc-unthemed {
	th,
	td,
	thead,
	tbody,
	.fc-divider,
	.fc-row,
	.fc-content,
	.fc-popover,
	.fc-list-view,
	.fc-list-heading td {
		border-color: $gray-200;
	}

	.fc-nonbusiness {
		background: $gray-50;
	}

	.fc-day-top.fc-other-month {
		opacity: 1;
		color: $gray-300;
	}

	.fc-highlight {
		background: $indigo-300;
		opacity: 0.1;
	}
}
