.input-editor {
	position: relative;

	&:not(.no-fields) {
		.single-line {
			width: calc(100% - 57px);
			border-right: 0px none;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	[contenteditable] {
		display: inline-block;
		overflow-x: hidden;
		line-height: 2;

		&.single-line {
			white-space: nowrap;
			overflow: hidden;
			height: 44px;

			*:not(.merge-field) {
				display: inline;
				white-space: nowrap;
			}
			br {
				display: none !important;
			}
		}

		&.multi-line {
			padding-right: 70px;
			height: 210px;
			overflow-y: scroll;
			white-space: pre-wrap;

			.btn {
				border-bottom-right-radius: 0;
			}
		}
	}

	.input-editor-btn {
		.btn {
			position: absolute;
			right: 0;
			top: 0;
			height: 44px;

			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}

		.dropdown-menu {
			width: 100%;

			.dropdown-search {
				padding: 0 15px;

				input {
					display: block;
					width: 100%;
					padding: 0.5rem 0.75rem;
					font-size: 1rem;
					line-height: 1.25;
					color: #55595c;
					background-color: #fff;
					background-image: none;
					background-clip: padding-box;
					border: 1px solid rgba(0, 0, 0, 0.15);
					border-radius: 0.25rem;
				}
			}
		}
	}
}

.wizard-modal {
	.steps-info {
		background-color: $gray-50;
		height: 529px;
		padding: 0;
		border-bottom-left-radius: 0.3rem; // wizard modal left corner was clipping out
		border-right: 1px solid $modal-header-border-color;

		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	.steps-content {
		padding: 0;
	}

	#body {
		.dropdown-menu {
			top: 48px;
		}
	}

	.empty {
		height: 300px;
		display: flex;
		align-items: center;
		justify-content: center;

		i {
			font-size: 100px;
		}
	}

	.notification-setting-info {
		padding: 0 0 20px;
	}

	#notification-kind {
		margin-bottom: 0;
		margin-top: 5px;

		h4 {
			font-size: 15px;
		}

		.disabled {
			border-color: #ddd;
			color: #ddd;
			pointer-events: none;

			svg g {
				fill: #ddd;
			}
		}

		.selected {
			border-color: $brand-primary;
			box-shadow: 0 0px 5px $brand-primary;
			color: $brand-primary;

			svg g {
				fill: $brand-primary;
			}
		}

		a:hover {
			border-color: $brand-primary;
			color: $brand-primary;
			svg g {
				fill: $brand-primary;
			}
		}
	}

	.input-group-trigger {
		display: flex;

		.form-control {
			order: 1;
			border-left: 0px none;
			border-radius: 0;

			&:focus {
				& ~ .group-text {
					border-color: $input-border-focus;
				}
			}
		}
		.group-text {
			order: 0;
			background-color: #fff;
			border: 1px solid rgba(0, 0, 0, 0.15);
			border-radius: 0.25rem;
			padding: 0.5rem 0.75rem;
			font-size: 1rem;
			line-height: 1.25;
			border-right: 0px none;

			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		.group-btn {
			order: 2;
			position: relative;

			.btn {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}

	.dropdown-items {
		max-height: 150px;
		overflow-y: auto;
	}

	.select-picker {
		.selection-text {
			width: calc(100% - 15px);
		}

		.results {
			label {
				pointer-events: none;
			}
		}
	}

	.steps {
		padding: 45px;

		.step {
			position: relative;
			color: $text-muted;
			pointer-events: none;

			&.active {
				color: $brand-primary;
				pointer-events: all;

				.number {
					opacity: 1;
					color: $brand-primary;
					border-color: $brand-primary;
					background-color: transparent;
				}

				.title {
					cursor: pointer;
				}
			}

			&.done,
			&.valid,
			&.available {
				color: $brand-primary;
				pointer-events: all;

				.number {
					opacity: 1;
					border-color: $brand-primary;
					background-color: $brand-primary;
					color: #fff;
				}

				.title {
					cursor: pointer;
				}
			}

			&.nextAvailable {
				&:after {
					background-color: $brand-primary !important;
				}
			}

			.number {
				border: 1px solid $text-muted;
				color: $text-muted;
				background-color: #ddd;
				opacity: 0.4;
				display: inline-block;
				border-radius: 100%;
				width: 35px;
				height: 35px;
				text-align: center;
				padding-top: 5px;
			}

			.title {
				position: absolute;
				left: 50px;
				top: 5px;
			}

			&:not(:last-child) {
				&:after {
					content: ' ';
					height: 50px;
					width: 1px;
					background-color: $text-muted;
					display: block;
					margin-left: 17px;
				}
			}
		}
	}
}

#notification-preview {
	border-radius: $border-radius;
	border: 1px solid rgba(0, 0, 0, 0.15);
}
#push-preview {
	background-color: #f5f5f5;
	padding: 15px;
	display: flex;
	align-items: center;
	box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.05);

	#ios-push-container {
		background-color: rgba(255, 255, 255, 0.65);
		border-radius: 10px;
		padding: 15px;
		margin: 15px;
		width: 100%;

		#ios-push-title img {
			border-radius: 5px;
		}

		#ios-push-title,
		#ios-push-subject,
		#ios-push-body {
			color: rgba(0, 0, 0, 0.6);
			margin-bottom: 5px;
		}

		#ios-push-subject,
		#ios-push-body {
			margin: 0;
		}

		#ios-push-subject {
			font-weight: $font-weight-bold;
		}
	}
}
#sms-preview {
	background-color: #f5f5f5;
	padding: 15px;
	display: flex;
	align-items: center;
	box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.05);

	#sms-container {
		background-color: lighten($brand-primary, 5%);
		color: #fff;
		border-radius: 10px;
		padding: 10px;
		margin: 15px auto;
		width: 50%;
		position: relative;

		#sms-body {
			margin: 0;
			word-break: break-word;
		}

		&:before {
			content: '';
			width: 0px;
			height: 0px;
			position: absolute;
			border-left: 10px solid lighten($brand-primary, 5%);
			border-right: 5px solid transparent;
			border-top: 5px solid lighten($brand-primary, 5%);
			border-bottom: 10px solid transparent;
			left: 13px;
			bottom: -10px;
		}
	}
}

.merge-field-temp {
	display: inline-block;
	padding: 3px 10px 3px 3px;
	background-color: #eee;
	border-radius: 10px;
	vertical-align: middle;
	line-height: 1.25 !important;
	border: 0px none;
	pointer-events: none;
	margin-top: -1px;

	.kind {
		background-color: #fff;
		font-size: 90%;
		padding: 2px 5px;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		margin-right: 5px;
		margin-top: -1px;
		display: inline-block;
	}
}
