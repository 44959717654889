// New colors
$gray-50: #f8fafc;
$gray-100: #f1f5f9;
$gray-200: #e2e8f0;
$gray-300: #cbd5e1;
$gray-400: #94a3b8;
$gray-500: #64748b;
$gray-600: #475569;
$gray-700: #334155;
$gray-800: #1e293b;
$gray-900: #0f172a;

$indigo-50: #f3f3ff;
$indigo-100: #eae9fe;
$indigo-200: #d8d6fe;
$indigo-300: #bbb5fd;
$indigo-500: #7b61ff;
$indigo-700: #5428d9;
$indigo-900: #3b1d95;

$blue-50: #eff6ff;
$blue-100: #dbeafe;
$blue-200: #bfdbfe;
$blue-300: #93c5fd;
$blue-500: #3b82f6;
$blue-700: #1d4ed8;
$blue-900: #1e3a8a;

$yellow-50: #fffbeb;
$yellow-100: #fff5e0;
$yellow-200: #fde68a;
$yellow-300: #fcd34d;
$yellow-500: #f59e0b;
$yellow-700: #b45309;
$yellow-900: #78350f;

$red-50: #fef2f2;
$red-100: #fee2e2;
$red-200: #fecaca;
$red-300: #fca5a5;
$red-500: #ef4444;
$red-700: #b91c1c;
$red-900: #7f1d1d;

$green-50: #ecfdf5;
$green-100: #d1fae5;
$green-200: #a7f3d0;
$green-300: #6ee7b7;
$green-500: #10b981;
$green-700: #047857;
$green-900: #064e3b;

$white: #fff;
$black: #000;

// Legacy colors
$gray-dark: $gray-900 !default;
$gray: $gray-700 !default;
$gray-light: $gray-500 !default;
$gray-lighter: $gray-100 !default;
$gray-lightest: $gray-50 !default;

$brand-primary: $indigo-500 !default;
$brand-success: $green-500 !default;
$brand-info: $blue-500 !default;
$brand-warning: $yellow-500 !default;
$brand-danger: $red-500 !default;
$brand-inverse: $gray-dark !default;

// New variables
$text-muted: $gray-500;
$text-dark: $gray-900;
$text-heading: $gray-700;
$text-secondary: $gray-500;

$link-hover-color: $indigo-700;
$link-color: $indigo-500;

$breadcrumb-active-color: $gray-400;

// Buttons

$btn-primary-color: $white !default;
$btn-primary-bg: $indigo-500 !default;
$btn-primary-border: $btn-primary-bg !default;

$btn-secondary-color: $gray-700 !default;
$btn-secondary-bg: $white !default;
$btn-secondary-border: $gray-300 !default;

$btn-success-color: $white !default;
$btn-success-bg: $green-500 !default;
$btn-success-border: $btn-success-bg !default;

$btn-warning-color: $white !default;
$btn-warning-bg: $yellow-500 !default;
$btn-warning-border: $btn-warning-bg !default;

$btn-danger-color: $white !default;
$btn-danger-bg: $red-500 !default;
$btn-danger-border: $btn-danger-bg !default;

$btn-link-disabled-color: $gray-500 !default;

$border-radius: 8px;
$btn-line-height: 1.25rem;

// Input fields
$input-color: $gray-700 !default;
$input-border-color: $gray-300 !default;
$input-color-placeholder: $gray-400 !default;
$input-border-focus: $indigo-300 !default;
$input-border-radius: 8px;

$input-line-height: 1.25rem;

// Typography
$font-family-sans-serif:
	Inter,
	-apple-system,
	BlinkMacSystemFont,
	'Segoe UI',
	Roboto,
	'Helvetica Neue',
	Arial,
	sans-serif !default;
$font-family-monospace: Inter, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !default;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;

$font-size-h1: 2.25rem;
$font-size-h2: 1.75rem;
$font-size-h3: 1.5rem;
$font-size-h4: 1.25rem;
$font-size-h5: 1rem;
$font-size-h6: 0.875rem;

// Lead
$lead-font-size: 16px;
$lead-font-weight: $font-weight-normal !default;

// Cards
$card-border-color: $gray-200 !default;

// List group
$list-group-border-color: $gray-200 !default;
$list-group-hover-bg: $gray-50 !default;
$list-group-link-color: $text-dark;
$list-group-link-heading-color: $text-dark;

// Tables
$table-bg-accent: $gray-50 !default;
$table-bg-hover: $gray-50 !default;
$table-border-color: $gray-200 !default;

// Dropdowns
$dropdown-link-color: $gray-900 !default;
$dropdown-link-hover-color: $gray-900 !default;
$dropdown-link-hover-bg: $gray-50 !default;
$dropdown-link-active-color: $gray-900 !default;
$dropdown-link-active-bg: $gray-50 !default;
$dropdown-border-color: $gray-200 !default;
$dropdown-item-padding-y: 6px !default;
$dropdown-item-padding-x: 12px !default;

//
// General
$bg-color: $gray-50;
$blue: $indigo-500;
$padding: 16px;

//
// Top navigation
$topnav-bg-color: $white;

$navbar-search-width: 380px;

//
// Icon navigation
$sidenav-bg-color: $gray-900;
$sidenav-color: $gray-200;
$sidenav-hover-color: $white;

//
// Page
//

//
// Pre-header
$preheader-bg-color: $white;
$preheader-padding: 12px $padding;
$preheader-border-color: $gray-200;

//
// Header
$header-bg-color: $white;
$header-padding: $padding;
$header-border-color: $gray-200;
$header-title-size: 32px;
$header-subtitle-size: 16px;

//
// Tabs
$tabs-bg-color: $white;
$tabs-border-color: $gray-200;
$tabs-padding: $padding $padding 0;
$tabs-padding-minimal: 0 $padding 0;
$tabs-color: $text-dark;
$tabs-active-border-color: $blue;

//
// Content
$content-padding: $padding;

//
// Timeline
$timeline-placeholder-bg-color: darken($bg-color, 5%);

//
// Tags
$statusAvailable: $green-500;
$statusCheckedOut: $gray-500;
$statusAwaitCheckout: $red-700;
$statusMaintenance: $indigo-300;
$statusExpired: $gray-900;
$statusConverted: $gray-500;
$statusCreating: #fff;
$statusOverdue: $red-500;
$statusIncomplete: $yellow-500;
$statusCustody: $indigo-300;
$statusArchived: $gray-300;

$orderColor: $statusCheckedOut;
$reservationColor: $statusAvailable;

//
// Labels
$labelColors: (
	gold: #FFC244,
	darkorchid: #D814AD,
	royalblue: #4C69F6,
	orangered: #FF462D,
	greenyellow: #42D396,
	orange: #FF9736,
	slategray: #9BA9BF,
	deepskyblue: #758CFF,
	hotpink: #FF6A8B,
	limegreen: #16B874,
	peru: #D2A47A,
	gainsboro: #CDD5FF,
	darkred: #D3002F,
	indigo: #080E8A,
	teal: #0EB4BE,
	lightsalmon: #FFC2CF,
	forestgreen: #006849,
);

:root {
	@each $color, $value in $labelColors {
		--label-#{$color}: #{$value};
	}
}

//
// Modals
$modal-content-border-color: $white;
$modal-backdrop-bg: $gray-700 !default;
$modal-backdrop-opacity: 0.5 !default;
$modal-header-border-color: $gray-200 !default;
$modal-footer-border-color: $gray-200 !default;

//
// Switches
$switch-color: $gray-200;
$switch-active-color: $indigo-500;

// Checkbox
$checkbox-border-color: $gray-300 !default;

$checkbox-active-color: $indigo-500 !default;
$checkbox-active-border-color: $indigo-500 !default;

$checkbox-focus-border-color: $indigo-300 !default;
$checkbox-focus-color: $indigo-100 !default;

// Progress bar
$progress-bg: $gray-100 !default;
$progress-bar-color: $indigo-500 !default;

// Report
$report-skeleton-color: $gray-100;
$report-skeleton-highlight-color: $gray-50;
$report-selected-group-color: $gray-500;
$reports-list-hover-color: $gray-50;
:root {
	--report-background-color: #{$gray-100};
}

// Messages
$state-success-bg: $green-100;
$state-success-text: $green-900;

$state-info-bg: $indigo-100;
$state-info-text: $indigo-900;

$state-warning-bg: $yellow-100;
$state-warning-text: $yellow-900;

$state-danger-bg: $red-100;
$state-danger-text: $red-900;

// Bootstrap date picker
$daterangepicker-color: $gray-900;

$daterangepicker-active-color: $gray-100;
$daterangepicker-active-bg-color: $indigo-500;

$daterangepicker-border-color: $gray-200;
$daterangepicker-border-radius: $border-radius;

$daterangepicker-control-active-border-color: $indigo-200;

$daterangepicker-in-range-bg-color: $indigo-50;
$daterangepicker-ranges-color: $indigo-500;
