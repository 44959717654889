// Recreate tailwind-esque grid helpers
.grid {
	display: grid;
}

$templates: 1, 2, 3, 4;
$breakpoints: md, lg, xl;

@each $template in $templates {
	.grid-cols-#{$template} {
		grid-template-columns: repeat($template, minmax(0, 1fr));
	}

	.col-span-#{$template} {
		grid-column: span $template / span $template;
	}

	@each $breakpoint in $breakpoints {
		@include media-breakpoint-up($breakpoint) {
			.#{$breakpoint}\:grid-cols-#{$template} {
				grid-template-columns: repeat($template, minmax(0, 1fr));
			}

			.#{$breakpoint}\:col-span-#{$template} {
				grid-column: span $template / span $template;
			}
		}
	}
}

$gaps: (
	2: 8px,
	4: 16px,
	8: 32px,
);

@each $gap, $pixels in $gaps {
	.gap-#{$gap} {
		gap: $pixels;
	}
}
