#cart {
	display: none;
}

.cart-link {
	.fa-circle {
		display: none;
	}

	&.active {
		span {
			position: relative;
		}
		.fa-circle {
			display: block;
			font-size: 8px;
			color: red;
			position: absolute;
			top: 0px;
			right: -6px;
		}
	}
}

.cart-popover {
	padding: 0;
	left: -110px !important;
	z-index: 1000;

	.popover-arrow {
		left: 90% !important;

		&:after {
			border-bottom-color: $gray-100 !important;
		}
	}

	.popover-title {
		background-color: $gray-100;

		.nav-tabs {
			padding: 10px 10px 0;
		}
	}

	.popover-content {
		.cart-loading {
			width: 244px;
			height: 213px;
		}

		.cart-content {
			.images {
				display: flex;
				margin-bottom: 15px;

				.btn-secondary {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 58px;
					height: 58px;
					margin-right: 3px;
				}

				img {
					width: 58px;
					height: 58px;
					border: 1px solid #ccc;
					border-radius: 3px;
				}
			}
		}
	}

	.nav-tabs .nav-link:not(.active):focus,
	.nav-tabs .nav-link:not(.active):hover {
		border-color: $gray-200 $gray-200 #ddd;
	}

	.picker .btn.btn-secondary.dotted {
		border: 1px solid $gray-300;
	}
}
