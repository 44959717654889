$fa-font-path: '../lib/font-awesome/fonts';

.svg-inline--fa {
	display: inline-block;
	font-size: inherit;
	height: 1em;
	overflow: visible;
	vertical-align: -0.125em;
}

@import '../../lib/font-awesome/scss/font-awesome';

.fa-drag {
	&:before {
		content: '\f142';
	}
	&:after {
		position: relative;
		left: 1px;
		content: '\f142';
	}
}

.color-yellow {
	color: $yellow-500;
}

.color-green,
.color-darkgreen {
	color: $green-500;
}

.color-red {
	color: $red-500;
}

.fa-question-circle-o {
	vertical-align: middle;

	&:before {
		content: ' ';
		display: inline-block;
		background-image: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" color="%23334155"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 8.625C11.5386 8.625 11.1343 8.87449 10.9166 9.25073C10.6401 9.72879 10.0284 9.89215 9.5503 9.61561C9.07224 9.33906 8.90888 8.72733 9.18542 8.24927C9.74585 7.28046 10.7958 6.625 12 6.625C13.7949 6.625 15.25 8.08007 15.25 9.875C15.25 10.4926 14.9723 11.0017 14.6856 11.3676C14.3954 11.738 14.0331 12.0431 13.7114 12.276C13.4585 12.4591 13.2092 12.6127 13 12.732V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V12.125C11 11.7314 11.2309 11.3744 11.5899 11.213C11.5902 11.2128 11.5906 11.2126 11.591 11.2125L11.5911 11.2124L11.5914 11.2123L11.6028 11.207C11.6144 11.2016 11.6334 11.1926 11.6587 11.1803C11.7095 11.1556 11.7849 11.1177 11.8766 11.0682C12.0623 10.968 12.3034 10.8262 12.5386 10.6559C12.7794 10.4817 12.9797 10.302 13.1113 10.134C13.2465 9.96149 13.25 9.87867 13.25 9.875C13.25 9.18464 12.6904 8.625 12 8.625ZM11 16C11 15.4477 11.4477 15 12 15H12.01C12.5623 15 13.01 15.4477 13.01 16C13.01 16.5523 12.5623 17 12.01 17H12C11.4477 17 11 16.5523 11 16Z" fill=\'#{encodeColor($gray-400)}\'></path></svg>');
		width: 1em;
		height: 1em;
		background-repeat: no-repeat;
		background-position: center center;
	}
}
