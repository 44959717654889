.event-list {
	overflow-y: auto;
	height: 100%;

	.event-title {
		background-color: $gray-50;
		font-size: 14px;
		margin: 0;
		padding: 5px;
		font-weight: normal;
		color: $text-heading;

		.tag {
			padding: 3px 5px;
			font-size: 10px;
			background-color: $text-secondary;
			color: #fff;
			font-weight: 100;
		}
	}

	.list-group-item {
		border-radius: 0;
		border-left: 0px none;
		border-right: 0px none;

		.multiline-text-truncate {
			line-height: 16px;
		}
	}
}
