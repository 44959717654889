.messg {
	font-family: $font-family-base;
	border-radius: 4px;

	.messg__button {
		font-family: $font-family-base;
		border-radius: 4px;
	}

	&--default {
		background-color: $gray-300;

		.messg__button {
			background-color: $gray-500;
			color: $gray-300;
		}

		.messg__button:hover {
			background-color: $gray-700;
		}
	}

	&--success .messg__button {
		background-color: $green-700;
	}

	&--success .messg__button:hover {
		background-color: $green-900;
	}

	&--info .messg__button {
		background-color: $indigo-700;
	}

	&--info .messg__button:hover {
		background-color: $indigo-900;
	}

	&--warning .messg__button {
		background-color: $yellow-700;
	}

	&--warning .messg__button:hover {
		background-color: $yellow-900;
	}

	&--error .messg__button {
		background-color: $red-700;
	}

	&--error .messg__button:hover {
		background-color: $red-900;
	}
}
