#user_permissions {
	small {
		&.uppercase {
			text-transform: uppercase;
		}
	}

	.fa-caret-right,
	.fa-caret-down {
		color: rgba(0, 0, 0, 0.3);
	}

	.permission-details {
		margin-bottom: 0;

		.list-group-item {
			padding: 15px 25px 10px 25px;
			white-space: initial;
		}

		.col-md-6 {
			margin-bottom: 10px;
		}

		.title {
			font-weight: 500;
			margin-bottom: 10px;

			.fa {
				color: rgba(0, 0, 0, 0.4);
				width: 15px;
			}
		}
		.desc {
			font-size: 12px;
			line-height: 14px;

			.relative {
				position: relative;
				padding-left: 18px;
			}

			svg {
				font-size: 12px;
				display: inline-block;
				width: 1em;
				height: 1em;
				margin-right: 10px;
				position: absolute;
				left: 0;
				top: 1px;
			}
		}
	}

	.access-level {
		margin-top: 3px;
		font-size: 0;

		span {
			display: inline-block;
			height: 12px;
			width: 4px;
			margin-right: 2px;
			background-color: #eee;
			border-radius: 2px;
		}

		&.low {
			span:nth-child(1) {
				background-color: $brand-primary;
			}
		}

		&.medium {
			span:nth-child(1),
			span:nth-child(2) {
				background-color: $brand-primary;
			}
		}

		&.high {
			span:nth-child(1),
			span:nth-child(2),
			span:nth-child(3) {
				background-color: $brand-primary;
			}
		}

		&.all {
			span {
				background-color: $brand-primary;
			}
		}
	}
}

#permissions_new {
	@include media-breakpoint-up(sm) {
		.md-up {
			display: block;
		}
		.md-down {
			display: none;
		}
	}

	.md-down {
		.dropdown-menu {
			width: 100%;
		}
	}
	@include media-breakpoint-down(sm) {
		.md-down {
			display: block;
		}
		.md-up {
			display: none;
		}
	}

	.permissions-card {
		position: relative;
		background-color: $white;

		.row {
			margin: 0;
		}

		.permission-group-list {
			a {
				display: block;
				padding: 15px 20px;
				font-size: 15px;
				border-bottom: 1px solid rgba(0, 0, 0, 0.125);
				color: $text-dark;
				text-decoration: none;
				position: relative;
				background-color: $white;

				i {
					width: 25px;
				}

				&:hover {
					background-color: $gray-50;
				}

				&.selected {
					color: $text-dark;
					background-color: $gray-50;

					&:after {
						content: ' ';
						position: absolute;
						right: -10px;
						top: 50%;
						margin-top: -10px;
						width: 0;
						height: 0;
						border-top: 10px solid transparent;
						border-bottom: 10px solid transparent;
						border-left: 10px solid $gray-50;
						z-index: 55;
					}
					&:before {
						content: ' ';
						position: absolute;
						right: -12px;
						top: 50%;
						margin-top: -11px;
						width: 0;
						height: 0;
						border-top: 11px solid transparent;
						border-bottom: 11px solid transparent;
						border-left: 11px solid rgba(0, 0, 0, 0.125);
						z-index: 54;
					}
				}
			}
		}

		.permissions-list {
			padding: 15px 30px;
			min-height: 600px;
			border-left: 1px solid rgba(0, 0, 0, 0.125);
			background-color: #fff;

			small {
				text-transform: uppercase;
				margin: 10px 0 5px;
				display: inline-block;
			}

			input[type='radio'],
			input[type='checkbox'] {
				pointer-events: none;

				&:disabled {
					opacity: 0.5;
				}

				&:disabled + label {
					opacity: 0.5;
				}
			}
		}
	}

	.restrictions-list {
		margin-left: 25px;

		.btn {
			margin-bottom: 5px;
		}

		.card {
			margin-bottom: 5px;

			&.archived {
				@include archived(#e5e5e5);
				border-color: #e5e5e5;
			}

			.remove {
				color: #fff;
				border-radius: 50px;
				background-color: #555;
				display: inline-block;
				width: 16px;
				height: 16px;
				line-height: 16px;
				text-align: center;
				font-size: 14px;
				text-decoration: none;

				&:hover {
					background-color: #000;
				}
			}
		}
	}
}
