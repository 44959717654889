.settings-page {
	.col-md-8 {
		@media (min-width: 768px) {
			float: right;
		}
	}

	.upgrade-tag {
		.fa-stack {
			font-size: 10px;
			margin-top: -2px;
		}
	}

	.card {
		overflow: visible;
		border-radius: 8px;

		h3 {
			margin-bottom: 8px;
			font-size: 1.125rem;
			font-weight: 500;
		}

		.dropdown-item {
			gap: 5px;
		}
	}

	.row {
		.card:last-child {
			margin-bottom: 0;
		}
		margin-bottom: $card-spacer-y;
	}

	.section {
		margin-bottom: 15px;
	}

	.section-grid {
		display: grid;
		grid-template-columns: repeat(1, minmax(0, 1fr));
		gap: 1rem;

		@media (min-width: 768px) {
			grid-template-columns: repeat(3, minmax(0, 1fr));
			gap: 24px;
		}

		.section-heading {
			p {
				&.text-muted {
					max-width: 280px;
					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}

			a,
			button {
				margin-top: 1rem;
			}
		}

		.section-body {
			@media (min-width: 768px) {
				grid-column: span 2 / span 2;
			}
		}
	}
}

#settings {
	@include media-breakpoint-down(md) {
		padding-top: 0;
	}

	.settings-grid {
		margin-top: 8px;
		display: grid;
		grid-template-columns: repeat(3, minmax(0, 1fr));
		@include media-breakpoint-down(lg) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
		@include media-breakpoint-down(md) {
			grid-template-columns: repeat(1, minmax(0, 1fr));
		}
		column-gap: 24px;
	  }

	.setting {
		@extend .card;
		padding: 16px;
		display: block;
		color: $text-dark;

		.fa {
			width: 48px;
			height: 48px;
			background-color: $gray-100;
			border-radius: 5px;
			color: $gray-700;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 20px;
		}

		.text-muted {
			font-size: 12px;
			display: inline-block;
			text-overflow: ellipsis;
			overflow: hidden;
			color: $gray-500;
		}

		h5 {
			margin-bottom: 0;
			font-size: 14px;
			line-height: 20px;
			font-weight: $font-weight-bold;
			color: $text-heading;
		}

		&:hover,
		&:active,
		&:focus {
			border-radius: 5px;
			text-decoration: none;
			outline: 0px none;
		}
	}
}

#add-ons {
	.container {
		padding: 0;
	}

	@include media-breakpoint-down(md) {
		.container {
			max-width: 100%;
		}
	}

	.col-xl-6 {
		padding: 0;
	}

	@include media-breakpoint-down(lg) {
		border-bottom: 0px none;

		.col-xl-6 {
			padding: 0;
		}
	}

	.col-xl-6:first-child,
	.col-xl-6:nth-child(2n + 3) {
		.addon {
			border-right: 1px solid $card-border-color;

			@include media-breakpoint-down(lg) {
				border-right: 0px none;
			}
		}
	}

	.addon {
		min-height: 170px;
		padding: 25px;
		border-bottom: 1px solid $card-border-color;

		.media-left {
			padding-right: 25px;

			.fa {
				height: 80px;
				width: 80px;
				font-size: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
				border-radius: 50%;
				overflow: hidden;
			}
		}

		.media-body {
			h3 {
				margin-bottom: 5px;
				font-size: 22px;
				line-height: 31px;
			}

			.card-text {
				color: $text-secondary;
				font-size: 16px;
				margin-bottom: 8px;
				min-height: 48px;
				max-height: 48px;
				overflow: hidden;
			}

			.card-actions {
				.btn + .btn {
					margin-left: 10px;
				}

				.btn-link {
					padding-left: 0;
					padding-right: 0;

					&.enabling {
						text-decoration: none;
					}

					&.learn-more {
						display: none;
					}
				}
			}
		}
	}
}

.btn-group-custom {
	.btn {
		.fa {
			width: 20px;
			color: $text-muted;
		}

		&.active {
			.fa {
				color: $text-dark;
			}
		}
	}
}

.btn-read-more {
	margin-bottom: 16px;
}

.upgrade-tag {
	&.upgrade-tag-sm {
		.fa-stack {
			font-size: 8px;
			margin-top: -2px;
		}
	}

	.fa-stack {
		font-size: 13px;
		margin-top: -4px;

		.fa {
			color: $brand-primary !important;
		}

		.fa-star {
			color: white !important;
		}
	}
}

.upgrade-switch {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	cursor: pointer;
}

//https://material.io/design/color/the-color-system.html#tools-for-picking-colors
.icon-red {
	background-color: #f44336;
}
.icon-pink {
	background-color: #e91e63;
}
.icon-purple {
	background-color: #9c27b0;
}
.icon-deeppurple {
	background-color: #673ab7;
}
.icon-indigo {
	background-color: #3f51b5;
}
.icon-blue {
	background-color: #2196f3;
}
.icon-lightblue {
	background-color: #03a9f4;
}
.icon-cyan {
	background-color: #00bcd4;
}
.icon-teal {
	background-color: #009688;
}
.icon-green {
	background-color: #8bc34a;
}
.icon-lime {
	background-color: #cddc39;
}
.icon-yellow {
	background-color: #ffeb3b;
}
.icon-amber {
	background-color: #ffc107;
}
.icon-orange {
	background-color: #ff9800;
}
.icon-deeporange {
	background-color: #ff5722;
}
.icon-brown {
	background-color: #795548;
}
.icon-black {
	background-color: #333;
}
