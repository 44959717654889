.attachment-viewer {
	text-align: center;
	color: #fff;

	.attachment-viewer-header {
		height: 48px;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 40;

		.attachment-viewer-header-close-icon {
			color: rgba(255, 255, 255, 0.6);
			position: absolute;
			right: 10px;
			top: 10px;
			-webkit-transition-property: -webkit-transform, color;
			transition-property: transform, color;
			-webkit-transition-duration: 150ms;
			transition-duration: 150ms;
			cursor: pointer;
			background-color: rgba(0, 0, 0, 0.6);
			border-radius: 100%;
			padding: 7px;
			line-height: 0;

			span {
				position: relative;
				display: inline-block;
				width: 20px;
				height: 20px;
				overflow: hidden;
				&:hover {
					&::before,
					&::after {
						background: white;
					}
				}

				&::before,
				&::after {
					content: '';
					position: absolute;
					height: 2px;
					width: 100%;
					top: 50%;
					left: 0;
					margin-top: -1px;
					background: rgba(255, 255, 255, 0.8);
				}
				&::before {
					@include transform(rotate(45deg));
				}
				&::after {
					@include transform(rotate(-45deg));
				}
				&::before,
				&::after {
					border-radius: 5px;
				}
			}
		}
	}

	.attachment-viewer-frames {
		-webkit-transition: -webkit-transform 150ms;
		transition: transform 150ms;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		.attachment-viewer-frame {
			bottom: 0;
			left: 0;
			margin: 0 auto;
			position: absolute;
			right: 0;
			top: 0;
			width: 100%;
			z-index: 20;
			-webkit-transition: -webkit-transform 150ms;
			transition: transform 150ms;

			&.attachment-viewer-frame-right {
				-webkit-transform: translateX(100%);
				transform: translateX(100%);
			}
			&.attachment-viewer-frame-left {
				-webkit-transform: translateX(-100%);
				transform: translateX(-100%);
			}
		}
	}

	.attachment-viewer-overlay {
		background-color: rgba($modal-backdrop-bg, 0.5);
		position: absolute;
		height: 100px;
		right: 0;
		left: 0;
		bottom: 0;
		z-index: 30;

		.attachment-viewer-frame-details {
			bottom: 0;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			color: #fff;
			height: 75pt;
			left: 0;
			padding: 9pt 10%;
			position: absolute;
			right: 0;
			z-index: 30;

			.attachment-viewer-frame-details-title {
				font-size: 18px;
				margin: 0 0 9px;
			}

			a {
				color: #fff;
				text-decoration: underline;

				span {
					margin-right: 5px;
				}

				&:hover {
					color: #fff;
				}
			}

			small {
				font-size: 14px;
				display: block;
				margin-bottom: 8px;
			}

			.make-cover,
			.remove-cover,
			.delete-attachment {
				margin-left: 15px;
				display: inline-block;
			}
		}
	}

	.attachment-viewer-frame-preview-wrapper {
		bottom: 0;
		left: 24px;
		overflow-x: hidden;
		overflow-y: auto;
		position: absolute;
		top: 0;
		right: 24px;
	}

	.attachment-viewer-frame-preview-image-wrapper {
		height: auto;
	}

	.attachment-viewer-frame-preview {
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		padding: 3pc 24px 7pc;
		height: 100%;

		.attachment-viewer-frame-preview-image {
			border-radius: 3px;
			max-width: 100%;
			opacity: 0;
			position: relative;
			z-index: 10;
			-webkit-transition-property: -webkit-transform, opacity;
			transition-property: transform, opacity;
			-webkit-transition-duration: 150ms, 0.4s;
			transition-duration: 150ms, 0.4s;
		}

		.attachment-viewer-frame-preview-iframe {
			background-color: #fff;
			border-radius: 3px;
			border: 0;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			height: 100%;
			opacity: 0;
			padding: 9pt;
			width: 100%;
		}
	}

	.attachment-viewer-frame-loaded .attachment-viewer-frame-preview-audio,
	.attachment-viewer-frame-loaded .attachment-viewer-frame-preview-iframe,
	.attachment-viewer-frame-loaded .attachment-viewer-frame-preview-image,
	.attachment-viewer-frame-loaded .attachment-viewer-frame-preview-placeholder,
	.attachment-viewer-frame-loaded .attachment-viewer-frame-preview-video {
		opacity: 1;
	}

	.attachment-viewer-next-frame-btn,
	.attachment-viewer-prev-frame-btn {
		position: absolute;
		height: 100px;
		bottom: 0;
		z-index: 1050;
		display: block;
		cursor: pointer;

		span {
			opacity: 0.4;
			padding: 43px;
			position: absolute;
			top: 0;
			-webkit-transition-property: -webkit-transform, opacity;
			transition-property: transform, opacity;
			-webkit-transition-duration: 150ms, 0.4s;
			transition-duration: 150ms, 0.4s;
		}

		&:hover {
			font-size: 130%;

			span {
				opacity: 1;
			}
		}
	}

	.attachment-viewer-next-frame-btn {
		right: 0;
		width: 10%;

		span {
			right: 0;
		}
	}

	.attachment-viewer-prev-frame-btn {
		left: 0;
		width: 10%;

		span {
			left: 0;
		}
	}
}

.attachment-viewer + .modal-backdrop {
	bottom: 100px;
}
