#getting-started {
	#dismiss {
		position: absolute;
		top: 20px;
		right: 20px;

		&:hover {
			text-decoration: none;
		}
	}

	#header {
		padding: 40px 40px 20px;

		p {
			margin: 0;
		}
		img {
			margin: auto;
		}
	}

	.progress-card {
		margin-top: 20px;

		h3 {
			font-weight: $font-weight-light;
			font-size: 15px;
			margin-bottom: 10px;
		}

		progress {
			height: 15px;
			margin-bottom: 0;
			border-radius: 50%;
		}
	}

	.onboarding {
		&:before {
			content: '';
			position: absolute;
			top: 15px;
			left: 15px;
			height: 100%;
			width: 2px;
			background: linear-gradient(to bottom, #b8c2c7, #b8c2c7 80%, #f1f3f4 100%);
		}

		.icon {
			position: absolute;
			background-color: #fff;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid $brand-primary;
			color: $brand-primary;
			left: 10px;
			margin-top: 10px;
			font-size: 18px;

			i {
				display: none;
			}
		}
	}

	.group {
		position: relative;
		margin-left: 60px;
		margin-bottom: 30px;

		.title {
			font-size: 20px;
			color: #555;
			margin-bottom: 10px;
		}

		.list-group {
			.list-group-item {
				transition: all 0.4s cubic-bezier(0.63, 0.15, 0.03, 1.12);
			}
		}

		&.stacked {
			height: 150px;

			.list-group {
				position: relative;
				height: 80px;

				.list-group-item {
					content: '';
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					background-color: #fff;

					&:nth-child(1) {
						z-index: 7;
					}

					@for $j from 2 to 6 {
						&:nth-child(#{$j}) {
							& > * {
								display: none;
							}

							transform: translate(0, #{($j * 5)-5}px) scale(#{1- ($j * 0.01)});
							z-index: #{6-$j};
							transition-delay: #{($j * 0.1)}s;
						}
					}
				}
			}
		}
	}

	.list-group-item {
		padding: 15px;
		margin-bottom: 5px;
		border-radius: 5px;

		.media-left {
			padding-right: 15px;

			.circle {
				width: 40px;
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid $brand-primary;
				border-radius: 50%;
				font-size: 18px;
				text-decoration: none !important;

				i {
					color: $brand-primary;
				}
			}
		}

		.media-right {
			.btn {
				width: 150px;
			}
		}

		&.finished {
			.media-left {
				.circle {
					background-color: $brand-success;
					border: 0px none;

					i {
						color: #fff;
					}
				}
			}
			.media-body {
				.lead {
					text-decoration: line-through;
				}
			}
			.media-right {
				display: none;
			}
		}

		@include media-breakpoint-down(sm) {
			.media-right {
				display: block;
				display: block;
				padding-left: 35px;
				padding-top: 10px;

				.btn {
					width: 100%;
				}
			}
		}
	}
}
