$hoverColor: #fafafa;
$arrowColor: $brand-primary;

.styled-checkbox,
.styled-radio {
	position: absolute; // take it out of document flow
	opacity: 0; // hide it
	z-index: -1;

	& + label {
		position: relative;
		cursor: pointer;
		padding: 0;
	}

	// Box.
	& + label:before {
		content: '';
		margin-right: 7px;
		display: inline-block;
		vertical-align: text-top;
		width: 16px;
		height: 16px;
		background: white;
		border-radius: 3px;
		border: 1px solid #cfd4d9;
	}

	// Box hover
	&:hover + label:before {
		background: $hoverColor;
	}

	// Box focus
	/*&:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }*/

	// Box checked
	&:checked + label:before {
		background: white;
		border-color: $arrowColor;
	}

	// Disabled state label.
	&:disabled + label {
		color: #b8b8b8;
		cursor: auto;
	}

	// Disabled box.
	&:disabled + label:before {
		background: transparent;
		border-color: #ccc;
	}

	&:disabled:checked + label:after {
		background: transparent;
		box-shadow: 2px 0 0 #ccc, 4px 0 0 #ccc, 4px -2px 0 #ccc, 4px -4px 0 #ccc, 4px -6px 0 #ccc, 4px -8px 0 #ccc;
	}

	// Checkmark. Could be replaced with an image
	&:checked + label:after {
		content: '';
		position: absolute;
		left: 3px;
		top: 8px;
		background: $arrowColor;
		width: 2px;
		height: 2px;
		box-shadow: 2px 0 0 $arrowColor, 4px 0 0 $arrowColor, 4px -2px 0 $arrowColor, 4px -4px 0 $arrowColor,
			4px -6px 0 $arrowColor, 4px -8px 0 $arrowColor;
		transform: rotate(45deg);
	}

	&.styled-checkbox:indeterminate + label:after {
		content: ' ';
		width: 8px;
		height: 2px;
		background-color: #cfd4d9;
		position: absolute;
		left: 4px;
		top: 7px;
	}
}

.styled-radio {
	& + label:before {
		border-radius: 50%;
	}

	&:checked + label:after,
	&:disabled:checked + label:after {
		box-shadow: none;
		width: 10px;
		height: 10px;
		background: $arrowColor;
		position: absolute;
		top: 5px;
		left: 3px;
		border-radius: 100%;
	}

	&:disabled:checked + label:after {
		background-color: #ccc;
	}
}
