#contact {
	height: calc(100% - 145px);

	.empty {
		height: 100%;
	}
	.loading {
		height: 100%;

		display: flex;
		align-items: center;
		justify-content: center;
	}

	.contact-stats {
		margin-bottom: 15px;

		a {
			&:hover {
				color: $text-dark;
				background-color: #fafafa;
			}
		}
	}

	.info {
		display: flex;
		gap: 24px;
		padding: 8px;

		@media (max-width: 768px) {
			flex-direction: column;
		}

		& > .details {
			width: 66%;
			flex-shrink: 0;
			flex-grow: 1;

			@media (max-width: 768px) {
				width: 100%;
			}
		}
	}
}

.contact-header {
	h3.editable {
		margin-bottom: 0;
	}

	img {
		width: 84px;
		height: 84px;
	}

	.contact-info {
		display: table;

		span {
			@include editable;

			&.editable {
				display: inline !important;
				padding-right: 5px;

				&.editable-email {
					a {
						display: none;
						color: $text-secondary;

						i {
							pointer-events: none;
						}
					}

					&:hover {
						a {
							margin-top: 1px;
							display: inline-block;
							vertical-align: middle;
						}
					}

					&:not(.editable-disabled) {
						&:hover {
							a {
								margin-left: 5px;
							}
						}
					}
				}
			}
		}
	}
}

#contacts-new {
	a[data-toggle='collapse'] {
		&:after {
			content: 'Show less contact info';
		}

		&.collapsed {
			&:after {
				content: 'Add more contact info';
			}
		}
	}

	.existing-contact {
		color: $brand-warning;
		text-decoration: none;
		padding-bottom: 10px;
		font-weight: $font-weight-bold;

		img {
			width: 24px;
			height: 24px;
			vertical-align: middle;
			box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.7);
		}

		&:hover,
		&:focus,
		&:visited {
			text-decoration: none;
			border-bottom: 1px solid $brand-warning;
		}
	}

	.test {
		@include media-breakpoint-down(md) {
			display: block;
		}
	}

	form {
		width: 100%;
		padding: 0 20px;
		text-align: center;

		input[type='file'] {
			display: none;
		}

		a {
			position: relative;
			display: inline-block;
			margin: auto;

			img {
				border-radius: 50%;
				box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
				object-fit: contain;
				height: 12vw;
				width: 12vw;
				max-width: 250px;
				max-height: 250px;

				@include media-breakpoint-down(md) {
					height: 73vw;
					width: 73vw;
					max-width: 300px;
					max-height: 300px;
				}
			}

			&:hover {
				.choose-picture {
					background-color: #dcdcdc;
				}
			}

			.choose-picture {
				background-color: #eee;
				border: 2px solid #fff;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				position: absolute;
				top: 50%;
				left: -15px;
				margin-top: -20px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: $text-secondary;
			}

			.remove-picture {
				background-color: #eee;
				border: 2px solid #fff;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				position: absolute;
				top: 50%;
				right: -15px;
				margin-top: -20px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: $text-secondary;
				font-size: 20px;

				&:hover {
					background-color: #dcdcdc;
				}
			}
		}
	}
}

.mailto {
	color: $text-dark;
	display: inline-block;
	border-bottom: 1px dotted #aaa;
	text-decoration: none;

	&:hover,
	&:active,
	&:visited,
	&:focus {
		outline: 0px none;
		text-decoration: none !important;
		color: $text-dark;
	}
}
