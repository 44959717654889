$border-radius-base: 5;
$screen-sm-min: 768;
$screen-md-min: 992;
$screen-lg-min: 1200;

@import '../../lib/eonasdan-bootstrap-datetimepicker/sass/_bootstrap-datetimepicker.scss';

.bootstrap-datetimepicker-widget {
	&.dropdown-menu {
		width: 300px;
		display: block;
	}

	table {
		th,
		td {
			padding: 5px;
			vertical-align: initial;
			border-top: 0px none;
		}

		thead th {
			vertical-align: initial;
			border-bottom: 0px none;
		}

		tbody + tbody {
			border-top: 0px none;
		}
	}
}
