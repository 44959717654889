code,
kbd,
pre,
samp {
	font-variant-numeric: tabular-nums;
}

b,
strong {
	font-weight: $font-weight-bold;
}

.settings-page h2,
.integrations-page h2 {
	font-size: $font-size-h4;
	font-weight: $font-weight-bold;
	font-size: 18px;
	margin-top: 1.4rem;
}

h3.caps {
	font-size: 14px;
	text-transform: uppercase;
	font-weight: normal;
	color: $text-heading;
	margin-bottom: 10px;
}

.beta-badge {
	display: inline-block;
	color: $indigo-500;
	border-color: $indigo-500;
	border-radius: 24px;
	border-width: 1px;
	padding: 4px 16px;
	margin-right: 8px;
}

.text {
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	margin-bottom: 0;
}
