// Hide copyright
// http://stackoverflow.com/questions/12499886/how-to-hide-or-disable-the-google-logo-footer-copyright-on-google-maps-javascr
.gm-style-cc {
	display: none !important;
}

.gm-style a[href^="https://maps.google.com/maps"]
{
	display: none !important;
}

// Marker styling
.marker {
	position: absolute;
	cursor: pointer;
	margin-left: -50px;
	margin-top: -50px;

	.pin {
		border-radius: 100%;
		background-color: #fff;
		position: relative;
		z-index: 2;
		border: 2px solid rgba(33, 33, 33, 0.4);
		overflow: hidden;
	}

	&.image-marker {
		z-index: 1005;
		margin-left: -24px;
		margin-top: -24px;

		.pin {
			img {
				width: 48px;
				height: 48px;
			}
		}

		.pin-effect {
			width: 100px;
			height: 100px;
			position: absolute;
			top: -24px;
			left: -24px;
			display: block;
			background: rgba(33, 33, 33, 0.4);
			border-radius: 50%;
			opacity: 0;
			animation: pulsate 2400ms ease-out infinite;
		}

		&.user-marker {
			margin-left: -16px;
			margin-top: -16px;
			z-index: 1004;

			.pin {
				border: 2px solid #fff;

				img {
					width: 32px;
					height: 32px;
				}
			}

			.pin-effect {
				top: -32px;
				left: -32px;
			}
		}
	}
	&.icon-marker {
		color: rgba(33, 33, 33, 0.4);
		margin-left: -24px;
		margin-top: -24px;
		z-index: 999;

		.pin {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 48px;
			height: 48px;
			font-size: 24px;

			i {
				padding-left: 2px;
			}
		}
	}
}

.cluster-marker {
	.image-marker {
		.pin {
			width: 48px;
			height: 48px;

			img {
				padding: 10px;
			}

			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.cluster-sum {
		background-color: #aaa;
		position: relative;
		z-index: 5000;
		width: 20px;
		height: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
	}
}
