#subscription {
	background-color: white;
	overflow-y: auto;

	.alert {
		p {
			margin: 0;

			&.lead {
				margin-bottom: 5px;
			}
		}
	}

	h6 {
		text-transform: uppercase;
	}

	.last {
		.card {
			margin: 0;
		}
	}

	.slidecontainer {
		width: 100%;

		.rangeslider {
			width: 100%;
			height: 10px;
			background: #fff;
			border: 1px solid $brand-primary;
			position: relative;
			display: block;
			border-radius: 20px;
			top: 5px;

			.rangeslider__fill {
				position: absolute;
				background: $brand-primary;
				display: block;
				border-radius: 10px;
				height: 100%;
			}

			.rangeslider__handle {
				background: $brand-primary;
				color: white;
				cursor: pointer;
				display: inline-block;
				position: absolute;
				top: -10px;
				touch-action: pan-y;
				-ms-touch-action: pan-y;
				border-radius: 22px;
				min-width: 65px;
				line-height: 25px;
				height: 25px;
				text-align: center;
				font-weight: $font-weight-bold;
			}
		}
	}

	.item-slider {
		display: flex;
		padding-top: 10px;

		label {
			font-size: 15px;
			margin-right: 20px;
			margin-bottom: 0;
		}

		.slidecontainer {
			padding-top: 5px;
			max-width: 300px;
		}
	}

	.plans-wrapper {
		display: flex;

		@include media-breakpoint-down(md) {
			flex-direction: column;

			.old {
				margin-right: 0 !important;
			}
		}

		.old {
			margin-right: 15px;
		}

		.new {
			flex-grow: 1;

			@include media-breakpoint-up(lg) {
				.collapsed {
					.plan-wrapper {
						margin: 0;

						&:first-child {
							.card {
								border-top-left-radius: 5px;
								border-bottom-left-radius: 5px;
							}
						}

						&:last-child {
							.card {
								border-top-right-radius: 5px;
								border-bottom-right-radius: 5px;
							}
						}

						.card {
							border-radius: 0;
						}

						&:not(:last-child) {
							.card {
								border-right: 0px;
							}
						}
					}
				}
			}
		}
	}

	.plans {
		display: flex;

		.plan-wrapper {
			flex: 1;

			.card {
				cursor: pointer;
			}

			&:not(:first-child) {
				margin-left: 15px;
			}
		}

		@include media-breakpoint-down(md) {
			flex-direction: column;

			.plan-wrapper {
				margin-left: 0px !important;
			}

			.plan-features {
				display: none;
			}
		}
	}

	.period-toggle-switch {
		font-size: 15px;
		float: right;

		@include media-breakpoint-down(md) {
			display: block;
			float: none;
		}

		label {
			color: #d0d0d0;
			cursor: pointer;

			&.active {
				color: #333;
			}
		}

		.save {
			color: $brand-primary;
			font-size: 90%;

			@include media-breakpoint-down(md) {
				margin-right: 0px;
			}
		}
	}

	.card {
		height: 220px;
	}

	.plans {
		.card {
			h3 + p {
				margin-bottom: 50px;
			}
			h3 + button {
				margin-top: 15px;
			}

			&.current {
				position: relative;

				&:before {
					font-size: 10px;
					content: 'current';
					text-transform: uppercase;
					padding: 2px 5px;
					color: $white;
					border-bottom-left-radius: 5px;
					position: absolute;
					top: 0;
					right: 0;
					background-color: $indigo-500;
				}
			}
		}

		.plan-features {
			height: auto;
			width: 90%;
			margin: 20px auto;

			.tag-sm {
				font-size: 65%;
				vertical-align: text-top;
			}
		}
	}

	.items-conflict.card {
		height: auto;
	}

	.confirm-subscription {
		.card {
			margin: 0;
		}

		.plan-features {
			height: 290px;
			width: 90%;
			margin: auto;

			.disabled {
				display: none;
			}
		}

		.col-md-7 {
			display: flex;
			align-items: center;
			height: 280px;
		}
	}
}
