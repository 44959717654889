#account {
	@include media-breakpoint-down(md) {
		.container {
			max-width: 100%;
		}
	}

	.subscription-item-list {
		& > :first-child {
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
		}
		& > :last-child {
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
		}
	}

	.list-group-item-warning,
	.list-group-item-danger,
	.list-group-item-success {
		p {
			margin: 0;
		}
	}

	.usage {
		margin: 0 5px;

		.progress {
			margin-top: 5px;
			height: 10px;
		}
	}

	.plan {
		h3 {
			margin-bottom: 15px;
		}

		.numItems {
			font-size: 16px;
		}
	}

	.data-usage {
		.card {
			margin-bottom: 0;
		}

		.card.quota-exceeded {
			border-color: $red-300;
		}

		h1 {
			color: $brand-primary;
			margin-bottom: 0;
			margin-top: 3px;
			margin-bottom: 2px;
		}

		small {
			font-size: 14px;
		}

		.progress,
		.new-progressbar {
			height: 5px;
			margin-bottom: 0;
		}
	}
}

#account_billing {
	.plan {
		.card-block {
			display: flex;
			padding: 15px;
			justify-content: space-between;

			.planPrice {
				flex-grow: 1;
				text-align: left;

				h3 {
					margin-bottom: 0;

					.btn-outline-primary {
						vertical-align: bottom;
					}
				}

				.numItems {
					color: $text-secondary;
				}
			}

			.planInfo {
				text-align: right;

				h1 {
					margin-top: 0;
					margin-bottom: 0;
				}

				.price {
					font-size: 30px;
					line-height: 30px;
				}
			}
		}
	}

	.recurly-hosted-field-card {
		height: 40px;
		border: 1px solid rgba(0, 0, 0, 0.15);
		border-radius: 5px;
	}

	.recurly-input {
		height: 68px;
	}

	.has-danger {
		.recurly-hosted-field-card {
			border-color: $brand-danger;
		}

		input:invalid {
			color: #d9534f;
		}
	}
}

.plan {
	&.active,
	&.selected {
		position: relative;
		background-color: $indigo-50;
		border-color: $indigo-500;
	}

	.alert {
		a {
			color: #8a6d3b;
		}
		p {
			margin: 0;
		}
	}

	.card-block {
		padding: 30px;
	}

	.planInfo {
		h1 {
			color: $brand-primary;
			margin-top: 30px;
			font-size: 0px;

			.currency {
				font-size: 25px;
				vertical-align: top;
			}

			.price {
				font-size: 34px;
				line-height: 34px;
			}

			.period {
				color: $text-secondary;
				font-weight: normal;
				font-size: 22px;
				padding-left: 2px;
			}
		}
		h4 {
			text-transform: uppercase;
			font-size: 14px;
			font-weight: normal;
			margin: 0;
		}
	}

	.banner-error {
		margin-top: 16px;
	}

	.premiumInfo {
		color: $brand-primary;
		font-size: 20px;
		line-height: 23px;
		height: 152px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.pricing-footer {
		background-color: #f2f5f9;
		padding: 15px 30px;
	}
}

.plan-features {
	height: 205px;
	margin: 20px 0;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;

	.disabled {
		opacity: 0.5;
	}

	li {
		margin-bottom: 5px;
	}

	.featureTitle {
		i {
			display: none;
		}
		font-weight: $font-weight-bold;
		margin-bottom: 5px;
	}
}

.link {
	font-weight: 600;
	text-decoration: underline;
}
