#profile-page {
	input#itemFile {
		cursor: pointer;
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.avatar {
		max-width: 64px;
		border-radius: 4px;
	}
}
