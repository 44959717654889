// https://tailwindcss-forms.vercel.app/
// https://gist.github.com/certainlyakey/e9c0d8f5c87ff47e3d5b
@function encodeColor($string) {
	@if type-of($string) == 'color' {
		$hex: str-slice(ie-hex-str($string), 4);
		$string: unquote('#{$hex}');
	}
	$string: '%23' + $string;
	@return $string;
}

input[type='checkbox'],
input[type='radio'] {
	appearance: none;
	-webkit-appearance: none;
	background-color: $white;
	background-origin: border-box;
	border-color: $checkbox-border-color;
	border-style: solid;
	border-width: 1px;
	color-adjust: exact;
	height: 16px;
	padding: 0;
	user-select: none;
	vertical-align: middle;
	width: 16px;
}

input[type='checkbox'] {
	border-radius: 0.25rem;
}

input[type='radio'] {
	border-radius: 100%;
}

input[type='checkbox']:focus,
input[type='radio']:focus {
	border-color: $checkbox-focus-border-color;
	box-shadow: 0 0 0 3px $checkbox-focus-color;
	outline-offset: 2px;
	outline: 2px solid transparent;
}

input[type='checkbox']:checked,
input[type='radio']:checked,
input[type='checkbox']:indeterminate {
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	border-color: $checkbox-active-border-color;
	background-color: $checkbox-active-color;
}

input[type='checkbox']:checked {
	background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='#{encodeColor($white)}' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

input[type='radio']:checked {
	background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='#{encodeColor($checkbox-active-color)}' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

input[type='checkbox']:indeterminate {
	background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' fill='#{encodeColor($white)}' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='8' height='2' x='4' y='7' rx='1'/%3E%3C/svg%3E");
}

.form-check {
	display: flex;
	align-items: center;
	margin-bottom: 1rem;

	.form-check-input {
		position: relative;
		margin: 0;
	}

	.form-check-label {
		padding-left: 0.5rem;
		margin-bottom: 0;
	}
}
