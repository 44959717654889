.tag-sm {
	padding: 4px;
}

.tag-available {
	background-color: $statusAvailable;
}
.tag-checkedout {
	background-color: $statusCheckedOut;
}
.tag-awaitcheckout {
	background-color: $statusAwaitCheckout;
}
.tag-expired {
	background-color: $statusExpired;
}
.tag-closed {
	background-color: $statusCheckedOut;
}
.tag-converted {
	background-color: $statusConverted;
}
.tag-open {
	background-color: $statusAvailable;
}
.tag-creating {
	background-color: $statusCreating;
}
.tag-overdue {
	background-color: $statusOverdue;
}
.tag-cancelled {
	background-color: $statusExpired;
}
.tag-custody {
	background-color: $statusCustody;
}

.tag-order {
	background-color: $statusCheckedOut;
}
.tag-reservation {
	background-color: $statusAvailable;
}

.tag-incomplete {
	background-color: $statusIncomplete;
	display: inline-block;
	@include gradient-striped();
	background-size: 10px 10px;
}

.tag-striped {
	@include gradient-striped();
	background-size: 10px 10px;
}

$statusColors: (
	available: $statusAvailable,
	checkedout: $statusCheckedOut,
	awaitcheckout: $statusAwaitCheckout,
	expired: $statusExpired,
	closed: $statusConverted,
	converted: $statusCheckedOut,
	open: $statusAvailable,
	creating: $gray-light,
	overdue: $statusOverdue,
	cancelled: $statusExpired,
	custody: $statusCustody,
	order: $statusCheckedOut,
	reservation: $statusAvailable,
	incomplete: $statusIncomplete,
	archived: $statusArchived,
);

.status-badge {
	display: inline-block;
	font-size: 12px;
	padding-right: 5px;
	padding-left: 1px;
	line-height: 12px;

	&.status-l {
		font-size: 14px;
		line-height: 20px;
	}

	&:before {
		content: '\f111';
		font-family: FontAwesome;
		font-size: 90%;
		vertical-align: middle;
		padding-right: 5px;
	}

	&.status-creating {
		&:before {
			content: '\f10c';
		}
	}

	&.status-incomplete {
		&:before {
			content: '\f05a';
		}
	}

	&.status-unavailable {
		&:before {
			content: '\f10c';
		}
	}

	&.status-progress {
		&:before {
			font-size: 13px;
			content: '\f042';
			color: $statusCheckedOut;
		}
	}

	&.status-exclamation {
		&:before {
			font-size: 13px;
			color: $brand-danger;
			content: '\f06a';
		}
	}

	&.status-success {
		&:before {
			font-size: 13px;
			color: $statusAvailable;
			content: '\f058';
		}
	}

	&.status-archived {
		&:before {
			content: '\f042';
			transform-origin: 5px 5px;
			transform: rotate(35deg);
			display: inline-block;
		}
	}

	&.status-restricted {
		&:before {
			content: '\f042';
			transform-origin: 5px 5px;
			transform: rotate(-60deg);
		}
	}

	@each $color, $value in $statusColors {
		&.status-#{$color} {
			&:before {
				color: $value;
			}
		}
	}
}
