#cancelReservationModal {
	.radio {
		margin-bottom: 5px;
		padding: 10px;

		&.selected {
			background-color: #eef2f9;
			border-radius: 5px;
		}

		label {
			margin-bottom: 0;
		}
	}
}
