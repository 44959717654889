#contactModal {
	.modal-content {
		min-height: 500px;

		.results-empty,
		.results-loading,
		.results-list {
			height: 360px;
		}

		.results-empty {
			i {
				font-size: 80px;
				margin-bottom: 10px;
				color: $text-secondary;
			}
		}

		.results-list {
			max-height: 350px;
			overflow-y: auto;
			clear: both;

			.btn {
				margin-top: 10px;
			}

			.img-circle {
				border-radius: 50%;
				box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
			}

			.own {
				border-bottom-width: 2px;
			}

			.blocked {
				cursor: not-allowed;
				@include archived(#eee);
			}
		}

		.form-search {
			display: flex;
			margin-bottom: 10px;
			gap: 10px;

			form {
				position: relative;
				width: 100%;

				input {
					padding-right: 55px;
				}

				.search-actions {
					position: absolute;
					right: 10px;
					top: 11px;
					display: flex;

					.clear-search {
						color: #ccc;
						font-size: 30px;
						line-height: 18px;
						font-weight: $font-weight-light;
						margin-right: 5px;
						text-decoration: none;
					}
				}
			}
		}
	}
}
