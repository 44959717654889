.upgrade-page {
	height: 100%;
	overflow: hidden;
	position: relative;

	.overlay {
		user-select: none;
		background-color: transparent;
		pointer-events: none;

		.content {
			margin-top: 45px;
		}
	}

	.blur {
		width: 100%;
		height: 100%;
		position: absolute;
		-webkit-filter: blur(10px);
		-moz-filter: blur(5px);
		-o-filter: blur(5px);
		-ms-filter: blur(5px);
		filter: blur(5px);
		opacity: 0.5;
	}
}
