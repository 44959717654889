@import '../../lib/bootstrap-select/sass/bootstrap-select.scss';

.has-danger {
	.bootstrap-select {
		button {
			color: $brand-danger;
			border-color: $brand-danger;
		}
	}
}

.bootstrap-select {
	.bs-searchbox + .dropdown-menu.inner {
		padding-bottom: 20px;
	}

	.divider {
		height: 1px;
		margin: 0.5rem 0;
		overflow: hidden;
		background-color: #e5e5e5;
	}
}
