.dropdown-menu {
	max-height: 500px;
	overflow-y: auto;
	width: auto;

	.dropdown-item {
		gap: 5px;
	}
}

.select-picker {
	.dropdown-menu {
		padding: 8px;

		.footer {
			gap: 4px;
			display: flex;
			padding-bottom: 0 !important;

			&.text-right {
				justify-content: flex-end;
			}
			&.space-between {
				justify-content: space-between;
			}
		}
	}
}
