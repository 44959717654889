.btn:focus,
.btn.focus,
.btn:active:focus,
.btn:active.focus,
.btn.active:focus,
.btn.active.focus {
	outline: 0px none;
}

// Button primary
.btn-primary {
	$hover-background: $indigo-700;
	$hover-border: $indigo-700;

	$active-background: $indigo-900;
	$active-border: $indigo-900;

	color: $white;
	background-color: $btn-primary-bg;
	border-color: $btn-primary-border;

	font-weight: 600;

	&:hover {
		color: $btn-primary-color;
		background-color: $hover-background;
		border-color: $hover-border;
	}

	&:focus,
	&.focus {
		color: $btn-primary-color;
		background-color: $btn-primary-bg;
		border-color: $btn-primary-border;

		outline-width: 4px;
		outline-offset: 0;
		outline-style: solid;
		outline-color: $indigo-100;
	}

	&:active,
	&.active {
		color: $btn-primary-color;
		background-color: $active-background;
		border-color: $active-border;

		&:hover,
		&:focus {
			color: $btn-primary-color;
			background-color: $active-background;
			border-color: $active-border;
		}
	}

	.open > &.dropdown-toggle {
		color: $btn-primary-color;
		background-color: $active-background;
		border-color: $active-border;

		&:hover {
			color: $btn-primary-color;
			background-color: $active-background;
			border-color: $active-border;
		}
	}

	&.disabled,
	&:disabled {
		background-color: $gray-200;
		border-color: $gray-200;
		color: $gray-400;

		&:active,
		&.active,
		&:hover,
		&:focus {
			background-color: $gray-200;
			border-color: $gray-200;
			color: $gray-400;
		}

		&:focus,
		&.focus {
			border-color: $gray-200;
		}
	}
}

// Button secondary (simple)
.btn-secondary {
	$hover-background: $gray-50;
	$hover-border: $gray-300;

	$active-background: $gray-100;
	$active-border: $gray-300;

	color: $gray-700;
	background-color: $white;
	border-color: $gray-300;

	font-weight: 600;

	&:hover {
		background-color: $hover-background;
		border-color: $hover-border;
	}

	&:focus,
	&.focus,
	&.active:focus,
	&:focus-within {
		background-color: $white;
		border-color: $gray-300;

		outline-width: 4px;
		outline-offset: 0;
		outline-style: solid;
		outline-color: $indigo-100;
	}

	&:active,
	&.active {
		background-color: $active-background;
		border-color: $active-border;

		&:hover,
		&:focus {
			background-color: $active-background;
			border-color: $active-border;
		}
	}

	.open > &.dropdown-toggle {
		background-color: $gray-100;
		border-color: $gray-300;

		&:hover {
			background-color: $gray-100;
			border-color: $gray-300;
		}
	}

	&.disabled,
	&:disabled {
		background-color: $gray-200;
		border-color: $gray-200;
		color: $gray-400;

		&:active,
		&.active,
		&:hover,
		&:focus {
			background-color: $gray-200;
			border-color: $gray-200;
			color: $gray-400;
		}

		&:focus,
		&.focus {
			border-color: $gray-200;
		}
	}
}

// Override for pickers (e.g. Reservation location picker)
.picker > .btn-secondary:disabled,
.picker > .btn-secondary.disabled {
	color: $input-color;
	background-color: $input-bg;
	border-color: $input-border-color;

	&:active,
	&.active {
		color: $input-color;
		background-color: $input-bg;
		border-color: $input-border-color;
	}

	&:focus,
	&.focus {
		border-color: $input-border-color;
	}
}

// Button success
.btn-success {
	$hover-background: $green-700;
	$hover-border: $green-700;

	$active-background: $green-900;
	$active-border: $green-900;

	color: $white;
	background-color: $btn-success-bg;
	border-color: $btn-success-border;

	font-weight: 600;

	&:hover {
		color: $btn-success-color;
		background-color: $hover-background;
		border-color: $hover-border;
	}

	&:focus,
	&.focus {
		color: $btn-success-color;
		background-color: $btn-success-bg;
		border-color: $btn-success-border;

		outline-width: 4px;
		outline-offset: 0;
		outline-style: solid;
		outline-color: $green-100;
	}

	&:active,
	&.active {
		color: $btn-success-color;
		background-color: $active-background;
		border-color: $active-border;

		&:hover,
		&:focus {
			color: $btn-success-color;
			background-color: $active-background;
			border-color: $active-border;
		}
	}

	.open > &.dropdown-toggle {
		color: $btn-success-color;
		background-color: $active-background;
		border-color: $active-border;

		&:hover {
			color: $btn-primary-color;
			background-color: $active-background;
			border-color: $active-border;
		}
	}

	&.disabled,
	&:disabled {
		background-color: $gray-200;
		border-color: $gray-200;
		color: $gray-400;

		&:active,
		&.active,
		&:hover,
		&:focus {
			background-color: $gray-200;
			border-color: $gray-200;
			color: $gray-400;
		}

		&:focus,
		&.focus {
			border-color: $gray-200;
		}
	}
}

// Button Warning
.btn-warning {
	$hover-background: $yellow-700;
	$hover-border: $yellow-700;

	$active-background: $yellow-900;
	$active-border: $yellow-900;

	color: $white;
	background-color: $btn-warning-bg;
	border-color: $btn-warning-border;

	font-weight: 600;

	&:hover {
		color: $btn-warning-color;
		background-color: $hover-background;
		border-color: $hover-border;
	}

	&:focus,
	&.focus {
		color: $btn-warning-color;
		background-color: $active-background;
		border-color: $active-border;

		outline-width: 4px;
		outline-offset: 0;
		outline-style: solid;
		outline-color: $yellow-100;
	}

	&:active,
	&.active {
		color: $btn-warning-color;
		background-color: $active-background;
		border-color: $active-border;

		&:hover,
		&:focus {
			color: $btn-warning-color;
			background-color: $active-background;
			border-color: $active-border;
		}
	}

	.open > &.dropdown-toggle {
		color: $btn-warning-color;
		background-color: $yellow-900;
		border-color: $yellow-900;

		&:hover {
			color: $btn-warning-color;
			background-color: $yellow-900;
			border-color: $yellow-900;
		}
	}

	&.disabled,
	&:disabled {
		background-color: $gray-200;
		border-color: $gray-200;
		color: $gray-400;

		&:active,
		&.active,
		&:hover,
		&:focus {
			background-color: $gray-200;
			border-color: $gray-200;
			color: $gray-400;
		}

		&:focus,
		&.focus {
			border-color: $gray-200;
		}
	}
}

// Danger
.btn-danger {
	$hover-background: $red-700;
	$hover-border: $red-700;

	$active-background: $red-900;
	$active-border: $red-900;

	color: $white;
	background-color: $btn-danger-bg;
	border-color: $btn-danger-border;

	font-weight: 600;

	&:hover {
		color: $btn-danger-color;
		background-color: $hover-background;
		border-color: $hover-border;
	}

	&:focus,
	&.focus {
		color: $btn-danger-color;
		background-color: $btn-danger-bg;
		border-color: $btn-danger-border;

		outline-width: 4px;
		outline-offset: 0;
		outline-style: solid;
		outline-color: $red-100;
	}

	&:active,
	&.active {
		color: $btn-danger-color;
		background-color: $active-background;
		border-color: $active-border;

		&:hover,
		&:focus {
			color: $btn-danger-color;
			background-color: $active-background;
			border-color: $active-border;
		}
	}

	.open > &.dropdown-toggle {
		color: $btn-danger-color;
		background-color: $active-background;
		border-color: $active-background;

		&:hover {
			color: $btn-danger-color;
			background-color: $active-background;
			border-color: $active-background;
		}
	}

	&.disabled,
	&:disabled {
		background-color: $gray-200;
		border-color: $gray-200;
		color: $gray-400;

		&:active,
		&.active,
		&:hover,
		&:focus {
			background-color: $gray-200;
			border-color: $gray-200;
			color: $gray-400;
		}

		&:focus,
		&.focus {
			border-color: $gray-200;
		}
	}
}

.btn-lg .caret {
	border: none;
}

.btn.dropdown-toggle {
	font-weight: 500;
}
