@import '../../lib/jquery-fullcalendar/fullcalendar.scss';
@import '../../lib/fullcalendar-scheduler/scheduler.scss';

.fullcalendar-old {
	margin-bottom: 0;
	flex-grow: 1;
	min-height: 0px;
	overflow-y: auto;

	.fc-timeline {
		.fc-timeline-event {
			margin-top: 10px;
			position: absolute;
			height: 43px;
		}

		.fc-scroller {
			position: relative;
		}
	}

	.fc-event {
		cursor: pointer;
		border: 0px none;
		padding: 1px;

		div {
			position: relative;
			z-index: 2;
		}

		&:hover {
			&:before {
				z-index: 1;
				content: ' ';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(
					0,
					0,
					0,
					0.2
				); //http://www.ultrawebsites.com/2013/11/just-one-css-class-for-darker-button-hover-states/
			}
		}
	}

	.unavailable-event {
		cursor: default !important;
		//pointer-events: none;
		@include gradient-striped();
		background-color: $gray !important;
		border-color: $gray !important;
		background-size: 10px 10px;
	}

	.unavailable-availability {
		@include gradient-striped(#fff);
		background-color: #eee !important;
		background-size: 30px 30px;
		color: #333 !important;
		border-color: #ddd !important;
	}

	.fc-time-grid-event.fc-short .fc-time span {
		display: block !important;
	}

	.fc-time-grid-event.fc-short .fc-time:before {
		display: none;
	}

	.fc-time-grid-event.fc-short .fc-time:after {
		display: none;
	}
}

#kits-list,
#items-list {
	display: flex;
	flex-direction: column;
	gap: 16px;

	.actions,
	.advanced-filters {
		margin-bottom: 0;
	}

	.footer-buttons {
		margin-top: 0;
	}
}

.scheduler-container {
	height: calc(100vh - 160px);
}

.scheduler {
	height: 100%;

	.actions {
		margin-bottom: 0;
	}

	.footer-buttons {
		margin-top: 0;
	}

	& > * {
		display: flex;
		flex-direction: column;
		gap: 16px;

		overflow-x: hidden;
		padding: 4px;
		margin: -4px;
	}
}
