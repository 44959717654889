@media print {
/*!
 * FullCalendar v3.8.1
 * Docs & License: https://fullcalendar.io/
 * (c) 2017 Adam Shaw
 */
/*!
 * FullCalendar v3.8.1 Print Stylesheet
 * Docs & License: https://fullcalendar.io/
 * (c) 2017 Adam Shaw
 */
/*
 * Include this stylesheet on your page to get a more printer-friendly calendar.
 * When including this stylesheet, use the media='print' attribute of the <link> tag.
 * Make sure to include this stylesheet IN ADDITION to the regular fullcalendar.css.
 */
.fc {
  max-width: 100% !important; }

/* Global Event Restyling
--------------------------------------------------------------------------------------------------*/
.fc-event {
  background: #fff !important;
  color: #000 !important;
  page-break-inside: avoid; }

.fc-event .fc-resizer {
  display: none; }

/* Table & Day-Row Restyling
--------------------------------------------------------------------------------------------------*/
.fc th,
.fc td,
.fc hr,
.fc thead,
.fc tbody,
.fc-row {
  border-color: #ccc !important;
  background: #fff !important; }

/* kill the overlaid, absolutely-positioned components */
/* common... */
.fc-bg,
.fc-bgevent-skeleton,
.fc-highlight-skeleton,
.fc-helper-skeleton,
.fc-bgevent-container,
.fc-business-container,
.fc-highlight-container,
.fc-helper-container {
  display: none; }

/* don't force a min-height on rows (for DayGrid) */
.fc tbody .fc-row {
  height: auto !important;
  /* undo height that JS set in distributeHeight */
  min-height: 0 !important;
  /* undo the min-height from each view's specific stylesheet */ }

.fc tbody .fc-row .fc-content-skeleton {
  position: static;
  /* undo .fc-rigid */
  padding-bottom: 0 !important;
  /* use a more border-friendly method for this... */ }

.fc tbody .fc-row .fc-content-skeleton tbody tr:last-child td {
  /* only works in newer browsers */
  padding-bottom: 1em;
  /* ...gives space within the skeleton. also ensures min height in a way */ }

.fc tbody .fc-row .fc-content-skeleton table {
  /* provides a min-height for the row, but only effective for IE, which exaggerates this value,
     making it look more like 3em. for other browers, it will already be this tall */
  height: 1em; }

/* Undo month-view event limiting. Display all events and hide the "more" links
--------------------------------------------------------------------------------------------------*/
.fc-more-cell,
.fc-more {
  display: none !important; }

.fc tr.fc-limited {
  display: table-row !important; }

.fc td.fc-limited {
  display: table-cell !important; }

.fc-popover {
  display: none;
  /* never display the "more.." popover in print mode */ }

/* TimeGrid Restyling
--------------------------------------------------------------------------------------------------*/
/* undo the min-height 100% trick used to fill the container's height */
.fc-time-grid {
  min-height: 0 !important; }

/* don't display the side axis at all ("all-day" and time cells) */
.fc-agenda-view .fc-axis {
  display: none; }

/* don't display the horizontal lines */
.fc-slats,
.fc-time-grid hr {
  /* this hr is used when height is underused and needs to be filled */
  display: none !important;
  /* important overrides inline declaration */ }

/* let the container that holds the events be naturally positioned and create real height */
.fc-time-grid .fc-content-skeleton {
  position: static; }

/* in case there are no events, we still want some height */
.fc-time-grid .fc-content-skeleton table {
  height: 4em; }

/* kill the horizontal spacing made by the event container. event margins will be done below */
.fc-time-grid .fc-event-container {
  margin: 0 !important; }

/* TimeGrid *Event* Restyling
--------------------------------------------------------------------------------------------------*/
/* naturally position events, vertically stacking them */
.fc-time-grid .fc-event {
  position: static !important;
  margin: 3px 2px !important; }

/* for events that continue to a future day, give the bottom border back */
.fc-time-grid .fc-event.fc-not-end {
  border-bottom-width: 1px !important; }

/* indicate the event continues via "..." text */
.fc-time-grid .fc-event.fc-not-end:after {
  content: "..."; }

/* for events that are continuations from previous days, give the top border back */
.fc-time-grid .fc-event.fc-not-start {
  border-top-width: 1px !important; }

/* indicate the event is a continuation via "..." text */
.fc-time-grid .fc-event.fc-not-start:before {
  content: "..."; }

/* time */
/* undo a previous declaration and let the time text span to a second line */
.fc-time-grid .fc-event .fc-time {
  white-space: normal !important; }

/* hide the the time that is normally displayed... */
.fc-time-grid .fc-event .fc-time span {
  display: none; }

/* ...replace it with a more verbose version (includes AM/PM) stored in an html attribute */
.fc-time-grid .fc-event .fc-time:after {
  content: attr(data-full); }

/* Vertical Scroller & Containers
--------------------------------------------------------------------------------------------------*/
/* kill the scrollbars and allow natural height */
.fc-scroller,
.fc-day-grid-container,
.fc-time-grid-container {
  /* */
  overflow: visible !important;
  height: auto !important; }

/* kill the horizontal border/padding used to compensate for scrollbars */
.fc-row {
  border: 0 !important;
  margin: 0 !important; }

/* Button Controls
--------------------------------------------------------------------------------------------------*/
.fc-button-group,
.fc button {
  display: none;
  /* don't display any button-related controls */ }
}