@import 'variables';
@import 'mixins';

@import 'plugins';
@import 'loaders';
@import 'utilities';
@import 'pages';
@import 'modals';
@import 'popovers';
@import 'overrides/button';
@import 'overrides/typography';
@import 'overrides/forms';
@import 'overrides/tables';
@import 'overrides/modal';
@import 'overrides/calendar';
@import 'overrides/checkbox';
@import 'overrides/message';
@import 'overrides/select';
@import 'overrides/progress';
@import 'overrides/dropdown';
@import 'overrides/grid';
@import 'overrides/flex';
@import 'overrides/banner';
@import 'overrides/nuvo';

html,
body {
	height: 100%;
	background-color: $bg-color;
	overflow: hidden;

	&.overflow {
		overflow: auto;
	}

	// React popover hacks for old ui
	&.popover-open {
		// Mobile Safari (invisible header)
		@media (max-width: 640px) {
			#appContent {
				overflow: visible;
			}
		}

		// Flickering
		.pre-header {
			z-index: 1035;

			// Because we give the pre-header a higher index then topNav we need
			// to temporary fake the bottom border of the topnav
			border-color: $preheader-border-color;
		}
	}

	div#beamerAnnouncementBar {
		// This enables us to show modals above the announcement bar without touching the original modals
		z-index: 1070;
	}

	&.reactified-view div[data-bind*='react'] {
		border: 5px dashed rgb(0, 255, 0);
		position: relative;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 255, 0, 0.2); /* Adjust the color and opacity as needed */
			z-index: 500; /* Ensure the overlay appears above the element */
			touch-action: none; /* Prevent touch events on the overlay */
			pointer-events: none; /* Prevent pointer events on the overlay */
		}
	}
}

.help-text {
	font-size: 14px;
	line-height: 16px;
	margin-top: 0.25rem;
	color: $gray-500;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-bottom: 0;
}

// To hide elements without losing keyboard focusability
.hidden-a11y {
	width: 0 !important;
	height: 0 !important;
	opacity: 0 !important;
}

#skipToMainContent {
	left: -999px;
	position: absolute;
	top: auto;
	z-index: -999;
}

.red {
	color: $red-500;
}

//
// Top navigation
#topNav {
	background-color: $topnav-bg-color;
	border-bottom: 1px solid $gray-200;
	display: flex;

	.navbar-menu-toggle-container {
		display: flex;
		align-items: center;
		justify-content: center;
		border-right: 1px solid $gray-200;

		&:hover {
			background: $gray-50;
		}
	}

	.navbar-menu-toggle {
		padding: 16px 25px;

		&:focus,
		&:active {
			outline: none;
		}
	}

	.navbar-header {
		flex: 1;
		padding: 12px 16px;
	}

	.navbar-search-container {
		display: flex;
		align-items: center;
		gap: 8px;
	}

	#product-changes {
		margin: 0;
		position: relative;

		.nav-link {
			color: $gray-700;
		}

		#beamerIcon {
			display: none;
			width: 16px;
			height: 16px;
			background-color: #ff5a5f;
			position: absolute;
			right: 0;
			top: 0;

			&.active {
				display: inline-block;
			}
		}
	}

	.navbar-container {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.navbar-container-right {
		display: flex;
		align-items: center;
		gap: 8px;

		.fa-bell {
			color: $gray-700;
			font-size: 16px;
		}

		.nav-link {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 36px;
			height: 36px;
		}

		.cart-link {
			background-color: $brand-primary;
			color: #fff;
			border-radius: 100%;
		}
	}

	#nav-add {
		.dropdown-menu {
			left: 8px;

			&:before {
				position: absolute;
				top: -7px;
				left: 5px;
				display: inline-block;
				border-right: 7px solid transparent;
				border-bottom: 7px solid #ccc;
				border-left: 7px solid transparent;
				border-bottom-color: rgba(0, 0, 0, 0.2);
				content: '';
			}

			&:after {
				position: absolute;
				top: -6px;
				left: 6px;
				display: inline-block;
				border-right: 6px solid transparent;
				border-bottom: 6px solid #ffffff;
				border-left: 6px solid transparent;
				content: '';
			}
		}

		.add-button {
			transform: rotate(0deg);
			transition: transform 0.5s;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: $brand-primary;
			color: #fff;
			width: 36px;
			height: 36px;
			border-radius: 100%;
			font-size: 18px;
			line-height: 26px;

			span {
				margin-top: -2px;
				display: block;
			}

			&:active,
			&:hover,
			&:focus {
				text-decoration: none;
			}
		}

		&.open {
			.add-button {
				transform: rotate(225deg);
			}
		}
	}

	#setup-progress {
		width: 200px;

		@media (min-width: 1200px) {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		.desc {
			margin-bottom: 3px;
			font-size: 90%;
		}

		.progress {
			margin: 0;
			height: 10px;
		}

		&:hover,
		&:active,
		&:focus {
			text-decoration: none;
		}
	}

	@include media-breakpoint-down(sm) {
		#setup-progress {
			display: none;
		}

		.user-dropdown {
			position: static;

			.dropdown-toggle {
				padding: 12px 5px !important;

				.avatar {
					margin-right: 0;
				}
				.username {
					display: none;
				}

				&:after {
					display: none;
				}
			}

			.dropdown-menu {
				width: 100%;
				left: 0;
				right: 0;
			}
		}

		.navbar-brand {
			color: $text-dark;
			background-color: #fff;
			width: 50px !important;

			.name {
				display: none;
			}
		}
	}
}

//
// Content
#appContent {
	position: relative;
	grid-area: main;
	-webkit-overflow-scrolling: touch;
	height: 100%;
	width: 100%;
	overflow-x: auto;
	overflow-y: auto;

	.durandal-wrapper {
		height: 100%;
		overflow-y: auto;
	}

	&.with-intercom-bubble > * {
		padding-bottom: 100px;
	}
}

#trialBar,
#blockedBar {
	width: 100%;
	text-align: center;
	background-color: #fff;
	padding: 10px;
	color: $text-dark;
	z-index: 100;
	border-top: 1px solid #dcdcdc;

	.btn {
		margin-left: 10px;
	}
}

#blockedBar {
	background-color: $red-100;
	color: $red-500;

	-webkit-animation: slideUp 0.5s forwards;
	webkit-animation-delay: 2s;
	animation: slideUp 0.5s forwards;
	animation-delay: 2s;
	bottom: -100px;
	font-size: 17px;
	padding: 20px;

	.hide {
		float: right;
		font-size: 25px;
		color: $red-500;
		line-height: 25px;

		&:hover {
			text-decoration: none;
		}
	}

	.upgrade-link {
		font-weight: 600;
		color: $red-500;
		text-decoration: underline;
	}
}

#pastDueBar {
	width: 100%;
	text-align: center;
	padding: 20px 10px;
	z-index: 560;
	margin: 0;
	font-size: 16px;

	a:not(.btn) {
		color: #8a6d3b;
	}

	.btn {
		margin-left: 10px;
	}
}

@-webkit-keyframes slideUp {
	100% {
		bottom: 0;
	}
}

@keyframes slideUp {
	100% {
		bottom: 0;
	}
}

.navigation-sidebar {
	// /!\ HERE BE DRAGONS! This mimics the React (Tailwind) CSS on Navigation.tsx
	&.placeholder {
		display: flex;
		overflow-y: auto;
		background-color: $gray-900;
		height: 100vh;
		width: 312px;

		&.collapsed {
			width: 70px;
		}

		// Mobile
		@media (max-width: 768px) {
			overflow-y: visible;

			&.collapsed {
				overflow-y: hidden;
				width: 0;
			}
		}

		@media print {
			background-color: transparent;
			overflow-y: hidden;
		}
	}

	> aside {
		height: 100%;
	}

	ul {
		margin-bottom: 0;
	}
}

// Override tailwind globals
img,
svg {
	display: unset;
	max-width: unset;
}

.inline-svg {
	width: 1em;
	height: 1em;
	display: inline-block;
	font-size: inherit;
	overflow: visible;
	vertical-align: -0.125em;
}

.breadcrumb-item {
	/* 
		We have to fix the breadcrumbs like this since bootstrap 3 does not handle the custom content for the breadcrumbs as gracefully as later versions
	*/
	+ .breadcrumb-item::before {
		content: '';
		display: inline-block;
		height: 16px;
		width: 17px;
		background-size: 20px 20px;
		background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' color='%2394A3B8' focusable='false' minHeight='16' minWidth='16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289L15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 18.7071C7.90237 18.3166 7.90237 17.6834 8.29289 17.2929L13.5858 12L8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289Z' fill='currentColor'%3E%3C/path%3E%3C/svg%3E");
		background-repeat: no-repeat;
	}

	a {
		color: $indigo-700;
		font-weight: 400;
	}
}

.dropdown-item {
	display: flex;
	align-items: center;

	input[type='checkbox'] {
		margin-right: 8px;
	}

	.label-tag,
	img {
		margin-right: 4px;
	}

	.pull-left {
		display: flex;
		align-items: center;
	}

	.pull-right {
		margin-left: auto;
	}

	&.custom-filter-dropdown-item {
		label {
			display: flex;
			max-width: 100%;
			gap: 4px;
			align-items: center;

			span:not(.label-tag, .only) {
				flex: 1;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			small {
				flex-shrink: 0;
			}
		}
	}
}

input[type='radio'] {
	width: 15px;
	height: 15px;
	border: 3px solid $white;
	outline: 1px solid $gray-300;
}

input[type='radio']:checked {
	border: 3px solid #fff;
	outline: 1px solid $indigo-500;
	background-color: $indigo-500;
}

input[type='radio']:focus {
	box-shadow: none;
	outline-offset: 0;
}

.location-picker {
	.btn {
		font-weight: 400;
		padding-left: 0.75rem;

		.media-left {
			padding-right: 0.5rem;
		}

		.media-body .text-muted {
			font-size: 100%;
			color: $gray-400;
		}

		.close {
			margin-right: 4px;
			color: $gray-500;
			font-size: 22px;
			line-height: 18px;
			font-weight: 200;
		}
	}
}
