@mixin required {
	border-color: $red-500 !important;
	color: $red-500 !important;

	&.dotted {
		border-color: $red-500 !important;
	}
	.text-muted {
		color: $red-500 !important;
	}
	a {
		color: $red-500 !important;
	}
}

.transaction-page {
	min-height: calc(100% - 155px);
}

.transaction-info {
	.contact-picker {
		.btn,
		.card-placeholder a {
			height: 111px;
		}

		.csv {
			margin-top: 5px;
			font-size: 90%;

			li {
				display: list-item;
			}
		}
	}

	#attachments {
		.card-upload {
			padding: 7px;

			a {
				color: $text-secondary;
			}
		}
	}

	#comments {
		textarea {
			resize: none;
		}
	}

	.col-padding {
		padding: 15px 7.5px 0;
	}
}

.custom-transaction-field {
	background-color: #fff;
	border: 1px solid $gray-300;
	border-radius: $input-border-radius;
	margin-bottom: 5px;
	padding: 5px 5px 5px 10px;

	@include dotted;

	.media-left {
		padding-right: 10px;

		//force width
		&:before {
			content: '';
			display: block;
			width: 120px;
		}
	}

	.media-body {
		.editable-empty {
			font-size: 90%;
			line-height: 21px;
			color: $text-secondary;
		}

		.editable:not(.editable-empty) {
			font-weight: $font-weight-bold;
		}
	}

	.media-right {
		.close {
			font-weight: normal;
			font-size: 22px;
			line-height: 18px;
		}
	}

	&.editing {
		background-color: #fff !important;

		.media-right {
			display: none;
		}
	}

	&.required {
		@include required;

		.editable-empty {
			color: $red-500 !important;
		}
	}
}

.picker {
	margin-bottom: 15px;

	//Extend btn-secondary hover styles
	.card-placeholder {
		@include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border);

		&:hover {
			background-color: $gray-50;
		}

		a {
			color: $text-secondary;

			&:hover,
			&:active,
			&:focus {
				text-decoration: none;
			}
		}
	}

	.card-block,
	.btn {
		padding-right: 5px; //close button closer to the side
		padding-left: 16px;
	}

	.btn {
		@extend .btn-block;

		&.disabled {
			opacity: 1;
		}

		@include dotted;

		.media-left {
			padding-right: 16px;

			span {
				width: 40px;
				display: inline-block;
				text-align: left;
			}
		}

		.media-body {
			text-align: left;

			small {
				font-size: 90%;
			}
		}
	}

	.dropdown-menu {
		width: 100%;
	}

	.close {
		font-weight: 300;
		font-size: 25px;
		line-height: 17px;
		margin-right: 8px;
		color: #b8c1d0;
		opacity: 1;
	}

	.required {
		@include required;
	}

	&.archived,
	&.blocked {
		.btn {
			@include archived(#e5e5e5);
			border-color: #e5e5e5;

			&.required {
				@include archived($state-danger-bg);
				border-color: $state-danger-text;
			}
		}
	}
}

.location-picker {
	.dropdown-menu {
		max-height: 225px;
		overflow-y: auto;
	}
}

.contact-picker {
	.card-block {
		padding: 10px 5px 10px 10px;

		img {
			width: 84px;
			height: 84px;
		}
	}

	.card-placeholder {
		a {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 86px;
		}
	}

	&:not(.compact) {
		.btn-secondary {
			padding: 0;
		}
	}

	.deleted-user {
		display: inline-flex;
		gap: 4px;
		justify-content: center;
		align-items: center;
		font-size: 12px;
		line-height: 18px;
		font-weight: normal;
		background-color: $gray-100;
		color: $gray-700;
		padding: 1px 4px;
		border-radius: 4px;
	}
}

.data-picker-wrapper {
	position: relative;
}

#equipment-view .add-equipment,
.add-equipment {
	&.required {
		border-radius: 4px;
		border: 1px solid $red-500 !important;

		&.dotted {
			border: 2px dotted $red-500 !important;
		}

		@include required;

		a {
			background-color: transparent;
		}
	}
}

.transaction-card {
	.card-block {
		.title {
			height: 49px;
			.name {
				margin-bottom: 5px;
			}
		}

		ul {
			margin: 15px 0 0;

			li {
				height: 32px;

				.fa {
					width: 15px;
					text-align: center;
					margin: 5px;
					color: $text-secondary;
				}

				small {
					color: $text-secondary;
				}
			}

			li:not(:last-child) {
				border-bottom: 1px solid #eee;
				line-height: 30px;
			}
		}
	}

	.card-footer {
		background-color: #f5f5f5;
		padding: 5px;
		height: 55px;

		img:not(aside img) {
			width: 44px;
			background-color: #fff;
		}

		.no-items {
			width: 44px;
			height: 44px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #fff;
			border: 1px solid #ddd;
			border-radius: 2px;
			text-align: center;
			font-size: 10px;
			color: $text-secondary;
			line-height: 10px;
		}
	}
}
