.modal-content {
	box-shadow: 0 0 transparent, 0 0 transparent, 0 20px 25px -5px rgba(0, 0, 0, 0.1),
		0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.modal-dialog .results-list > a,
.modal-dialog .results-list > a {
	border-color: $gray-200;

	&.selected,
	&:hover {
		background-color: $gray-50;
	}
}
