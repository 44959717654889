#attachments {
	.empty {
		margin-top: 80px;
	}

	.attachment {
		margin-bottom: 15px;

		.avatar {
			width: 32px;
			height: 32px;
		}

		.by {
			font-weight: $font-weight-bold;
			margin-bottom: 5px;
		}

		.card {
			padding: 15px;
			margin-bottom: 0px;
			cursor: pointer;

			img {
				width: 64px;
				height: 64px;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		.empty {
			margin-top: 0;
		}
	}
}

.dropzone {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(255, 255, 255, 0.9);
	z-index: 250000;
	display: none;
	text-align: center;
	opacity: 0;
	//transition: opacity 250ms;

	&.show {
		opacity: 1;
		display: block;
	}

	.dropzone-content {
		position: absolute;
		top: 10px;
		left: 10px;
		right: 10px;
		bottom: 10px;
		border: 2px dashed #aaa;
		@include border-radius(5px);
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 30px;
		flex-direction: column;
	}
}

.card-upload {
	input {
		cursor: pointer;
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		font-size: 100px;
	}
}

.card-progress {
	padding: 15px;

	progress {
		margin: 0;
	}
}
