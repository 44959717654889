#editUserModal {
	.select-picker .selection-text {
		width: calc(100% - 15px);
	}
}

#usersyncs {
	height: calc(100% - 100px);
}

#account_usersync {
	a[data-toggle='collapse'] {
		&:before {
			font-family: FontAwesome;
			content: '\f107';
			padding-right: 5px;
		}

		&.collapsed {
			&:before {
				content: '\f105';
			}
		}

		&:active,
		&:hover,
		&:visited,
		&:focus {
			text-decoration: none;
		}
	}

	#advanced_host {
		margin-bottom: 15px;
		overflow: hidden;
	}

	#advanced_host_settings {
		margin-top: 5px;
	}

	.select-picker .selection-text {
		width: calc(100% - 15px);
	}
}

.location-filter {
	.select-picker {
		.btn {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
}
