#bulkModal {
	.modal-body {
		.btn {
			&.disabled {
				background-color: $gray-lighter;
			}
		}

		.input-group {
			input:disabled + .input-group-addon {
				background-color: $gray-lighter;
			}
		}

		.select-picker {
			min-width: auto;

			.selection-text {
				width: calc(100% - 15px);
			}
		}

		.section-title {
			margin-bottom: 15px;
			margin-top: 30px;
			border-bottom: 1px solid #eee;
			padding-bottom: 10px;
		}
	}
}
